import React, { Component, createRef } from "react";
import { Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { req } from "../utils/request.js";
import Alerts from "../common/Alerts";
import $ from "jquery";
import { API_URL } from '../utils/config.js';
import Header from "../common/Header.js";

class EditFieldOrderIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      editingData: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      preguntas: [],
      busqueda: null,
      page: 0,
      paginacion: 200,
      totalCount: null
    };
    this.tableRef = createRef();
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);
  }
  showAudit(data) {
    this.setState({
      auditID: data.id,
      showAudit: true
    })
  }

  async componentDidMount() {
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.encuesta .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.formulario").addClass("active");
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);

    // req.get(API_URL + `formularios/items?formulario=${this.props.match.params.formularyId}`).then(({ data }) => {
    //   this.setState({ preguntas: data.data })
    // });
    this.fetchData();
  }

  desactivateRow(data) {
    data.activo = false;
    req.post(`${API_URL}formularios/${data.id}/toggle`).then(response => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Formulario ha sido desactivado con éxito'
      });
    });
  }
  goToEditOrderIndexField(data) {
    console.log(data)
    this.props.history.push(`/preguntas/edit-order-index/${data.id}`)
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${API_URL}formularios/${data.id}/toggle`).then(response => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Formulario ha sido activado con éxito'
      });
    });
  }

  setProperty(id, data, propertyToUpdate) {
    this.setState({
      preguntas: this.state.preguntas.map(x => {
        if (x.id === id) {
          const merged = { ...x, ...data }
          this.updateData(id, merged, propertyToUpdate);
          return merged;
        }
        return x;
      })
    })

  }

  fetchData() {
    req.get(API_URL + `formularios/items`, {
      params: {
        formulario: this.props.match.params.formularyId,
        pregunta: this.state.busqueda,
        page: this.state.page,
        paginacion: this.state.paginacion
      }
    }).then(({ data }) => {
      this.setState({
        preguntas: data.data,
        totalCount: data.meta.total,
      })
    });
  }

  updateData(id, newItem, propertyToUpdate) {
    if (['indice', 'orden'].includes(propertyToUpdate)) {
      const formData = new FormData();
      newItem.indice && formData.append('indice', newItem.indice)
      newItem.orden && formData.append('orden', newItem.orden)

      formData.append('formulario_seccion', newItem.seccion.id)
      formData.append('tipo_pregunta', newItem.tipo_pregunta.id)
      formData.append('pregunta', newItem.pregunta)
      formData.append('codificacion', newItem.codificacion)
      formData.append('requerido', newItem.requerido ? 1 : 0)

      req.post(API_URL + `formularios/items/${id}/update`, formData)
    } else if (propertyToUpdate === 'activo') {
      req.post(API_URL + `formularios/items/${id}/toggle`);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.page !== prevState.page || this.state.busqueda !== prevState.busqueda) {
      this.fetchData()
    }
  }

  render() {
    const {
      alertMessage,
      alertShow,
      alertType
    } = this.state;
    const searchParams = new URLSearchParams(this.props.location.search);

    const pageName = searchParams.get('formulary_name');
    return (
      <>
        <Header back="/formularios" title={`Orden / Índice - ${pageName}`} />
        <Container>
          <div className="titleButtons">
            <Nav className="justify-content-end">
              <Alerts
                these={this}
                type={alertType}
                message={alertMessage}
                show={alertShow}
              />
            </Nav>
          </div>
          <CSSTransition
            unmountOnExit
            in={this.state.show}
            timeout={200}
            classNames="transitionPage"
          >
            <div className="mainBox">
              <MaterialTable
                tableRef={this.tableRef}
                cellEditable={{
                  onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    return new Promise((resolve, reject) => {
                      this.setProperty(rowData.id, { [columnDef.field]: newValue }, columnDef.field)
                      resolve()
                    });
                  }
                }}
                columns={[
                  {
                    title: 'Pregunta',
                    field: 'pregunta',
                    editable: 'never',

                  },
                  {
                    title: 'Orden',
                    field: 'orden',
                  },
                  {
                    title: 'Índice',
                    field: 'indice',
                  },
                  {
                    title: 'Estatus',
                    field: 'activo',
                    lookup: { 'true': 'Activo', 'false': 'Inactivo' }
                  },
                ]}
                onRowClick={(event, rowData) => {
                  console.log({ rowData })
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  toolbar: {
                    searchTooltip: 'Buscar',
                    searchPlaceholder: 'Buscar',
                  },
                  pagination: {
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Página siguiente',
                    firstTooltip: 'Primera página',
                    lastTooltip: 'Última página',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay registros que mostrar',
                  },
                }}
                title=''
                options={{
                  pageSize: this.state.paginacion,
                  search: true,
                  selection: false,
                  searchFieldAlignment: "right",
                  paging: true,
                  actionsColumnIndex: -1,
                  emptyRowsWhenPaging: false,
                  draggable: false,
                }}
                onChangePage={h => {
                  this.setState({ page: h })
                }}
                onSearchChange={d => {
                  this.setState({ busqueda: d })
                }}
                page={this.state.page}
                totalCount={this.state.totalCount}
                perPage={200}
                data={this.state.preguntas}
              />
            </div>
          </CSSTransition>
        </Container>
      </>
    );
  }
}

export default withRouter(EditFieldOrderIndex);
