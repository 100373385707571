import { CheckCircleOutlined } from '@mui/icons-material';
import React from 'react';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { API_URL } from '../utils/config';
import { req } from '../utils/request'; 
import $ from 'jquery';
import {
  PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL,
  PROGRAMA_EMPRENDIMIENTO,
} from '../configurations/Configurations';
import ConfirmationDialog from '../components/ConfirmationDialog';
import './ModalBeneficiarios.css';
import { format, parse } from 'date-fns';

export function ModalBeneficiarios(props) {
  const { show, actividad, organizacion, asistencias, onHide } = props;
  const [alert, setAlert] = useState({
    show: false,
    type: 'success',
    message: '',
  });
  const [alertTimeout, setAlertTimeout] = useState();
  const [loading, setLoading] = useState();
  const [participantSelected, setParticipantSelected] = useState([]);
  const [idProgramaCapacitacionTecnica, setIdProgramaCapacitacionTecnica] =
    useState();
  const [idProgramaEmprendimiento, setIdProgramaEmprendimiento] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmingParticipant, setConfirmingParticipant] = useState(null);
  const [actividadTerminada, setActividadTerminada] = useState(null);

  const tableRef = React.createRef();
  useEffect(() => {
    req.get(`${API_URL}config`).then((response) => {
      const programaEmprendimiento = response.data.data.find(
        (config) => config.key === PROGRAMA_EMPRENDIMIENTO
      )?.value;
      setIdProgramaEmprendimiento(programaEmprendimiento);
      const programaCapacitacionTecnica = response.data.data.find(
        (config) => config.key === PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL
      )?.value;
      setIdProgramaCapacitacionTecnica(programaCapacitacionTecnica);
      if (!programaEmprendimiento) {
        setAlert({
          show: true,
          type: 'warning',
          message: 'No está configurado el programa de emprendimiento.',
        });
      }
      if (!programaCapacitacionTecnica) {
        setAlert({
          show: true,
          type: 'warning',
          message:
            'No está configurado el programa de capacitación técnica vocacional.',
        });
      }
    });
  }, []);

  const addParticipant = (participant) => {
    req
      .post(`${API_URL}asistencia/actividades/store`, {
        participante: participant.id,
        actividad: actividad?.id,
      })
      .then(
        () => {
          participant.isMember = true;
          setParticipantSelected(oldArray => [...oldArray, participant])
          setAlert({
            show: true,
            type: 'success',
            message: 'Participante agregado con éxito.',
          });
          tableRef.current && tableRef.current.onQueryChange();
        },
        () => {
          setAlert({
            message: 'Ocurrió un error. Participante no fue agregado.',
            type: 'text-danger',
            show: true,
          });
        }
      )
      .finally(() => {
        const timeoutId = setTimeout(() => {
          setAlert({ show: false, message: '', type: 'success' });
        }, 5000);
        setAlertTimeout(timeoutId);
      });
  };

  const fetchData = (query) => {
    return new Promise((resolve) => {
      const params = {
        page: query.page + 1,
        paginacion: query.pageSize,
        organizacion,
        estado: 'activos',
      };
      if (query.search) {
        params.busqueda = query.search;
      }
      // TODO implement filters
      req.get(API_URL + 'participantes', { params }).then((response) => {
        // TODO filter participants
        const data = response.data.data.map((participante) => {
          let isMemberValidated = false;
          const isMember =
            asistencias
              .filter((asistencia) => asistencia.activo)
              .map((asistencia) => asistencia.participante.id)
              .findIndex((id) => id === participante.id) >= 0;
          if (!isMember) {
            let filtered = participantSelected.filter(element => {
              return element.codigo === participante.codigo;
            });
            if (filtered[0] && filtered[0].isMemberValidated) {
              isMemberValidated = true;
            } else {
              isMemberValidated = false;
            }
          } else {
            isMemberValidated = true;
          }
          return { ...participante, isMemberValidated };
        });
        resolve({
          data,
          page: response.data.meta.current_page - 1,
          totalCount: response.data.meta.total,
        });
      });
    });
  };
  return (
    <>
      <Modal
        size="sm" centered show={loading} >
        <Modal.Body>
          <div className='text-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName="my-modal"
        style={{}} centered show={show} onHide={() => {
          setParticipantSelected([]);
          onHide();
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Participante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className={`alert text-end ${alert.show ? 'show' : ''} ${alert.type}`}
          >
            <p>{alert.message}</p>
          </div>
          <MaterialTable
            title=''
            tableRef={tableRef}
            columns={[
              {
                title: 'Nombres',
                field: 'nombres',
              },
              {
                title: 'Apellidos',
                field: 'apellidos',
              },
              {
                title: 'Sexo',
                field: 'genero',
                render: (rowData) =>
                  rowData.genero.charAt(0).toUpperCase() +
                  rowData.genero.slice(1),
              },
              {
                title: 'Fecha de Nacimiento',
                field: 'fecha_nacimiento',
                render: (rowData) => {
                  // Parse the input date string into a Date object
                  const date = parse(rowData.fecha_nacimiento, 'yyyy-MM-dd', new Date());

                  // Format the date to dd/MM/yyyy
                  const formattedDate = format(date, 'dd/MM/yyyy');
                  return formattedDate;
                },
              },
              {
                title: 'Tipo de Participante',
                field: 'tipo.nombre',
              },
              {
                title: 'UUID',
                field: 'codigo',
              },
              {
                title: 'is Member',
                hidden: true,
                field: 'isMemberValidated',
              },
            ]}
            localization={{
              header: {
                actions: "Acciones",
              },
              toolbar: {
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar',
              },
              pagination: {
                previousTooltip: 'Página anterior',
                nextTooltip: 'Página siguiente',
                firstTooltip: 'Primera página',
                lastTooltip: 'Última página',
              },
              body: {
                emptyDataSourceMessage: 'No hay registros que mostrar',
              },
            }}
            options={{
              search: true,
              selection: false,
              searchFieldAlignment: 'right',
              searchAutoFocus: true,
              actionsColumnIndex: -1,
              paging: true,
              pageSize: 10,
              emptyRowsWhenPaging: false,
              draggable: false,
            }}
            data={fetchData}
            actions={[
              (rowData) => ({
                icon: CheckCircleOutlined,
                tooltip: 'Activar',
                hidden: rowData.isMemberValidated,
                onClick: (event, rowData) => {
                  setLoading(true);
                  clearTimeout(alertTimeout);
                  req
                    .get(`${API_URL}asistencia/actividades`, {
                      params: {
                        participante: rowData.id,
                        finalizado: 'finalizadas',
                        estado: 'activos',
                        paginacion: 9999,
                      },
                    })
                    .then((response) => {
                      const asistencias = response.data.data ?? [];
                      const isMemberOfAnotherActivity = asistencias.some(
                        (elem) => {
                          const isMember =
                            (actividad?.programa?.id?.toString() ===
                              idProgramaCapacitacionTecnica &&
                              elem.actividad.programa.id.toString() ===
                              idProgramaEmprendimiento) ||
                            (actividad.programa?.id?.toString() ===
                              idProgramaEmprendimiento &&
                              elem.actividad.programa.id.toString() ===
                              idProgramaCapacitacionTecnica) ||
                            elem.actividad.programa.id ===
                            actividad?.programa?.id;
                          if (isMember) {
                            setActividadTerminada(elem.actividad);
                          }
                          return isMember;
                        }
                      );
                      if (isMemberOfAnotherActivity) {
                        setShowConfirmationDialog(true);
                        setConfirmingParticipant(rowData);
                      } else {
                        rowData.isMemberValidated = true;
                        addParticipant(rowData);
                      }
                      setLoading(false);
                    });
                },
                disabled: rowData.selected,
              }),
            ]}
          />
        </Modal.Body>
        <ConfirmationDialog
          show={showConfirmationDialog}
          onConfirm={() => {
            setShowConfirmationDialog(false);
            addParticipant(confirmingParticipant);
            setConfirmingParticipant(null);
            setActividadTerminada(null);
          }}
          onHide={() => {

            $(".MuiTableCell-root[value='" + confirmingParticipant.codigo + "'] + .MuiTableCell-body").children("div").show();
            setShowConfirmationDialog(false);
            setConfirmingParticipant(null);
            setActividadTerminada(null);
          }}
        >
          <p className='text-center'>
            Este beneficiario ya concluyó el programa:
          </p>
          <strong className='text-center'>{actividadTerminada?.nombre}</strong>
          <strong className='text-center'>
            Actividad No. {actividadTerminada?.id}
          </strong>
          <p className='text-center'>¿Desea incluirlo?</p>
        </ConfirmationDialog>

      </Modal>
    </>
  );
}

export default ModalBeneficiarios;
