import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { object, ref, string } from 'yup';
import Alert, { initialAlert } from '../common/Alert';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';

export const ChangePassword = () => {
  const [alert, setAlert] = useState(initialAlert);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert((prev) => ({ ...prev, show: false }));
    }, alert.timeout ?? 5000);
    return () => clearTimeout(timer);
  }, [alert.show, alert.timeout]);

  const initialValues = {
    password: '',
    current_password: '',
    password_confirmation: '',
  };

  const validationSchema = object().shape({
    current_password: string().required('Debe escribir su contraseña actual'),
    password: string().required('Debe escribir su nueva contraseña'),
    password_confirmation: string().when('password', (password) => {
      if (password) {
        return string()
          .oneOf(
            [ref('password'), null],
            'Las confirmación de contraseña no concuerda.'
          )
          .required('Debes confirmar tu nueva contraseña');
      }
    }),
  });

  const updatePassword = (values, { setSubmitting }) => {
    req
      .post(`${API_URL}profile/password`, values)
      .then((response) => {
        setAlert({
          show: true,
          type: 'text-success',
          message: 'Contraseña actualizada.',
        });
      })
      .catch((errorResponse) => {
        const message = errorResponse.response.data.message;
        setAlert({
          show: true,
          type: 'text-danger',
          message,
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className='container'>
      <div className='titleButtons'>
        <div className='nav justify-content-end'>
          <Alert {...alert} />
        </div>
      </div>
      <div className='mainBox'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={updatePassword}
        >
          {({ handleBlur, handleChange, isSubmitting }) => (
            <Form>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='form-group'>
                    <label className='form-label'>Contraseña actual</label>
                    <input
                      type='password'
                      className='form-control'
                      name='current_password'
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name='current_password'
                    component='p'
                    className='small text-danger'
                  />
                </div>
                <div className='col-lg-4'>
                  <div className='form-group'>
                    <label className='form-label'>Nueva contraseña</label>
                    <input
                      type='password'
                      className='form-control'
                      name='password'
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name='password'
                      component='p'
                      className='small text-danger'
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='form-group'>
                    <label className='form-label'>
                      Confirmar contraseña nueva
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      name='password_confirmation'
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name='password_confirmation'
                      component='p'
                      className='small text-danger'
                    />
                  </div>
                </div>
              </div>
              <button type='submit' className='btn btn-primary buttonSubmit'>
                {isSubmitting ? 'Guardando...' : 'Guardar'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default withRouter(ChangePassword);
