import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import AuthService from '../services/auth.service';

export function SecuredComponent({ children, roles = [] }) {
  const auth = AuthService.getCurrentUser();
  if (!auth) {
    return <Redirect to='/login' />;
  }
  if (!Array.isArray(roles)) {
    roles = [roles];
  }
  const isAuthorized =
    roles.length === 0 || roles.includes(auth.user.role.nombre);
  return isAuthorized ? children : null;
}

export default SecuredComponent;
