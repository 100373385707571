import React, { Component } from "react";
import { Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Link, withRouter } from "react-router-dom";
import Alerts from "../common/Alerts";
import $ from "jquery";
import Audits from '../components/Audits';
import { checkStatus } from "../components/functions";

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { API_URL } from '../utils/config.js';
import { req } from '../utils/request.js';

const url = `${API_URL}provincias`;

class Provinces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      provincias: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      auditModal: null,
      auditType: 'provincias'
    };

    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
    this.showAudit = this.showAudit.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.administracion .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.provincias").addClass("active");
    });
    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);
    req.get(`${url}`).then(response => {
      this.setState({
        provincias: response.data.data ?? []
      });
    });
  }
  showAudit(id) {
    this.setState({
      auditModal: id
    })
  }

  closeAudit() {
    this.setState({
      auditModal: null
    })
  }

  desactivateRow(data) {
    data.activo = false;
    req.post(`${url}/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertMessage: 'La Provincia ha sido desactivada con éxito',
        alertType: 'success'
      });
      return req.get(`${url}`);
    }).then(response => {
      this.setState({
        provincias: response.data.data ?? []
      });
    });
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${url}/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertMessage: 'La Provincia ha sido activada con éxito',
        alertType: 'success'
      });
      return req.get(`${url}`);
    }).then(response => {
      this.setState({
        provincias: response.data ?? []
      });
    });
  }

  render() {
    const {
      provincias,
      alertMessage,
      alertShow,
      alertType,
    } = this.state;
    return (
      <Container>
        {this.state.auditModal ? (
          <Audits
            close={this.closeAudit}
            show={!!this.state.auditModal}
            tipo={this.state.auditType}
            id={this.state.auditModal}
          />
        ) : null}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <Link className="nav-link" to='/provincias/nueva'>
                Crear Provincias
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              columns={[
                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "nombre" },
                {
                  title: "Estatus", field: "activo",
                  render: rowData => (
                    checkStatus(rowData.activo)
                  )
                }
              ]}
              actions={[
                rowData => ({
                  icon: ModeEditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: () => this.props.history.push(`/provincias/${rowData.id}/editar`)
                }),
                rowData => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: 'Desactivar',
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: rowData.activo === false
                }),
                rowData => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: 'Activar',
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.showAudit(rowData.id)
                })
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                paging: false,
              }}
              data={provincias}
            />
          </div>
        </CSSTransition>
      </Container>
    );
  }
}
export default withRouter(Provinces);