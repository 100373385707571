import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { changeDateFormat } from '../components/functions';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';

export default function Actividades({ beneficiario }) {
  const [isLoading, setLoading] = useState(true);
  const [actividades, setActividades] = useState([]);

  const id_beneficiario = beneficiario?.id;

  useEffect(() => {
    const params = { participante: id_beneficiario, estado: 'activos' };
    req.get(`${API_URL}asistencia/actividades`, { params }).then((response) => {
      setActividades(response.data.data);
      setLoading(false);
    });
  }, [id_beneficiario]);

  return (
    <SpinnerPlaceholder isLoading={isLoading}>
      <MaterialTable
        title=''
        columns={[
          { title: 'Nombre del Programa', field: 'actividad.programa.nombre' },
          { title: 'Nombre de la actividad', field: 'actividad.nombre' },
          {
            title: 'Fecha de Inicio',
            field: 'actividad.fecha_inicio',
            render: (rowData) =>
              changeDateFormat(rowData.actividad.fecha_inicio),
          },
          {
            title: 'Fecha de Cierre',
            field: 'actividad.fecha_cierre',
            render: (rowData) =>
              changeDateFormat(rowData.actividad.fecha_cierre),
          },
          { title: 'ID Actividad', field: 'actividad.id' },
          {
            title: 'Finalizó Actividad',
            field: 'finalizado',
            render: (rowData) => (rowData.finalizado ? 'Si' : 'No'),
          },

          {
            title: 'Estatus',
            field: 'activo',
            render: (rowData) => (rowData.actividad.activo ? 'Activo' : 'Inactivo'),
          },
          {
            title: 'Porcentaje',
            field: 'porcentaje',
          },
          {
            title: 'Observación',
            field: 'observacion',
          },
          {
            title: 'Motivo',
            field: 'motivo',
          },
        ]}
        localization={{
          toolbar: {
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
          },
          body: {
            emptyDataSourceMessage: 'No hay registros que mostrar',
          },
        }}
        options={{
          search: false,
          selection: false,
          searchFieldAlignment: 'right',
          actionsColumnIndex: -1,
          paging: false,
          pageSize: 20,
          draggable: false,
        }}
        data={actividades}
      />
    </SpinnerPlaceholder>
  );
}
