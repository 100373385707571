export const TablaRespuestas = ({ items, removerItem }) => {
  return (
    <table className='table'>
      <thead>
        <tr>
          <th>Pregunta</th>
          <th>Operador</th>
          <th>Respuesta</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <TableItem
            key={`item_${index}`}
            item={item}
            removerItem={(item) => removerItem(item)}
          />
        ))}
      </tbody>
    </table>
  );
};

const TableItem = ({ item, removerItem }) => {
  return (
    <tr>
      <td>{item.pregunta_texto}</td>
      <td>{item.operador}</td>
      <td>{item.respuesta_texto}</td>
      <td>
        <button
          type='button'
          className='btn btn-sm btn-danger'
          onClick={() => removerItem(item)}
        >
          Remover
        </button>
      </td>
    </tr>
  );
};

export default TablaRespuestas;
