import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../common/Header';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import activityService from '../services/activities';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import ModalBeneficiarios from './ModalBeneficiarios.jsx';
import ParticipantsTable from './ParticipantsTable';

export function ActivitiesAttendances(props) {
  const [isLoading, setLoading] = useState(true);
  const [auditModal, setAuditModal] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    type: 'success',
    message: '',
  });
  const [alertTimeout, setAlertTimeout] = useState();
  const [actividad, setActividad] = useState({});
  const [asistencias, setAsistencias] = useState([]);
  const [showBeneficiariesDialog, setShowBeneficiariesDialog] = useState(false);
  // const [x, setX] = useState(false);

  const activity_id = props.match.params.activity_id;

  useEffect(() => {
    const fetchData = async () => {
      const actividadResponse = await activityService.find(activity_id);
      setActividad(actividadResponse.data.data);
      const asistenciasResponse = await req.get(
        `${API_URL}asistencia/actividades`,
        {
          params: {
            actividad: activity_id,
            paginacion: 999999,
          },
        }
      );
      setAsistencias(asistenciasResponse.data.data);
      setLoading(false);
    };

    fetchData();
  }, [activity_id]);

  const removerParticipante = ({ id }) => {
    req
      .delete(`${API_URL}asistencia/actividades/${id}/delete`)
      .then(() => {
        return req.get(`${API_URL}asistencia/actividades`, {
          params: {
            actividad: activity_id,
            paginacion: 999999,
          },
        });
      })
      .then((response) => {
        setAsistencias(response.data.data);
      });
  };

  const finalizarParticipante = (asistencia) => {
    clearTimeout(alertTimeout);
    req
      .post(`${API_URL}asistencia/actividades/${asistencia.id}/finalizar`)
      .then(
        (response) => {
          const finalizado = response.data.data.finalizado;
          setAsistencias((prev) => {
            const index = prev.findIndex((elem) => elem.id === asistencia.id);
            prev[index].finalizado = finalizado;
            if (finalizado) {
              setAlert({
                show: true,
                type: 'success',
                message: 'Asistencia marcada como finalizada.',
              });
            } else {
              setAlert({
                show: true,
                type: 'success',
                message: 'Asistencia marcada como no finalizada.',
              });
            }
            return [...prev];
          });
        },
        () => {
          setAlert({
            show: true,
            message:
              'Ocurrió un error. No se pudo marcar la asistencia como finalizada.',
            type: 'text-danger',
          });
        }
      )
      .finally(() => {
        const timeoutId = setTimeout(() => {
          setAlert({ show: false, type: 'success', message: '' });
        }, 5000);
        setAlertTimeout(timeoutId);
      });
  };

  console.log('rendering')
  return (
    <>
      <Header
        back='/actividades'
        title={isLoading ? '' : `Participantes de ${actividad?.nombre}`}
        subtitle={
          isLoading
            ? ''
            : `Número de Actividad: \u00a0${actividad.id} \u00a0\u00a0\u00a0\u00a0\u00a0 Organización: ${actividad?.organizacion?.nombre} \u00a0\u00a0\u00a0\u00a0\u00a0 Programa: ${actividad?.programa?.nombre}`
        }
      />
      <div className='container' style={{ position: 'relative' }}>
        <SpinnerPlaceholder isLoading={isLoading}>
          <div className='titleButtons'>
            <div className='justify-content-end nav'>
              {/* Alerts */}
              <div
                className={`alert ${alert.show ? 'show' : ''} ${alert.type}`}
              >
                <p>{alert.message}</p>
              </div>
              {/* show beneficiaries dialog button */}
          {asistencias.length > 0 && actividad?.meta != null ? 
            <Link to={`/asistencia/${activity_id}`} className='nav-item'>
              <span className='nav-link'>Ver Asistencia</span>
            </Link>
          : null }
              <a
                href={`/actividades/${activity_id}/asistencias`}
                role='button'
                className='nav-item'
                onClick={(e) => {
                  e.preventDefault();
                  setShowBeneficiariesDialog(true);
                }}
              >
                <span className='nav-link'>Agregar beneficiario</span>
              </a>
            </div>
          </div>

          <div className='mainBox' style={{ overflow: 'visible' }}>
            <p className='conteo'>
              Cantidad total de participantes: {asistencias.length}
            </p>
            <ParticipantsTable
              setAsistencias={setAsistencias}
              title='Participantes'
              asistencias={asistencias.filter((elem) => !elem.finalizado)}
              onReturn={finalizarParticipante}
              onConclude={finalizarParticipante}
              onRemove={removerParticipante}
              auditModal={auditModal}
              setAuditModal={setAuditModal}

            />
            {/* <div style={{ height: 1000, position: 'absolute', backgroundColor: 'red' }}>klk bro</div> */}
          </div>
          <br />
          <br />
          <br />
          <div className='mainBox'>
            <ParticipantsTable
              setAsistencias={setAsistencias}
              title='Participantes que finalizaron'
              asistencias={asistencias.filter((elem) => elem.finalizado)}
              onReturn={finalizarParticipante}
              onConclude={finalizarParticipante}
              onRemove={removerParticipante}
              auditModal={auditModal}
              setAuditModal={setAuditModal}
            />
          </div>
        </SpinnerPlaceholder>
      </div>

      <ModalBeneficiarios
        show={showBeneficiariesDialog}
        actividad={actividad}
        organizacion={actividad?.organizacion?.id}
        asistencias={asistencias}
        onHide={() => {
          setShowBeneficiariesDialog(false);
          req
            .get(`${API_URL}asistencia/actividades`, {
              params: {
                actividad: activity_id,
                paginacion: 999999,
              },
            })
            .then((response) => setAsistencias(response.data.data));
        }}
      />
    </>
  );
}

export default withRouter(ActivitiesAttendances);
