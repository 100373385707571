import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class UserService {
  getUsers(params) {
    return req.get(`${API_URL}usuarios`, { params });
  }

  save(newUser) {
    return req.post(`${API_URL}usuarios/store`, newUser);
  }

  update(newUser) {
    return req.post(`${API_URL}usuarios/${newUser.id}/update`, newUser);
  }

  toggle(userId) {
    return req.post(`${API_URL}usuarios/${userId}/toggle`);
  }
}
const service = new UserService();

export default service;
