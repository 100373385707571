export function Unauthorized() {
  return (
    <div className='container'>
      <div className='mainBox'>
        <p className='alert alert-warning text-center'>
          No posee permisos para acceder a este recurso.
        </p>
      </div>
    </div>
  );
}

export default Unauthorized;
