import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GET } from "../utils/request.js";
import MaterialTable from "material-table";

class AuditsConfigurations extends Component {
   constructor(props) {
      super(props);
      this.state = {
         show: this.props.show,
         usuarios: [],

      };
      this.hide_PopUp = this.hide_PopUp.bind(this);
      this.getColumns = this.getColumns.bind(this);
   }
   hide_PopUp() {
      this.props.close();
   }
   getColumns(data) {
      return this.props.getColumns ? this.props.getColumns(data) : data;
   }
   componentDidMount() {
      GET(this, 'usuarios');
   }
   render() {
      const dataTable = this.props.data || [];
      return (
         <div>
            <Modal
               size="xl"
               centered
               show={this.props.show}
               onHide={this.hide_PopUp}
            >
               {this.state.loading ? (
                  <Spinner animation="border" role="status">
                     <span className="visually-hidden">Loading...</span>
                  </Spinner>

               ) : (
                  <>
                     <Modal.Header closeButton>
                        <Modal.Title>Auditoría</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        <MaterialTable
                           title=""
                           columns={this.getColumns([
                              {
                                 title: "id",
                                 hidden: "true",
                                 defaultSort: "desc",
                                 field: "_id",
                              },

                              {
                                 title: "Cambio realizado por", field: "user_id", render: (rowData) =>
                                    getName(rowData.user_id, this.state.usuarios),
                              },
                              {
                                 title: "Valor anterior", field: "old_values", render: (rowData) =>
                                    JSON.stringify(rowData.old_values)
                              },
                              {
                                 title: "Valor nuevo", field: "new_values", render: (rowData) =>
                                    JSON.stringify(rowData.new_values)
                              },
                              { title: "IP", field: "ip_address" },
                              { title: "Tipo de Modificación", field: "event" },
                              { title: "Fecha de Actualización", field: "updated_at", defaultSort: 'asc' },
                           ])}
                           localization={{
                              header: {
                                 actions: "Acciones",
                              },
                              toolbar: {
                                 searchTooltip: "Buscar",
                                 searchPlaceholder: "Buscar",
                              },
                              pagination: {
                                 previousTooltip: "Página anterior",
                                 nextTooltip: "Página siguiente",
                                 firstTooltip: "Primera página",
                                 lastTooltip: "Última página",
                              },
                              body: {
                                 emptyDataSourceMessage: "No hay registros que mostrar",
                              },
                           }}
                           options={{
                              search: false,
                              selection: false,
                              searchFieldAlignment: "right",
                              paging: false,
                              pageSize: 10,
                              actionsColumnIndex: -1,
                              draggable: false,
                           }}
                           data={dataTable}
                        />
                     </Modal.Body></>
               )}
            </Modal>
         </div>
      );
   }
}
function getName(id, data) {
   if (data !== undefined) {
      for (var i = 0, len = data.length; i < len; i++) {
         if (data[i].id === id) {
            return data[i].profile.nombre //+ " " + data[i].last_name
         }
      }
   }
}
export default AuditsConfigurations;
