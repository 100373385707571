import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { array, object, string } from "yup";
import DatePickerField from "../common/DatePicker";
import MultiSelect from "../components/MultiSelect";
import SecuredComponent from "../components/SecuredComponent";
import { convertDate } from "../poll/PollQuestion";
import { API_URL } from "../utils/config";
import { req } from "../utils/request";
import { ROLE_SYSTEM_ADMIN } from "../utils/roles";
import { saveReportData } from "./ReportDataService.js";
import TablaRespuestas from "./TablaRespuestas.jsx";

export function ModalResultadosEncuestas({
  show,
  onHide,
  formularios,
  organizaciones,
  title,
  reportNo,
}) {
  const history = useHistory();
  const [preguntas, setPreguntas] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const [respuestaItems, setRespuestaItems] = useState([]);
  const [idPreguntaSeleccionada, setIdPreguntaSeleccionada] = useState();
  const [idRespuestaSeleccionada, setIdRespuestaSeleccionada] = useState();

  const initialValues = {
    fecha_inicio: "",
    fecha_final: "",
    organizaciones: [],
    formulario: [],
    tipo_participantes: "",
    finalizado: "finalizadas", // siempre finalizadas
    estado: "no_canceladas", // siempre no canceladas
    pregunta: "",
    operador: "",
  };

  const validationSchema = object().shape({
    fecha_inicio: string()
      .nullable()
      .required("Fecha inicial es un campo requerido."),
    fecha_final: string()
      .nullable()
      .required("Fecha final es un campo requerido."),
    organizaciones: array()
      .of(object())
      .min(1, "Organizaciones es un campo requerido."),
    formulario: string().nullable(),
    tipo_participantes: string().nullable(),
    finalizado: string().nullable(),
    estado: string().nullable(),
    pregunta: string().nullable(),
    operador: string().nullable(),
  });

  const onFormularioChange = (formulario) => {
    setPreguntas([]);
    setRespuestas([]);
    setRespuestaItems([]);
    setIdPreguntaSeleccionada(null);
    setIdRespuestaSeleccionada(null);
    req
      .get(`${API_URL}selectores/preguntas`, {
        params: { formulario },
      })
      .then((responsePreguntas) => {
        const tiposPreguntaValidos = [1, 2, 5, 6]; // selección única, selección múltiple, si o no, verdadero o falso
        const preguntas = (responsePreguntas.data.data ?? []).filter(
          (pregunta) => tiposPreguntaValidos.includes(pregunta.tipo_pregunta.id)
        );
        setPreguntas(preguntas);
      });
  };

  const onPreguntaChange = (formulario_item) => {
    setIdPreguntaSeleccionada(formulario_item);
    setIdRespuestaSeleccionada(null);
    setRespuestas([]);
    const pregunta = preguntas.find((p) => p.id === formulario_item);
    if (pregunta.tipo_pregunta.id === 5) {
      // si o no
      setRespuestas([
        { id: 1, respuesta: "Si" },
        { id: 2, respuesta: "No" },
      ]);
      return;
    }
    if (pregunta.tipo_pregunta.id === 6) {
      // verdadero o falso
      setRespuestas([
        { id: 1, respuesta: "Verdadero" },
        { id: 2, respuesta: "Falso" },
      ]);
      return;
    }
    req
      .get(`${API_URL}selectores/respuestas`, {
        params: { estado: "activos", paginacion: 999, formulario_item },
      })
      .then((responseRespuestas) => {
        setRespuestas(responseRespuestas.data.data ?? []);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {};
    for (let key in values) {
      if (values[key]) {
        if (key === "fecha_inicio" || key === "fecha_final") {
          params[key] = convertDate(values[key]);
        } else {
          params[key] = values[key];
        }
      }
    }
    params.organizaciones = values.organizaciones
      .map((elem) => `${elem.value}`)
      .join(`,`);

    // handle questions and answers
    delete params["operador"];
    for (let item of respuestaItems) {
      const { pregunta, respuesta, respuesta_texto, tipo_pregunta, operador } =
        item;
      params[`pregunta_${pregunta}`] =
        tipo_pregunta !== 2 ? respuesta_texto : respuesta;
      // tipo_pregunta === 5 || tipo_pregunta === 6
      //   ? respuesta_texto
      //   : respuesta;
      params[`operador_${pregunta}`] = operador;
    }
    console.log(params.financiador);
    if (
      auth.user.role.nombre === ROLE_SYSTEM_ADMIN &&
      (params.financiador === "" || params.financiador === undefined)
    ) {
      if (reportNo === 6) {
        params.financiador = "USAID";
      } else {
        delete params.financiador;
      }
    } else if (auth.user.role.nombre !== ROLE_SYSTEM_ADMIN) {
      params.financiador = auth.user.organizacion.financiador;
    }

    req.get(`${API_URL}reportes/${reportNo}`, { params }).then((response) => {
      setSubmitting(false);
      saveReportData(response.data.data);
      history.push(`/reportes/${reportNo}?type=${response.data.type}`);
    });
  };

  const opcionesPreguntas = preguntas
    .filter((p) => respuestaItems.findIndex((ri) => ri.pregunta === p.id) < 0)
    .map((p) => ({
      label: p.pregunta,
      value: p.id,
    }));

  const preguntaSeleccionada =
    opcionesPreguntas.find((p) => p.value === idPreguntaSeleccionada) ?? null;

  const opcionesRespuestas = respuestas.map((r) => ({
    label: r.respuesta,
    value: r.id,
  }));

  const respuestaSeleccionada =
    opcionesRespuestas.find((r) => r.value === idRespuestaSeleccionada) ?? null;

  const auth = JSON.parse(localStorage.getItem("user"));
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={() => {
        setPreguntas([]);
        setRespuestas([]);
        setRespuestaItems([]);
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
        }) => (
          <Form>
            <Modal.Body>
              <SecuredComponent roles={[]}>
                <div className="form-group">
                  <label className="form-label">
                    * Fecha inicio llenado de encuesta
                  </label>
                  <DatePickerField name="fecha_inicio" />
                  <ErrorMessage
                    className="small text-danger"
                    name="fecha_inicio"
                    component="p"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">
                    * Fecha fin llenado de encuesta
                  </label>
                  <DatePickerField name="fecha_final" />
                  <ErrorMessage
                    className="small text-danger"
                    name="fecha_final"
                    component="p"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">* Organizaciones</label>
                  <MultiSelect
                    name="organizaciones"
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue("organizaciones", e);
                    }}
                    options={organizaciones.map((org) => ({
                      label: org.nombre,
                      value: org.id,
                    }))}
                  />
                  <ErrorMessage
                    component="p"
                    name="organizaciones"
                    className="small text-danger"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Tipo de participantes</label>
                  <select
                    name="tipo_participantes"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Ambos</option>
                    <option value="1">Directo</option>
                    <option value="2">Indirecto</option>
                  </select>
                  <ErrorMessage
                    className="small text-danger"
                    name="tipo_participantes"
                    component="p"
                  />
                </div>
                {auth.user.role.nombre === ROLE_SYSTEM_ADMIN &&
                  reportNo !== 6 && (
                    <div className="form-group">
                      <label className="form-label">Financiador</label>
                      <select
                        name="financiador"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Selecciona</option>
                        <option value="USAID">USAID</option>
                        <option value="KOICA">KOICA</option>
                        <option value="IREX">IREX</option>
                      </select>
                      <ErrorMessage
                        className="small text-danger"
                        name="tipo_participantes"
                        component="p"
                      />
                    </div>
                  )}
                <p className="fw-bold">Preguntas</p>
                <div className="form-group">
                  <label className="form-label">* Formulario</label>
                  <MultiSelect
                    name="formulario"
                    onChange={(e) => {
                      setFieldValue("formulario", e);
                      setFieldValue("pregunta", "");
                      setFieldValue("operador", "");
                      setFieldValue("respuesta", "");
                      onFormularioChange(e);
                    }}
                    options={formularios.map((elem) => ({
                      label: elem.nombre,
                      value: elem.id,
                    }))}
                  ></MultiSelect>
                  <ErrorMessage
                    component="p"
                    name="formulario"
                    className="small text-danger"
                  />
                </div>
                <div className="row border rounded-3 py-3">
                  <div className="form-group col-8">
                    <label className="form-label">* Pregunta</label>
                    <MultiSelect
                      name="pregunta"
                      value={preguntaSeleccionada}
                      onChange={(e) => {
                        setFieldValue("pregunta", e);
                        onPreguntaChange(e);
                      }}
                      options={opcionesPreguntas}
                    ></MultiSelect>
                    <ErrorMessage
                      component="p"
                      name="pregunta"
                      className="small text-danger"
                    />
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label">* Operador</label>
                    <select
                      name="operador"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Selecciona</option>
                      <option value="=">Igual a</option>
                      <option value="!=">Diferente a</option>
                    </select>
                    <ErrorMessage
                      className="small text-danger"
                      name="operador"
                      component="p"
                    />
                  </div>
                  <div className="form-group col-8">
                    <label className="form-label">* Respuesta</label>
                    <MultiSelect
                      name="respuesta"
                      value={respuestaSeleccionada}
                      onChange={(e) => {
                        setFieldValue("respuesta", e);
                        setIdRespuestaSeleccionada(e);
                      }}
                      onBlur={handleBlur}
                      options={opcionesRespuestas}
                    ></MultiSelect>
                    <ErrorMessage
                      className="small text-danger"
                      name="respuesta"
                      component="p"
                    />
                  </div>
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={
                        !idPreguntaSeleccionada ||
                        !values.operador ||
                        !idRespuestaSeleccionada
                      }
                      onClick={() => {
                        const { pregunta, operador, respuesta } = values;
                        const preguntaObj = preguntas.find(
                          (p) => p.id === pregunta
                        );
                        const pregunta_texto = preguntaObj.pregunta;
                        const tipo_pregunta = preguntaObj.tipo_pregunta.id;
                        const respuesta_texto = respuestas.find(
                          (r) => r.id === respuesta
                        ).respuesta;
                        const item = {
                          pregunta,
                          pregunta_texto,
                          tipo_pregunta,
                          operador,
                          respuesta,
                          respuesta_texto,
                        };
                        setIdPreguntaSeleccionada(null);
                        setIdRespuestaSeleccionada(null);
                        setFieldValue("pregunta", "");
                        setFieldValue("respuesta", "");
                        setRespuestaItems((prev) => [...prev, item]);
                      }}
                    >
                      Agregar
                    </button>
                  </div>
                </div>

                <TablaRespuestas
                  items={respuestaItems}
                  removerItem={(item) => {
                    const index = respuestaItems.indexOf(item);
                    respuestaItems.splice(index, 1);
                    setRespuestaItems([...respuestaItems]);
                  }}
                />
              </SecuredComponent>
            </Modal.Body>

            <Modal.Footer>
              <button
                type="submit"
                className="buttonSubmit"
                disabled={
                  isSubmitting ||
                  !dirty ||
                  !isValid ||
                  respuestaItems.length === 0
                }
              >
                Buscar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalResultadosEncuestas;
