import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCustomReportFilterFunction } from '../utils/getCustomReportFilterFunction.js';
import { clearReportData, loadReportData } from './ReportDataService.js';

export function TableReporte7({ showActivities = false, origen, numeroReporte }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const completoPrograma = queryParams.get('completo_programa');
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const tData = loadReportData();
    setTableData(tData ?? []);
    return () => clearReportData();
  }, []);

  const downloadCsv = () => {
    if (tableData.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'reporte',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of tableData) {
      const row = { ...elem };
      delete row.tableData; // remove this property added by MaterialTable
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };

  function getAgeColumns(type) {
    const ageColumns = {
      USAID: [
        { title: 'Masculino 5-9 años', field: 'm_59' },
        { title: 'Femenino 5-9 años', field: 'f_59' },
        { title: 'Masculino 10-14 años', field: 'm_1014' },
        { title: 'Femenino 10-14 años', field: 'f_1014' },
        { title: 'Masculino 15-19 años', field: 'm_1519' },
        { title: 'Femenino 15-19 años', field: 'f_1519' },
        { title: 'Masculino 20-24 años', field: 'm_2024' },
        { title: 'Femenino 20-24 años', field: 'f_2024' },
        { title: 'Masculino 25-29 años', field: 'm_2529' },
        { title: 'Femenino 25-29 años', field: 'f_2529' },
        { title: 'Masculino 30-35 años', field: 'm_3035' },
        { title: 'Femenino 30-35 años', field: 'f_3035' },
        { title: 'Masculino +36 años', field: 'm_36' },
        { title: 'Femenino +36 años', field: 'f_36' }
      ],
      KOICA: [
        { title: 'Masculino 5-9 años', field: 'm_59' },
        { title: 'Femenino 5-9 años', field: 'f_59' },
        { title: 'Masculino 10-11 años', field: 'm_1011' },
        { title: 'Femenino 10-11 años', field: 'f_1011' },
        { title: 'Masculino 12-14 años', field: 'm_1214' },
        { title: 'Femenino 12-14 años', field: 'f_1214' },
        { title: 'Masculino 15-17 años', field: 'm_1517' },
        { title: 'Femenino 15-17 años', field: 'f_1517' },
        { title: 'Masculino 18-24 años', field: 'm_1824' },
        { title: 'Femenino 18-24 años', field: 'f_1824' },

        { title: 'Masculino 25-29 años', field: 'm_2529' },
        { title: 'Femenino 25-29 años', field: 'm_2529' },
        { title: 'Masculino 30-35 años', field: 'm_3035' },
        { title: 'Femenino 30-35 años', field: 'f_3035' },
        { title: 'Masculino +36 años', field: 'm_36' },
        { title: 'Femenino +36 años', field: 'f_36' },
      ],
      IREX: [
        { title: 'Masculino 5-9 años', field: 'm_59' },
        { title: 'Femenino 5-9 años', field: 'f_59' },
        { title: 'Masculino 10-15 años', field: 'm_1015' },
        { title: 'Femenino 10-15 años', field: 'f_1015' },
        { title: 'Masculino 16-20 años', field: 'm_1620' },
        { title: 'Femenino 16-20 años', field: 'f_1620' },
        { title: 'Masculino 21-25 años', field: 'm_2125' },
        { title: 'Femenino 21-25 años', field: 'f_2125' },
        { title: 'Masculino 26-30 años', field: 'm_2630' },
        { title: 'Femenino 26-30 años', field: 'f_2630' },
        { title: 'Masculino 31-35 años', field: 'm_3135' },
        { title: 'Femenino 31-35 años', field: 'f_3135' },
        { title: 'Masculino +36 años', field: 'm_36' },
        { title: 'Femenino +36 años', field: 'f_36' }
      ],
    }
    return ageColumns[type];
  }


  return (
    <div className='container'>
      <div className='mainBox'>
        <div className='d-flex justify-content-end'>
          {tableData.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
        <MaterialTable
          title=''
          columns={[
            {
              title: 'Organización', field: 'organizacion',
              customFilterAndSearch: getCustomReportFilterFunction('organizacion')
            },
            {
              title: 'Programa', field: 'programa',
              customFilterAndSearch: getCustomReportFilterFunction('programa')
            },
            {
              title: 'Actividad', field: 'actividad', hidden: !showActivities,
              customFilterAndSearch: getCustomReportFilterFunction('actividad')
            },
            {
              title: 'ID Actividad',
              field: 'id_actividad',
              hidden: !showActivities,
              customFilterAndSearch: getCustomReportFilterFunction('id_actividad')
            },

            ...(numeroReporte === '7' ? [
              // { title: 'Nombre de la Actividad', field: 'actividad' },
              // { title: 'ID de la Actividad', field: 'id_actividad' },
              { title: 'Fecha de Inicio', field: 'fecha_inicio' },
              { title: 'Fecha de Cierre', field: 'fecha_cierre' },
            ] : []),

            { title: 'Masculino Total', field: 'total_masculino' },
            { title: 'Femenino Total', field: 'total_femenino' },



            ...getAgeColumns(type),
            { title: 'Con discapacidad', field: 'con_discapacidad' },
            { title: 'Sin discapacidad', field: 'sin_discapacidad' },
            { title: 'No Contestó Discapacidad', field: 'no_contesto_discapacidad' },
            { title: 'Con Vulnerabilidad', field: 'con_vulnerabilidad' },
            { title: 'Sin Vulnerabilidad', field: 'sin_vulnerabilidad' },
            { title: 'Vulnerabilidad no Colectada', field: 'vulnerabilidad_no_colectada' },
            { title: 'No Contestó Vulnerabilidad', field: 'no_contesto_vulnerabilidad' },
            ...(completoPrograma === 'true' ? [
              { title: 'Completó el porcentaje del programa', field: 'completo' },
              { title: 'No Completó el porcentaje del programa', field: 'no_completo' },
            ] : []),
            { title: 'República Dominicana', field: origen ? 'republica_dominicana' : 'rd' },
            { title: 'Haití', field: 'haiti' },
            { title: 'Otros Países', field: origen ? 'otros_paises' : 'otros' },
            { title: 'Total', field: 'total' },
          ]}
          options={{
            search: true,
            draggable: false,
            emptyRowsWhenPaging: false,
            paging: false,
          }}
          data={tableData}
        />
      </div>
    </div>
  );
}

export default TableReporte7;
