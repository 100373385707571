import { useState } from 'react';
import { useEffect } from 'react';
import { API_URL } from '../utils/config.js';
import { req } from '../utils/request.js';

export default function ParticipantDocuments({ beneficiarioId }) {
   const [files, setFiles] = useState([]);
   useEffect(() => {
      req.get(`${API_URL}participantes/documentos?participante=${beneficiarioId}`).then(files => {
         setFiles(files.data.data);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   if (!files.length) {
      return (
         <span>{'No hay documentos para mostrar'}</span>
      )
   }
   return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
         {(files || []).map(x => (
            <div style={{ marginBlock: 5 }} >
               <a href={x.url} target={'_blank'} rel='noopener'>
                  {x.descripcion}
               </a>
            </div>
         ))}
      </div>
   )
}