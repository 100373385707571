import { API_URL } from '../utils/config';
import { base64ToBlob } from '../utils/file';
import { req } from '../utils/request';

class OrganizationService {
  async query(params) {
    return req.get(`${API_URL}organizaciones`, { params });
  }

  async toggle(id) {
    return req.post(`${API_URL}organizaciones/${id}/toggle`);
  }

  async save(organization) {
    const body = await this.createFormData(organization);
    return req.post(`${API_URL}organizaciones/store`, body);
  }

  async update(organization) {
    const body = await this.createFormData(organization);
    return req.post(`${API_URL}organizaciones/${organization.id}/update`, body);
  }

  async createFormData(organization) {
    const formData = new FormData();
    Object.keys(organization).forEach((key) => {
      formData.set(key, organization[key]);
    });
    if (organization.imagen && organization.imagen.startsWith('data:image')) {
      const imgBlob = base64ToBlob(organization.imagen);
      formData.set('imagen', imgBlob);
    } else if (organization.imagen === '') {
      formData.set('imagen', null);
    } else {
      formData.delete('imagen');
    }
    return formData;
  }
}

export const service = new OrganizationService();

export default service;
