import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { getCustomReportFilterFunction } from '../utils/getCustomReportFilterFunction.js';
import { clearReportData, loadReportData } from './ReportDataService.js';

export function TableReporte3() {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const data = loadReportData();
    const tData = [];
    for (let key in data) {
      const row = data[key];
      tData.push(row);
    }
    setTableData(tData);

    return () => clearReportData();
  }, []);

  const downloadCsv = () => {
    if (tableData.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'reporte',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of tableData) {
      const row = { ...elem };
      delete row.tableData; // remove this property added by MaterialTable
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };

  return (
    <div className='container'>
      <div className='mainBox'>
        <div className='d-flex justify-content-end'>
          {tableData.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
        <MaterialTable
          title=''
          columns={[
            { title: 'Organización', field: 'organizacion', customFilterAndSearch: getCustomReportFilterFunction('organizacion') },
            {
              title: 'Código', field: 'codigo',
              customFilterAndSearch: getCustomReportFilterFunction('codigo')
            },
            {
              title: 'Nombres', field: 'nombres',
              customFilterAndSearch: getCustomReportFilterFunction('nombres')
            },
            {
              title: 'Apellidos', field: 'apellidos',
              customFilterAndSearch: getCustomReportFilterFunction('apellidos')
            },
            { title: 'Sexo', field: 'sexo' },
            { title: 'Género', field: 'genero' },
            {
              title: 'Pertenece a algún Grupo Vulnerable?',
              field: 'vulnerable'
            },
            {
              title: 'Fecha de Nacimiento',
              field: 'fecha_nacimiento',
              customFilterAndSearch: getCustomReportFilterFunction('fecha_nacimiento'),
              render: (rowData) => {
                const parsedDate = parse(
                  rowData.fecha_nacimiento,
                  'dd-MM-yyyy',
                  new Date()
                );
                return format(parsedDate, 'dd/MM/yyyy');
              },
            },
            { title: 'Edad Actual', field: 'edad_actual' },
            { title: 'Edad en el Registro', field: 'edad_registro'},
            { title: 'Tipo de Participante', field: 'tipo_participante' },
            { title: 'Dirección', field: 'direccion' },
            { title: 'Barrio', field: 'barrio' },
            { title: 'Sector', field: 'sector', customFilterAndSearch: getCustomReportFilterFunction('sector'), },
            { title: 'Municipio', field: 'municipio', customFilterAndSearch: getCustomReportFilterFunction('municipio'), },
            { title: 'Provincia', field: 'provincia', customFilterAndSearch: getCustomReportFilterFunction('provincia'), },
            { title: 'Móvil', field: 'movil' },
            { title: 'Teléfono', field: 'telefono' },
            { title: 'Nombre Contacto', field: 'contacto_nombre' },
            { title: 'Tipo Contacto', field: 'contacto_tipo' },
            { title: 'Teléfono Contacto', field: 'contacto_telefono' },
            { title: 'Móvil Contacto', field: 'contacto_movil' },
          ]}
          options={{
            search: true,
            draggable: false,
            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 60,
          }}
          data={tableData}
        />
      </div>
    </div>
  );
}

export default TableReporte3;
