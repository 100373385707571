import React, { Component } from "react";
import { Button, Col, Row, Form, Modal, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { req } from "../utils/request.js";
import Alerts from "../common/Alerts";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { checkStatus } from "../components/functions";
import Audits from '../components/Audits';

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { API_URL } from '../utils/config.js';
import { ModeEditOutlined } from "@mui/icons-material";

const url = `${API_URL}tipos/pregunta`;

class QuestionTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      popupAddQuestionTypes: false,
      questiontypes: [],
      editingData: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      auditType: "tipos/pregunta",
      showAudit: null
    };
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);
    this.show_PopUpQuestionTypes = this.show_PopUpQuestionTypes.bind(this);
    this.hide_PopUpQuestionTypes = this.hide_PopUpQuestionTypes.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.encuesta .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.formulario").addClass("active");
    });
    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);
    req.get(`${url}`).then((response) => {
      this.setState({
        questiontypes: response.data.data ?? []
      });
    });
  }

  showAudit(id) {
    this.setState({
      showAudit: id
    })
  }
  closeAudit() {
    this.setState({
      showAudit: null
    })
  }
  show_PopUpQuestionTypes(data) {
    this.setState({ popupAddQuestionTypes: true, editingData: data });
  }
  hide_PopUpQuestionTypes() {
    this.setState({ popupAddQuestionTypes: false });
  }
  editRow(data) {
    this.show_PopUpQuestionTypes(data);
  }
  desactivateRow(data) {
    data.activo = false;
    req.post(`${url}/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Tipo de Pregunta ha sido desactivado con éxito'
      });
    });
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${url}/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Tipo de Pregunta ha sido activado con éxito'
      });
    });
  }

  checkTipoDato(tipoDato) {
    let text = '';
    switch (tipoDato) {
      case 'unico':
        text = 'Único';
        break;
      case 'multiple':
        text = 'Múltiple';
        break;
      case 'date':
        text = 'Fecha';
        break;
      case 'text':
        text = 'Texto';
        break;
      case 'si_o_no':
        text = 'Si o no';
        break;
      case 'boolean':
        text = 'Boolean';
        break;
      case 'numerico':
        text = 'Numérico';
        break;
      default:
        text = 'Indeterminado';
    }
    return <span>{text}</span>;
  }

  render() {
    const {
      popupAddQuestionTypes,
      questiontypes,
      editingData,
      alertMessage,
      alertShow,
      alertType,
    } = this.state;
    const addQuestionTypesValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
      tipo_dato: Yup.string().required('El tipo de dato es un campo requerido.')
    });
    return (
      <Container>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        )}
        <div className="titleButtons">
          {alertShow ? (
            <Nav className="justify-content-end">
              <Alerts
                these={this}
                type={alertType}
                message={alertMessage}
                show={alertShow}
              />
            </Nav>
          ) : (
            <div className='nav-item'>

              <span
                className='nav-link'
                onClick={() => {
                  this.props.history.push('/tipo-pregunta/crear');
                }}
              >
                Crear Tipo de Pregunta
              </span>
            </div>
          )}


        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              columns={[

                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "nombre" },
                { title: 'Tipo de dato', field: 'tipo_dato', render: rowData => this.checkTipoDato(rowData.tipo_dato) },
                {
                  title: "Estatus", field: "activo",
                  render: rowData => (
                    checkStatus(rowData.activo)
                  )
                },
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "right",
                actionsColumnIndex: -1,
                paging: true,
                pageSize: 20,
                draggable: false,
              }}
              data={questiontypes}
              actions={[
                rowData => ({
                  hidden: !rowData.general,
                  icon: ModeEditOutlined,
                  tooltip: 'Editar',
                  onClick: (event, rowData) => {
                    this.props.history.push(`/tipo-pregunta/${rowData.id}`);
                  }
                }),
                rowData => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: 'Desactivar',
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: rowData.activo === false
                }),
                rowData => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: 'Activar',
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.showAudit(rowData.id)
                })
              ]}
            />
          </div>
        </CSSTransition>
        <Modal
          centered
          show={popupAddQuestionTypes}
          onHide={this.hide_PopUpQuestionTypes}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Tipo de Pregunta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                nombre: editingData.nombre || "",
                tipo_dato: editingData.tipo_dato ?? '',
              }}
              validationSchema={addQuestionTypesValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                this.hide_PopUpQuestionTypes();
                values.activo = true;
                if (editingData.id !== undefined) {
                  delete values.id;
                  req.post(`${url}/${editingData.id}/update`, values).then(() => {
                    this.setState({
                      alertShow: true,
                      alertType: 'success',
                      alertMessage: 'El Tipo de Pregunta ha sido actualizado con éxito'
                    })
                    return req.get(url);
                  }).then(response => {
                    this.setState({ questiontypes: response.data.data ?? [] });
                  });
                } else {
                  req.post(`${url}/store`, values).then(() => {
                    this.setState({
                      alertShow: true,
                      alertType: 'success',
                      alertMessage: 'El Tipo de Pregunta fue creado con éxito'
                    })
                    return req.get(url);
                  }).then(response => {
                    this.setState({ questiontypes: response.data.data ?? [] });
                  });
                }
                this.hide_PopUpQuestionTypes();
                resetForm();
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <div className="form-group">
                        <label>Nombre</label>
                        <Field type="text" name="nombre" />
                        <ErrorMessage
                          className="errorField"
                          name="nombre"
                          component="p"
                        />
                      </div>
                      <div className='form-group'>
                        <label id='tipo_dato_label'>Tipo de dato</label>
                        <div role='group' aria-labelledby='tipo_dato_label'>
                          <label>
                            <Field type='radio' name='tipo_dato' value='unico' />
                            Único
                          </label>
                          <label>
                            <Field type='radio' name='tipo_dato' value='multiple' />
                            Múltiple
                          </label>
                          <label>
                            <Field type='radio' name='tipo_dato' value='date' />
                            Fecha
                          </label>
                          <label>
                            <Field type='radio' name='tipo_dato' value='text' />
                            Texto
                          </label>
                          <label>
                            <Field type='radio' name='tipo_dato' value='si_o_no' />
                            Si o no
                          </label>
                          <label>
                            <Field type='radio' name='tipo_dato' value='boolean' />
                            Boolean
                          </label>
                          <label>
                            <Field type='radio' name='tipo_dato' value='numerico' />
                            Numérico
                          </label>
                        </div>
                        <ErrorMessage
                          className='errorField'
                          name='tipo_dato'
                          component='p'
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          disabled={isSubmitting || !values.nombre || !values.tipo_dato}
                          variant="primary "
                        >
                          {isSubmitting ? "Guardando..." : "Guardar"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
export default withRouter(QuestionTypes);