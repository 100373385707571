import { useEffect, useState } from 'react';
import PollQuestion from './PollQuestion.jsx';
import { req } from '../utils/request.js';
import { API_URL } from '../utils/config.js';

export function PollSection({
  section,
  selectedAnswers,
  errors,
  handleChange,
  canEdit,
  form,
}) {
  const [itemsToShow, setItemsToShow] = useState([]);
  const areQuestionConditionsMet = ({ dependencias }) => {
    return dependencias
      .filter((dependencia) => dependencia.activo)
      .every((dependencia) => {
        const itemId = `field_${dependencia.encuesta_item_id_dependencia}`;
        switch (dependencia.operador) {
          case '3': // !=
            return Array.isArray(selectedAnswers[itemId])
              ? selectedAnswers[itemId] &&
              selectedAnswers[itemId].length > 0 &&
              selectedAnswers[itemId].every(
                (ans) => ans.value !== dependencia.valor
              )
              : selectedAnswers[itemId] &&
              selectedAnswers[itemId] !== dependencia.valor;
          case '0': // ==
          default:
            return Array.isArray(selectedAnswers[itemId])
              ? selectedAnswers[itemId] &&
              selectedAnswers[itemId].length > 0 &&
              selectedAnswers[itemId].every(
                (ans) => ans.value === dependencia.valor
              )
              : selectedAnswers[itemId] &&
              selectedAnswers[itemId] === dependencia.valor;
        }
      });
  };

  useEffect(() => {
    async function load() {
      const itemsToShow = await Promise.all(section.items
        .filter((item) => item.activo)
        .filter(areQuestionConditionsMet)
        // Esto es para remplazar la propiedad respuestas de cada pregunta con las respuestas del tipo de pregnta
        .map(async item => {
          let respuestas = item.respuestas;

          if (item.tipo_pregunta.general) {
            const response = await req.get(`${API_URL}respuestas/generales?tipo_pregunta=${item.tipo_pregunta.id}`);
            respuestas = response?.data?.data || [];
          }

          return { ...item, respuestas }
        })
      );
      setItemsToShow(itemsToShow);
    }
    // console.log({ itemsToShow })
    load();
  }, [section, selectedAnswers]);

  return itemsToShow.length > 0 ? (
    <div className='row subBox mb-4'>
      <strong>{section.nombre}</strong>
      {itemsToShow.map((item) => (
        <div className='col-lg-4' key={`question_container_${item.id}`}>
          <PollQuestion
            section={section}
            key={`question_${item.id}`}
            item={item}
            respuestas={item.respuestas}
            selectedAnswers={selectedAnswers}
            errors={errors}
            handleChange={handleChange}
            canEdit={canEdit}
            form={form}
          />
        </div>
      ))}
    </div>
  ) : null;
}

export default PollSection;
