import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';

class Alerts extends Component {
    hideAlert() {
        $(".alert").removeClass("show"); 
        let these = this;
        setTimeout(function () { 
            these.props.these.setState({
                alertShow: false,
                alertMessage: "",
                alertType: ""
            })
        }, 1000);
    }
    render() {
        if (this.props.show) {
            let these = this;
            setTimeout(function () { 
                these.hideAlert();
            }, 6000);
            return (
                <div className={`alert show ${this.props.type}`}>
                    <p>{this.props.message}</p>
                </div>
            )
        } else {
            return ("");
        }
    }
}

export default Alerts;
