import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class ProgramService {
  query(params) {
    return req.get(`${API_URL}programas`, { params });
  }

  save(program) {
    return req.post(`${API_URL}programas/store`, program);
  }

  update(program) {
    return req.post(`${API_URL}programas/${program.id}/update`, program);
  }

  toggle(id) {
    return req.post(`${API_URL}programas/${id}/toggle`);
  }
}

export const service = new ProgramService();

export default service;
