import { Modal } from 'react-bootstrap';

export function PollSavedDialog({ nombre, codigo, encuestaId, show, onHide }) {
  return (
    <Modal centered size='lg' show={show} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p className='text-center fw-bold'>
          La encuesta ha sido guardada con éxito
        </p>
        <p className='text-center'>Nombre y apellido: {nombre}</p>
        <p className='text-center'>Código único: {codigo}</p>
        <p className='text-center'>Encuesta No. {encuestaId}</p>
        <button className='buttonSubmit' type='button' onClick={() => onHide()}>
          Aceptar
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default PollSavedDialog;
