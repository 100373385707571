const { sessionStorage } = window;

const REPORT_DATA = 'reportData';

export function saveReportData(data) {
  sessionStorage.setItem(REPORT_DATA, JSON.stringify(data));
}

export function loadReportData() {
  const dataStr = sessionStorage.getItem(REPORT_DATA);
  return JSON.parse(dataStr);
}

export function clearReportData() {
  sessionStorage.removeItem(REPORT_DATA);
}
