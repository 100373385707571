import MultiSelect from "../components/MultiSelect.js";
import { API_URL } from "../utils/config";
import { req } from "../utils/request";
import CustomDatePicker from "./CustomDatePicker.jsx";
import { PatternFormat } from "react-number-format";

export function removeUnderscoreAndHyphen(inputString) {
  // Use regular expressions to replace underscores and hyphens with an empty string
  const cleanedString = inputString.replace(/[_-]/g, "");
  return cleanedString;
}

function convertDateFormatToYYYYMMDD(inputDate) {
  // Split the input date into day, month, and year
  const [day, month, year] = inputDate.split("/");

  // Create a new Date object using the components
  const convertedDate = new Date(`${year}/${month}/${day}`);

  // Get the components of the converted date
  const convertedDay = convertedDate.getDate();
  const convertedMonth = convertedDate.getMonth() + 1; // Months are zero-based, so add 1
  const convertedYear = convertedDate.getFullYear();

  // Pad single-digit day and month with leading zero if necessary
  const formattedDay = convertedDay < 10 ? "0" + convertedDay : convertedDay;
  const formattedMonth =
    convertedMonth < 10 ? "0" + convertedMonth : convertedMonth;

  // Create the formatted date string in "yyyy/mm/dd" format
  const formattedDate = `${convertedYear}/${formattedMonth}/${formattedDay}`;

  return formattedDate;
}

function convertDateFormatToDDMMYYYY(inputDate) {
  // Split the input date into year, month, and day
  const [year, month, day] = inputDate.split("/");

  // Create a new Date object using the components
  const convertedDate = new Date(`${month}/${day}/${year}`);

  // Get the components of the converted date
  const convertedDay = convertedDate.getDate();
  const convertedMonth = convertedDate.getMonth() + 1; // Months are zero-based, so add 1
  const convertedYear = convertedDate.getFullYear();

  // Pad single-digit day and month with leading zero if necessary
  const formattedDay = convertedDay < 10 ? "0" + convertedDay : convertedDay;
  const formattedMonth =
    convertedMonth < 10 ? "0" + convertedMonth : convertedMonth;

  // Create the formatted date string in "dd/mm/yyyy" format
  const formattedDate = `${formattedDay}/${formattedMonth}/${convertedYear}`;

  return formattedDate;
}

function isDateInFormat(dateString) {
  // Check if the date matches the "dd/mm/yyyy" format
  const ddmmYYYYRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (ddmmYYYYRegex.test(dateString)) {
    return "dd/mm/yyyy";
  }

  // Check if the date matches the "yyyy/mm/dd" format
  const yyyyMMddRegex = /^\d{4}\/\d{2}\/\d{2}$/;
  if (yyyyMMddRegex.test(dateString)) {
    return "yyyy/mm/dd";
  }

  // If the date doesn't match either format
  return "Invalid format";
}

export function PollQuestion({
  section,
  item,
  respuestas = [],
  selectedAnswers,
  errors,
  handleChange,
  canEdit,
  form,
}) {
  const handleBlur = ({ section, respuesta: respuesta_v, pregunta }) => {
    const idRespuesta = item.respuestas_participante[0].id;
    let respuesta = Array.isArray(selectedAnswers[`field_${item.id}`])
      ? selectedAnswers[`field_${item.id}`].map((option) => option.value)
      : selectedAnswers[`field_${item.id}`];

    let peso = null;

    if ([1, 2].includes(type.id)) {
      const formSection = form.secciones.find(
        (x) => x.orden === section.orden && x.activo === true
      );
      const formQuestion = formSection.items.find(
        (x) => x.pregunta === pregunta
      );
      if (type.id === 1) {
        const formAnswer = (formQuestion?.respuestas || []).find(
          (x) => x.respuesta === respuesta_v
        );
        peso = formAnswer?.peso;
      } else {
        // here exists a possible problem when the answer uses comma
        respuesta_v.split("|").forEach((respuesta) => {
          const formAnswer = (formQuestion?.respuestas || []).find(
            (x) => x.respuesta === respuesta
          );
          console.log({ formAnswer });
          peso = (peso || 0) + (formAnswer?.peso || 0);
        });
      }
    } else if (type.id === 5) {
      if (respuesta === "Si") {
        peso = 1;
      } else if (respuesta === "No") {
        peso = 2;
      } else if (respuesta === "no_responde") {
        peso = 3;
      }
    } else if (type.id === 6) {
      if (respuesta === "Verdadero") {
        peso = 1;
      } else if (respuesta === "Falso") {
        peso = 2;
      } else if (respuesta === "no_responde") {
        peso = 3;
      }
    } else if ([9, 8].includes(type.id)) {
      respuesta = removeUnderscoreAndHyphen(respuesta);
    }

    // TODO maybe check for validations?

    if (!respuesta || respuesta?.length === 0) {
      return;
    }

    // just send the request, no need to handle the response
    req.post(
      `${API_URL}encuestas/respuestas/participantes/${idRespuesta}/update`,
      {
        respuesta,
        peso,
        ...(type.id === 4 ? { comentario: respuesta } : {}),
      }
    );
    // TODO send a request to clear the answer of each dependent questions
  };

  const type = item.tipo_pregunta;
  const fieldName = `field_${item.id}`;

  let answerField = <span>Rendering answer field...</span>;

  switch (type.id) {
    case 1: // selección única
    case 2: // selección múltiple
      const options = respuestas
        .filter((r) => r.activo)
        .map((r) => ({
          label: r.respuesta,
          value: r.respuesta,
        }));

      const value =
        typeof selectedAnswers[fieldName] === "string"
          ? selectedAnswers[fieldName].split("|")
          : selectedAnswers[fieldName];
      answerField =
        type.id === 2 ? (
          <MultiSelect
            id={fieldName}
            key={fieldName}
            name={fieldName}
            value={
              !value || !Array.isArray(value)
                ? null
                : value.map((x) => ({
                    label: x?.label || x,
                    value: x?.value || x,
                  }))
            }
            isMulti
            required={item.requerido}
            isDisabled={!canEdit}
            onChange={(e) => {
              handleChange(
                {
                  type,
                  target: {
                    name: fieldName,
                    value: e.map((x) => x.label).join("|"),
                    required: item.requerido,
                  },
                },
                item.id,
                type.id
              );
            }}
            onBlur={() =>
              handleBlur({
                pregunta: item.pregunta,
                section,
                respuesta: selectedAnswers[fieldName],
              })
            }
            allowSelectAll
            options={options}
          />
        ) : (
          <MultiSelect
            id={fieldName}
            name={fieldName}
            key={fieldName}
            defaultValue={
              (selectedAnswers[fieldName]?.label || "").includes("null") ||
              selectedAnswers[fieldName]?.label === null
                ? null
                : selectedAnswers[fieldName]
            }
            className={`${errors[item.id]?.required ? "border-danger" : ""}`}
            required={item.requerido}
            isDisabled={!canEdit}
            onBlur={() =>
              handleBlur({
                section,
                respuesta: selectedAnswers[fieldName],
                pregunta: item.pregunta,
              })
            }
            onChange={(e) =>
              handleChange(
                {
                  type,
                  target: {
                    name: fieldName,
                    value: e,
                    required: item.requerido,
                  },
                },
                item.id,
                item.pregunta,
                section,
                value
              )
            }
            options={options}
          />
        );
      break;
    case 3: // fecha
      const selected = selectedAnswers[fieldName]
        ? isDateInFormat(selectedAnswers[fieldName]) === "dd/mm/yyyy"
          ? new Date(
              convertDateFormatToYYYYMMDD(selectedAnswers[fieldName]) + " 00:00"
            )
          : new Date(selectedAnswers[fieldName] + " 00:00")
        : null;
      answerField = (
        <CustomDatePicker
          selected={selected}
          id={fieldName}
          key={fieldName}
          name={fieldName}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          required={item.requerido}
          disabled={!canEdit}
          onChange={(date) => {
            const resultDate = convertDateFormatToDDMMYYYY(convertDate(date));
            handleChange(
              {
                type,
                target: {
                  name: fieldName,
                  value: resultDate,
                  required: item.requerido,
                },
              },
              item.id,
              item.pregunta
            );
            const idRespuesta = item.respuestas_participante[0].id;
            req.post(
              `${API_URL}encuestas/respuestas/participantes/${idRespuesta}/update`,
              {
                respuesta: resultDate,
              }
            );
          }}
        />
      );

      break;
    case 5: // si o no
      answerField = (
        <select
          id={fieldName}
          key={fieldName}
          name={fieldName}
          value={selectedAnswers[fieldName] ?? ""}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          required={item.requerido}
          disabled={!canEdit}
          onBlur={handleBlur}
          onChange={(e) =>
            handleChange({ ...e, type }, item.id, item.pregunta, e.target.peso)
          }
        >
          <option value="">Selecciona</option>
          <option value="Si">Si</option>
          <option value="No">No</option>
          <option value="no_responde">No responde</option>
        </select>
      );
      break;
    case 6: // verdadero o falso
      answerField = (
        <select
          id={fieldName}
          key={fieldName}
          name={fieldName}
          value={selectedAnswers[fieldName] ?? ""}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          disabled={!canEdit}
          required={item.requerido}
          onBlur={handleBlur}
          onChange={(e) =>
            handleChange({ ...e, type }, item.id, item.pregunta, e.target.peso)
          }
        >
          <option value="">Selecciona</option>
          <option value="Verdadero">Verdadero</option>
          <option value="Falso">Falso</option>
          <option value="no_responde">No responde</option>
        </select>
      );
      break;
    case 7: // número
      answerField = (
        <input
          type="number"
          id={fieldName}
          key={fieldName}
          name={fieldName}
          value={selectedAnswers[fieldName] ?? ""}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          required={item.requerido}
          disabled={!canEdit}
          onBlur={handleBlur}
          onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
        ></input>
      );
      break;
    case 9:
      answerField = (
        <PatternFormat
          key={fieldName}
          id={fieldName}
          name={fieldName}
          format="###-#######-#"
          required={item.requerido}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          mask="_"
          value={selectedAnswers[fieldName]}
          onBlur={() =>
            handleBlur({
              section,
              respuesta: selectedAnswers[fieldName],
              pregunta: item.pregunta,
            })
          }
          onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
        />
      );
      break;

    case 8:
      answerField = (
        <PatternFormat
          key={fieldName}
          id={fieldName}
          name={fieldName}
          format="###-###-####"
          required={item.requerido}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          mask="_"
          value={selectedAnswers[fieldName]}
          onBlur={() =>
            handleBlur({
              section,
              respuesta: selectedAnswers[fieldName],
              pregunta: item.pregunta,
            })
          }
          onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
        />
      );
      break;
    case 4: // texto
    default:
      answerField = (
        <input
          type="text"
          key={fieldName}
          id={fieldName}
          name={fieldName}
          value={selectedAnswers[fieldName] ?? ""}
          className={`${errors[item.id]?.required ? "border-danger" : ""}`}
          required={item.requerido}
          disabled={!canEdit}
          onBlur={handleBlur}
          onChange={(e) => handleChange({ ...e, type }, item.id, item.pregunta)}
        ></input>
      );
      break;
  }
  

  // its for display a multiselect always when tipo_dato is multiple
  if (type.tipo_dato === 'multiple' || type.tipo_dato === 'unico') {
    const options = respuestas
      .filter((r) => r.activo)
      .map((r) => ({
        label: r.respuesta,
        value: r.respuesta,
      }));

    const value = typeof selectedAnswers[fieldName] === 'string' ?
      selectedAnswers[fieldName].split('|') :
      selectedAnswers[fieldName]
    answerField =
      type.tipo_dato === 'multiple' ? (
        <MultiSelect
          id={fieldName}
          key={fieldName}
          name={fieldName}
          value={(!value || !Array.isArray(value)) ? null : value.map(x => ({ label: x?.label || x, value: x?.value || x }))}
          isMulti
          required={item.requerido}
          isDisabled={!canEdit}
          onChange={(e) => {
            handleChange(
              {
                type,
                target: {
                  name: fieldName,
                  value: e.map(x => x.label).join('|'),
                  required: item.requerido,
                },
              },
              item.id,
              type.id
            )
          }}
          onBlur={() => handleBlur({
            pregunta: item.pregunta,
            section,
            respuesta: selectedAnswers[fieldName]
          })}
          allowSelectAll
          options={options}
        />
      ) : (
        <MultiSelect
          id={fieldName}
          name={fieldName}
          key={fieldName}
          defaultValue={((selectedAnswers[fieldName]?.label || '').includes('null') || selectedAnswers[fieldName]?.label === null) ? null : selectedAnswers[fieldName]}
          className={`${errors[item.id]?.required ? 'border-danger' : ''}`}
          required={item.requerido}
          isDisabled={!canEdit}
          onBlur={() => handleBlur({ section, respuesta: selectedAnswers[fieldName], pregunta: item.pregunta, })}
          onChange={(e) =>
            handleChange(
              {
                type,
                target: {
                  name: fieldName,
                  value: e,
                  required: item.requerido,
                },
              },
              item.id,
              item.pregunta,
              section,
              value
            )
          }
          options={options}
        />
      );
  }

  return (
    <div className="form-group">
      <label>{item.pregunta}</label>
      {answerField}
      {errors[item.id]?.required && (
        <p className="text-danger small">Este campo es requerido.</p>
      )}
    </div>
  );
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function convertDate(inputFormat) {
  if (isValidDate(inputFormat)) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [
      pad(d.getFullYear()),
      pad(d.getMonth() + 1),
      ("0" + d.getDate()).slice(-2),
    ].join("/");
  } else {
    return inputFormat;
  }
}

export default PollQuestion;
