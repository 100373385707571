import React, { Component } from 'react';   
import Utils from '../utils/config';
import axios from 'axios';
const API = Utils.API;

class AttendanceDates extends Component {
    constructor(props) {
      super(props);
      this.state = {
        actividad: [],
        popupAddDate: false,
        asistencia: [],
        dataId: 0
      }; 
    }   
    componentDidMount(){ 
      let conditionMet = false; 
        this.props.dateAsistencia.forEach((data, dataIndex) => { 
          if (data.participanteId === this.props.rowData.participante.id) {
            data.asistencia.forEach((asistencia, asistenciaIndex) => {
              if (asistencia.fecha === this.props.onlyDates) {
                this.setState({ dataId: asistencia?.tipo_asistencia?.id, asistencia: asistencia });
                conditionMet = true; // Se actualiza conditionMet cuando se ejecuta this.setState
              }
            });
          } 
        });

        // Ejecuta la función si la condición nunca se cumplió
        if (!conditionMet) { 
          
                let auth = JSON.parse(localStorage.getItem('user'));
                
          let access_token = auth.access_token; 
            axios
               .post(API + 'asistencia/actividades/asistencia/store', { fecha: this.props.onlyDates , tipo_asistencia: 6, asistencia_actividad: this.props.rowData.id }, {
                 headers: { Authorization: 'Bearer ' + access_token },
               })
               .then((response) => { 
                  setTimeout(function(){
                    window.location.reload();
                  }, 500);
                })
               .catch((error) => {
                 console.error(`Error al registrar asistencia para participante `, error);
               });
            }
            
        }
    
    changeAsistencia(asistencia, value){ 
      let auth = JSON.parse(localStorage.getItem('user'));
      let access_token = auth.access_token;
      
      axios
      .post(API + 'asistencia/actividades/asistencia/'+ asistencia + '/marcar_asistencia', {tipo_asistencia : value}, {
        headers: { Authorization: 'Bearer ' + access_token },
      })
      .then((response) => { 
        this.setState({dataId: value});  
        this.props.onAttendanceChange();
        
      })
      .catch((error) => {
        console.error(`Error al registrar asistencia para participante`, error);
      });
    }
    
    selectAsistencia(){ 
      return(
        <select onChange={(e) => { this.changeAsistencia(this.state.asistencia.id, e.target.value)}} style={{padding: '10px'}} value={this.state.dataId}>
          <option value={6}>-</option>
          <option value={1}>P</option>
          <option value={3}>A</option>
          <option value={4}>R</option>
          <option value={2}>T</option>
          <option value={5}>E</option>
        </select>
      )
    }
    render(){
      // const { rowData, onlyDates, dateAsistencia } = this.props; 
        return (
          this.selectAsistencia()
        );
    }
  }
export default AttendanceDates;