import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';

export default function FormulariosCompletados({ beneficiario }) {
  const [isLoading, setLoading] = useState(true);
  const [formularios, setFormularios] = useState([]);

  const beneficiario_id = beneficiario?.id;

  useEffect(() => {
    const params = {
      estado: 'no_canceladas',
      finalizado: 'finalizadas',
      participante: beneficiario_id,
    };
    req.get(`${API_URL}encuestas`, { params }).then((response) => {
      setFormularios(
        response.data.data.map((encuesta) => ({
          id: encuesta.formulario_id,
          nombre: encuesta.nombre,
        }))
      );
      setLoading(false);
    });
  }, [beneficiario_id]);

  return (
    <SpinnerPlaceholder isLoading={isLoading}>
      <div className='card beneficiariesDetail'>
        <div className='card-body'>
          <strong>Formularios Completados</strong>
          <Row>
            {formularios.map((formulario) => (
              <Col lg='3' key={formulario.id}>
                <div className='beneficiariesDetailContent'>
                  <p>
                    <strong>{formulario.nombre}</strong>
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </SpinnerPlaceholder>
  );
}
