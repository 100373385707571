
import React, { Component } from 'react';
import { ErrorMessage } from "formik";
import { Col } from 'react-bootstrap';
class FS extends Component {
    render() {
        const { label, name, children, col, className } = this.props;
        let errorClass = "";
        if(this.props.errors){
            errorClass = this.props.errors[name] ? "error" : null;
        }
        return (
            <Col className={className} xs={col[2]} md={col[1]} lg={col[0]}>
                <div id={name} className={"form-group " + errorClass}>
                   {label !== "" ? (<label>{label}</label>) : (<br/>)}
                    {children}
                    <ErrorMessage className="errorField" name={name} component="p" />
                </div>
            </Col>
        )
    }
}
export default FS;
