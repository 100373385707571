import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { object, string } from 'yup';
import Alert, { initialAlert } from '../common/Alert';
import MultiSelect from '../components/MultiSelect';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';

function SectorsUpdate(props) {
  const [isLoading, setLoading] = useState(true);
  const [sector, setSector] = useState({});
  const [loadingMunicipios, setLoadingMunicipios] = useState(true);
  const [municipios, setMunicipios] = useState([]);
  const [alert, setAlert] = useState(initialAlert);

  const { id } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert((prev) => ({ ...prev, show: false }));
    }, alert.timeout ?? 5000);
    return () => clearTimeout(timer);
  }, [alert.show, alert.timeout]);

  // cargar municipios
  useEffect(() => {
    setLoadingMunicipios(true);
    req
      .get(`${API_URL}selectores/municipios`)
      .then((response) => {
        setMunicipios(response.data.data);
      })
      .finally(() => setLoadingMunicipios(false));
  }, []);

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    req.get(`${API_URL}sectores/${id}/show`).then((response) => {
      const { municipio_id: municipio } = response.data.data;
      setSector({ ...response.data.data, municipio });
      setLoading(false);
    });
  }, [id]);

  const onSubmit = ({ nombre, municipio }, { setSubmitting }) => {
    const isNew = !id;
    if (isNew) {
      req
        .post(`${API_URL}sectores/store`, { nombre, municipio })
        .then(() => {
          props.history.goBack();
        })
        .catch((err) => {
          const message = err.response.data.message;
          setAlert({
            show: true,
            type: 'text-danger',
            message,
          });
        })
        .finally(() => setSubmitting(false));
    } else {
      req
        .post(`${API_URL}sectores/${id}/update`, {
          nombre,
          municipio,
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((err) => {
          const message = err.response.data.message;
          setAlert({
            show: true,
            type: 'text-danger',
            message,
          });
        })
        .finally(() => setSubmitting(false));
    }
  };

  const validationSchema = object().shape({
    nombre: string().trim().required('Nombre es un campo requerido.'),
    municipio: string()
      .trim()
      .required('Código de municipio es un campo requerido.'),
  });

  const { nombre, municipio } = sector;

  const opcionesMunicipios = municipios.map((m) => ({
    label: m.nombre,
    value: m.id,
  }));

  const municipioSeleccionado = opcionesMunicipios.find(
    (m) => m.value === municipio
  );

  return (
    <div className='container'>
      <div className='tittleButtons'>
        <div className='justify-content-end nav'>
          <Alert {...alert} />
        </div>
      </div>
      <div className='mainBox'>
        <SpinnerPlaceholder isLoading={isLoading}>
          <Formik
            initialValues={{ ...sector }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleChange, handleBlur, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className='row'>
                  <div className='form-group col'>
                    <label className='form-label'>Nombre</label>
                    <input
                      type='text'
                      name='nombre'
                      className='form-control'
                      value={nombre}
                      onChange={(e) => {
                        handleChange(e);
                        setSector((prev) => ({
                          ...prev,
                          nombre: e.target.value,
                        }));
                      }}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name='nombre'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                  <div className='form-group col'>
                    <label className='form-label'>Municipio</label>
                    <SpinnerPlaceholder isLoading={loadingMunicipios}>
                      <MultiSelect
                        name='municipio'
                        defaultValue={municipioSeleccionado}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange({
                            target: { name: 'municipio', value: e },
                          });
                          setSector((prev) => ({ ...prev, municipio: e }));
                        }}
                        options={opcionesMunicipios}
                      />
                    </SpinnerPlaceholder>
                    <ErrorMessage
                      name='municipio'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button
                    type='submit'
                    className='buttonSubmit'
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    <span>{isSubmitting ? 'Guardando...' : 'Guardar'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </SpinnerPlaceholder>
      </div>
    </div>
  );
}

export default withRouter(SectorsUpdate);
