import { ErrorMessage, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { array, object, string } from 'yup';
import DatePickerField from '../common/DatePicker';
import MultiSelect from '../components/MultiSelect';
import SecuredComponent from '../components/SecuredComponent';
import { convertDate } from '../poll/PollQuestion';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import { ROLE_SYSTEM_ADMIN } from '../utils/roles';
import { saveReportData } from './ReportDataService.js';

export function ModalReporte7({
  show,
  onHide,
  organizaciones,
  programas,
  tipoActividades,
  title,
  reportNo,
}) {
  const history = useHistory();
  const auth = JSON.parse(localStorage.getItem('user'));

  const initialValues = {
    fecha_inicio: '',
    fecha_final: '',
    organizaciones: [],
    tipo_participantes: '',
    programas: [],
    finalizado: 'finalizadas',
    estado_actividad: 'activos',
    estado_asistencia: 'activos',
    estado_participante: 'activos',
  };

  const validationSchema = object().shape({
    fecha_inicio: string()
      .nullable()
      .required('Fecha inicial es un campo requerido.'),
    fecha_final: string()
      .nullable()
      .required('Fecha final es un campo requerido.'),
    organizaciones: array()
      .of(object())
      .min(1, 'Organizaciones es un campo requerido.'),
    tipo_participantes: string().nullable(),
    programas: array().of(object()),
    finalizado: string().nullable(),
    estado_actividad: string().nullable(),
    estado_asistencia: string().nullable(),
    estado_participante: string().nullable(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {};
    for (let key in values) {
      if (values[key]) {
        if (key === 'fecha_inicio' || key === 'fecha_final') {
          params[key] = convertDate(values[key]);
        } else {
          params[key] = values[key];
        }
      }
    }
    params.programas = values.programas
      .map((elem) => `${elem.value}`)
      .join(`,`);
    params.organizaciones = values.organizaciones
      .map((elem) => `${elem.value}`)
      .join(`,`);
    params.tipos_actividad = values.tipos_actividad
      .map((elem) => `${elem.value}`)
      .join(`,`);




    if (params?.finalizado === 'no_finalizadas') {
      params.finalizado = 'finalizadas, no_finalizadas'
    }


    if (auth.user.role.nombre === ROLE_SYSTEM_ADMIN && params.financiador === '') {
      delete params.financiador;
    } else if (auth.user.role.nombre !== ROLE_SYSTEM_ADMIN) {
      params.financiador = auth.user.organizacion.financiador;
    }

    const obj = {
      'finalizadas': 'fecha_final',
      'no_finalizadas': 'fecha_inicio'
    }

    params.filtro_fechas = obj[params.finalizado] === undefined ? 'fecha_inicio' : obj[params.finalizado];

    req.get(`${API_URL}reportes/${reportNo}`, { params }).then((response) => {
      setSubmitting(false);
      saveReportData(response.data.data);
      history.push(`/reportes/${reportNo}?type=${response.data.type}&completo_programa=${params.finalizado === 'finalizadas'}`);
    });
  };

  return (
    <Modal size='lg' centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <Modal.Body>
              <SecuredComponent roles={[]}>
                <div className='form-group'>
                  <label className='form-label'>* Fecha inicio creación</label>
                  <DatePickerField name='fecha_inicio' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_inicio'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>* Fecha fin creación</label>
                  <DatePickerField name='fecha_final' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_final'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>* Organizaciones</label>
                  <MultiSelect
                    name='organizaciones'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('organizaciones', e);
                    }}
                    options={organizaciones.map((org) => ({
                      label: org.nombre,
                      value: org.id,
                    }))}
                  />
                  <ErrorMessage
                    component='p'
                    name='organizaciones'
                    className='small text-danger'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Tipo de participantes</label>
                  <select
                    name='tipo_participantes'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value=''>Ambos</option>
                    <option value='1'>Directo</option>
                    <option value='2'>Indirecto</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='tipo_participantes'
                    component='p'
                  />
                </div>
                {auth.user.role.nombre === ROLE_SYSTEM_ADMIN && <div className='form-group'>
                  <label className='form-label'>Financiador</label>
                  <select
                    name='financiador'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value=''>Selecciona</option>
                    <option value='USAID'>USAID</option>
                    <option value='KOICA'>KOICA</option>
                    <option value='IREX'>IREX</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='tipo_participantes'
                    component='p'
                  />
                </div>}
                <div className='form-group'>
                  <label className='form-label'>Programas</label>
                  <MultiSelect
                    name='programas'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('programas', e);
                    }}
                    options={programas.map((elem) => ({
                      label: elem.programa,
                      value: elem.id,
                    }))}
                  ></MultiSelect>
                  <ErrorMessage
                    component='p'
                    name='programas'
                    className='small text-danger'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Tipo de Actividad</label>
                  <MultiSelect
                    name='tipos_actividad'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('tipos_actividad', e);
                    }}
                    options={tipoActividades.map((elem) => ({
                      label: elem.nombre,
                      value: elem.id,
                    }))}
                  ></MultiSelect>
                  <ErrorMessage
                    component='p'
                    name='tipos_actividad'
                    className='small text-danger'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Finalizado</label>
                  <select
                    name='finalizado'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='finalizadas'>Completó programa</option>
                    <option value='no_finalizadas'>Sólo participó</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='finalizado'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Estado actividad</label>
                  <select
                    name='estado_actividad'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='activos'>Activas</option>
                    <option value='inactivos'>Inactivas</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='estado_actividad'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Estado asistencia</label>
                  <select
                    name='estado_asistencia'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='activos'>Activas</option>
                    <option value='inactivos'>Inactivas</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='estado_asistencia'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Estado participante</label>
                  <select
                    name='estado_participante'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='activos'>Activos</option>
                    <option value='inactivos'>Inactivos</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='estado_participante'
                    component='p'
                  />
                </div>
              </SecuredComponent>
            </Modal.Body>

            <Modal.Footer>
              <button
                type='submit'
                className='buttonSubmit'
                disabled={isSubmitting}
              >
                Buscar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalReporte7;
