import React, { Component } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class Expired extends Component {

  render() {
    return (
      <Container>
            <Row>
                      <Col lg="3">
                      </Col>
                      <Col lg="6">
            <div className="mainBox" style={{ padding: "4.5%" }}>
                <p style={{textAlign: "center", diplay: "block", marginTop: "20px", color: "#478ddd"}}>El link ha expirado</p>
            </div>
                </Col>
                <Col lg="3">
                      </Col>
                      </Row>
      </Container>
    );
  }
}
export default withRouter(Expired);