import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { clearReportData, loadReportData } from './ReportDataService.js';

export function TableReporte4_1() {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const data = loadReportData();
    const tData = [];
    for (let key in data) {
      const row = data[key];
      tData.push(row);
    }
    setTableData(tData);

    return () => clearReportData();
  }, []);

  const downloadCsv = () => {
    if (tableData.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'reporte',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of tableData) {
      const row = { ...elem };
      delete row.tableData; // remove this property added by MaterialTable
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };

  return (
    <div className='container'>
      <div className='mainBox'>
        <div className='d-flex justify-content-end'>
          {tableData.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
        <MaterialTable
          title=''
          columns={[
            {
              title: 'Encuesta',
              field: 'formulario_encuesta'
            },
            {
              title: 'Estado Encuesta',
              field: 'estado_encuesta'
            },
            { title: 'Organización', field: 'organizacion' },
            { title: 'Código', field: 'codigo' },
            { title: 'Nombres', field: 'nombres' },
            { title: 'Apellidos', field: 'apellidos' },
            { title: 'Sexo', field: 'sexo' },
            { title: 'Género', field: 'genero' },
            {
              title: 'Fecha de Nacimiento',
              field: 'fecha_nacimiento'
            },
            { title: 'Edad', field: 'edad' },
            {
              title: 'Tipo Participante',
              field: 'tipo_participante'
            },
            { title: 'Discapacidad', field: 'discapacidad' },
            {
              title: 'Pertenece a algún Grupo Vulnerable?',
              field: 'vulnerabilidad'
            },
            {
              title: 'Donde Naciste',
              field: 'donde_naciste'
            },
            { title: 'Direccion', field: 'direccion' },
            { title: 'Barrio', field: 'barrio' },
            { title: 'Sector', field: 'sector' },
            { title: 'Municipio', field: 'municipio' },
            { title: 'Provincia', field: 'provincia' },
            { title: 'Celular', field: 'celular' },
            { title: 'Telefono', field: 'telefono' },
            { title: 'Contacto', field: 'contacto' },
            {
              title: 'Tipo Contacto',
              field: 'tipo_contacto'
            },
            {
              title: 'Telefono Contacto',
              field: 'telefono_contacto'
            }
          ]}
          options={{
            search: true,
            draggable: false,
            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 60
          }}
          data={tableData}
        />
      </div>
    </div>
  );
}

export default TableReporte4_1;
