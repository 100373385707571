import React, { Component } from "react";
import { Button, Col, Row, Form, Modal, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { req } from "../utils/request.js";
import Alerts from "../common/Alerts";
import $ from "jquery";
import {checkStatus} from "../components/functions";
import Audits from '../components/Audits';

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { API_URL } from '../utils/config';


class ActivitiesTypesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      popupAddActivityType: false,
      activitiestype: [],
      editingData: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      auditType: "activity_type", 
      auditID: 0,
      showAudit: false 
    };
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);

    this.show_PopUpActivitiesType = this.show_PopUpActivitiesType.bind(this);
    this.hide_PopUpActivitiesType = this.hide_PopUpActivitiesType.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.administracion .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.tipo-actividades").addClass("active");
    });
    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);
    req.get(`${API_URL}tipos/actividad`).then(response => {
      this.setState({ activitiestype: response.data.data ?? [] });
    });
  }

  showAudit(data){
    this.setState({ 
        auditID: data.id,
        showAudit: true
    })
  }
  closeAudit(){
    this.setState({ 
        showAudit: false
    })
  }
  editRow(data){
    this.show_PopUpActivitiesType(data);
  }
  desactivateRow(data){
    data.activo = false;
    req.post(`${API_URL}tipos/actividad/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Tipo de Actividad ha sido desactivado con éxito'
      });
    });
  }
  activeRow(data){
    data.activo = true;
    req.post(`${API_URL}tipos/actividad/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Tipo de Actividad ha sido activado con éxito'
      });
    });
  }

  show_PopUpActivitiesType(data) {
    this.setState({ popupAddActivityType: true, editingData: data });
  }
  hide_PopUpActivitiesType() {
    this.setState({ popupAddActivityType: false });
  }
  render() {
    const {
      activitiestype,
      editingData,
      popupAddActivityType,
      alertMessage,
      alertShow,
      alertType,
    } = this.state;
    const addActivitiesTypesValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
    });
    return (
      <Container>
        {this.state.showAudit && <Audits close={this.closeAudit} show={this.state.showAudit} tipo={this.state.auditType} id={this.state.auditID} /> }
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <span
                className="nav-link"
                onClick={this.show_PopUpActivitiesType}
              >
                Crear Tipo de Actividad
              </span>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              columns={[
                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "nombre" },
                { title: "Estatus", field: "activo",
                    render: rowData => (
                        checkStatus(rowData.activo)
                    )
            }
              ]}
              localization={{
                header: {
                    actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                    emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "left",
                actionsColumnIndex: -1,
                paging: true,
                pageSize: 20,
                draggable: false,
              }}
              data={activitiestype}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, rowData) => this.editRow(rowData)
                },
                rowData => ({
                    icon: DoNotDisturbOnOutlinedIcon,
                    tooltip: 'Desactivar',
                    onClick: (event, rowData) => this.desactivateRow(rowData),
                    disabled: rowData.activo === false
                }),
                rowData => ({
                    icon: CheckCircleOutlinedIcon,
                    tooltip: 'Activar',
                    onClick: (event, rowData) => this.activeRow(rowData),
                    disabled: rowData.activo === true
                }),
                rowData => ({
                    icon: HistoryIcon,
                    tooltip: 'Auditoria',
                    onClick: (event, rowData) => this.showAudit(rowData)
                })
            ]}
            />
          </div>
        </CSSTransition>
        <Modal
          centered
          show={popupAddActivityType}
          onHide={this.hide_PopUpActivitiesType}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Tipo de Actividad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                nombre: editingData.nombre || "",
              }}
              validationSchema={addActivitiesTypesValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                values.activo = true;

                if (editingData.id !== undefined) {
                  delete values.id;

                  req.post(
                    `${API_URL}tipos/actividad/${editingData.id}/update`,
                    values
                  ).then(() => {
                    this.setState({
                      alertShow: true,
                      alertType: 'success',
                      alertMessage: 'El tipo de actividad ha sido actualizada con éxito'
                    });
                    return req.get(`${API_URL}tipos/actividad`);
                  }).then(response => {
                    this.setState({
                      activitiestype: response.data.data ?? []
                    })
                  });
                } else {
                  req.post(`${API_URL}tipos/actividad/store`, values).then(() => {
                    this.setState({
                      alertShow: true,
                      alertType: 'success',
                      alertMessage: 'El tipo de actividad fue creada con éxito'
                    });
                    return req.get(`${API_URL}tipos/actividad`);
                  }).then(response => {
                    this.setState({
                      activitiestype: response.data.data ?? []
                    })
                  });
                }
                this.hide_PopUpActivitiesType();

                resetForm();
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <div className="form-group">
                        <label>Nombre</label>
                        <Field type="text" name="nombre" />
                        <ErrorMessage
                          className="errorField"
                          name="nombre"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="primary "
                        >
                          {isSubmitting ? "Guardando..." : "Guardar"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
export default withRouter(ActivitiesTypesList);