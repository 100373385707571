import { ErrorMessage, Form, Formik } from 'formik';
import { format, parseISO } from 'date-fns'; 
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { number, object, string } from 'yup';
import DatePickerField from '../common/DatePicker';
import { Modal } from 'react-bootstrap';
import SecuredComponent from '../components/SecuredComponent';
import activityService from '../services/activities';
import AuthService from '../services/auth.service';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import { ROLE_SYSTEM_ADMIN } from '../utils/roles'; 

export function UpdateActivity(props) {
  const [isLoading, setLoading] = useState(true);
  const [organizaciones, setOrganizaciones] = useState([]);
  const [tipoActividades, setTipoActividades] = useState([]);
  const [responseFormSubmited, setResponseFormSubmited] = useState([]);
  const [popupActivityCreated, setPopupActivityCreated] = useState(false);
  const [programas, setProgramas] = useState([]);
  const [formState, setFormState] = useState({
    nombre: '',
    comentarios: '',
    programa: '',
    meta: '', 
    organizacion: '',
    fecha_inicio: '',
    fecha_cierre: '', 
    tipo: ''
  });

  const auth = AuthService.getCurrentUser();
  const isAdmin = auth?.user?.role?.nombre === ROLE_SYSTEM_ADMIN;
  const activity_id = props.match.params.activity_id;

  // load activity, if editing
  useEffect(() => {
    if (!activity_id) {
      setLoading(false);
      return;
    }
    activityService.find(activity_id).then((response) => {
      const actividad = response.data.data;
      const { nombre, comentarios, fecha_inicio, fecha_cierre } = actividad;
      const programa = actividad.programa.id;
      const organizacion = actividad.organizacion.id;
      const tipo = actividad.tipo.id;
      const meta = actividad.meta == null ? "" : actividad.meta;
      setFormState({
        nombre,
        programa,
        comentarios,
        meta,
        organizacion,
        fecha_inicio,
        fecha_cierre,
        tipo
      });
      setLoading(false);
    });
  }, [activity_id]);

  // load programs
  useEffect(() => {
    req.get(`${API_URL}selectores/programas`).then((response) => {
      setProgramas(response.data.data ?? []);
    });
  }, []);

  // load organizaciones
  useEffect(() => {
    if (!isAdmin) {
      const currentUser = AuthService.getCurrentUser();
      setFormState((prev) => ({
        ...prev,
        organizacion: currentUser?.user?.organizacion?.id,
      }));
      return;
    }
    req
      .get(`${API_URL}selectores/organizaciones`)
      .then((response) => setOrganizaciones(response.data.data ?? []));

    req
      .get(`${API_URL}tipos/actividad`)
      .then((response) => setTipoActividades(response.data.data ?? []));
  }, [isAdmin]);

  const validationSchema = object().shape({
    nombre: string().trim().required('Nombre es un campo requerido.'),
    comentarios: string().trim().nullable().optional(),
    meta: number().nullable(),
    programa: number().nullable().required('Programa es un campo requerido.'),
    fecha_inicio: string()
      .nullable()
      .required('Fecha de inicio es un campo requerido.'),
    fecha_cierre: string()
      .nullable()
      .required('Fecha de cierre es un campo requerido.'),
    organizacion: number()
      .nullable()
      .required('Organización es un campo requerido.'),
    tipo: number()
      .nullable()
      .required('Tipo es un campo requerido.'),
  });

  const onSubmit = ({ fecha_inicio, fecha_cierre }, { setSubmitting }) => {
    setSubmitting(false);
    const isNew = !activity_id;
    // let these = this;
    if (isNew) {
      activityService
        .save({ ...formState, fecha_inicio, fecha_cierre })
        .then((response) => { 
          // props.history.push('/actividades');
          setResponseFormSubmited(response.data.data);
          setPopupActivityCreated(true);
        });
    } else {
      activityService
        .update({ ...formState, fecha_inicio, fecha_cierre, id: activity_id })
        .then(() => {
          props.history.push('/actividades');
        });
    }
  };

  const {
    nombre,
    comentarios,
    programa,
    meta, 
    organizacion, 
    fecha_inicio, 
    fecha_cierre,
    tipo
  } = formState;

  return (
    <div className='container'>
      <div className='mainBox'>
        {isLoading ? (
          // show a spinner when loading
          <div className='text-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={{
              nombre,
              programa,
              comentarios,
              meta, 
              organizacion,
              fecha_inicio,
              fecha_cierre,
              tipo
            }} 
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValid, handleBlur, handleChange }) => (
              <Form>
                <div className='row'>
                  <div className='form-group col-4'>
                    <label className='form-label'>Nombre</label>
                    <input
                      name='nombre'
                      type='text'
                      onChange={(e) => {
                        handleChange(e);
                        setFormState((prev) => ({
                          ...prev,
                          nombre: e.target.value,
                        }));
                      }}
                      onBlur={handleBlur}
                      value={nombre}
                    />
                    <ErrorMessage
                      name='nombre'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                  <div className='form-group col-4'>
                    <label className='form-label'>Programa</label>
                    <select
                      name='programa'
                      value={programa}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormState((prev) => ({
                          ...prev,
                          programa: e.target.value,
                        }));
                      }}
                    >
                      <option value=''>Selecciona...</option>
                      {programas.map((programa) => (
                        <option
                          key={`programa_${programa.id}`}
                          value={programa.id}
                        >
                          {programa.programa}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      name='programa'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                  <div className='form-group col-4'>
                    <label className='form-label'>Fecha de inicio</label>
                    <DatePickerField name='fecha_inicio' value={fecha_inicio} />
                    <ErrorMessage
                      name='fecha_inicio'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                  <div className='form-group col-4'>
                    <label className='form-label'>Fecha de cierre</label>
                    <DatePickerField name='fecha_cierre' value={fecha_cierre} />
                    <ErrorMessage
                      name='fecha_cierre'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                  <SecuredComponent roles={[ROLE_SYSTEM_ADMIN]}>
                    <div className='form-group col-4'>
                      <label className='form-label'>Organización</label>
                      <select
                        name='organizacion'
                        value={organizacion}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFormState((prev) => ({
                            ...prev,
                            organizacion: e.target.value,
                          }));
                        }}
                      >
                        <option value=''>Selecciona....</option>
                        {organizaciones.map((org) => (
                          <option key={`organizacion_${org.id}`} value={org.id}>
                            {org.nombre}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        name='organizacion'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                  </SecuredComponent>
                  <SecuredComponent roles={[ROLE_SYSTEM_ADMIN]}>
                    <div className='form-group col-4'>
                      <label className='form-label'>Tipo</label>
                      <select
                        name='tipo'
                        value={tipo}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFormState((prev) => ({
                            ...prev,
                            tipo: e.target.value,
                          }));
                        }}
                      >
                        <option value=''>Selecciona....</option>
                        {tipoActividades.map((act) => (
                          <option key={`tipo_actividad_${act.id}`} value={act.id}>
                            {act.nombre}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        name='tipo'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                  </SecuredComponent>
                  <div className='form-group col-4'>
                    <label className='form-label'>Comentarios</label>
                    <textarea
                      name='comentarios'
                      value={comentarios}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormState((prev) => ({
                          ...prev,
                          comentarios: e.target.value,
                        }));
                      }}
                    ></textarea>
                    <ErrorMessage
                      name='comentarios'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                  <div className='form-group col-4'>
                    <label className='form-label'>Meta</label>
                    <input
                      name='meta'
                      type='number'
                      onChange={(e) => {
                        handleChange(e);
                        setFormState((prev) => ({
                          ...prev,
                          meta: e.target.value,
                        }));
                      }}
                      onBlur={handleBlur}
                      value={meta}
                    />
                    <ErrorMessage
                      name='meta'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 text-center'>
                    <button
                      type='submit'
                      className='buttonSubmit'
                      disabled={isSubmitting || !isValid}
                    >
                      <span>{isSubmitting ? 'Guardando...' : 'Guardar'}</span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <Modal
              size="md"
              centered
              show={popupActivityCreated} 
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>¡La actividad fue creada con exito!</p><br/>
                <p style={{ textAlign: 'center', marginBottom: 0 }}>Nombre de la actividad: <strong>{`${responseFormSubmited.nombre}`}</strong></p>
                <p style={{ textAlign: 'center', marginBottom: 0 }}>Organización: <strong>{`${responseFormSubmited.organizacion?.nombre}`}</strong></p>
                <p style={{ textAlign: 'center', marginBottom: 0 }}>ID de la actividad: <strong>{`${responseFormSubmited.id}`}</strong></p>
                <p style={{ textAlign: 'center', marginBottom: 0 }}>Fecha de inicio: <strong>{`${responseFormSubmited.fecha_inicio ? format(parseISO(responseFormSubmited.fecha_inicio), 'dd/MM/yyyy ',new Date() ) : null}`}</strong></p>
                <p style={{ textAlign: 'center', marginBottom: 0 }}>Fecha de cierre: <strong>{`${responseFormSubmited.fecha_inicio ? format(parseISO(responseFormSubmited.fecha_cierre), 'dd/MM/yyyy ',new Date() ) : null}`}</strong></p>
                 
              </Modal.Body>

              <Modal.Footer>
                    <button onClick={() => props.history.push('/actividades')}>Cerrar</button>
                  </Modal.Footer>
            </Modal>
    </div>
  );
}
 
export default withRouter(UpdateActivity);
