import React, { Component } from "react";
import { Row, Modal, Container, Nav } from "react-bootstrap";
import DatePickerField from "../common/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter, } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from "formik";
import { validationSchema } from "./_formSettings";
import { CSSTransition } from "react-transition-group";
import { Link } from 'react-router-dom';
import FS from "../components/FieldStructure";
import Alerts from "../common/Alerts";
import $ from "jquery";
import Utils from '../utils/config';
import {
  INDIRECT_FORMS
} from '../configurations/Configurations';
import AuthService from "../services/auth.service";
import { API_URL } from '../utils/config';
import { req } from '../utils/request.js';
import axios from 'axios';
import { ROLE_SYSTEM_ADMIN } from '../utils/roles';
import SecuredComponent from '../components/SecuredComponent';
import MultiSelect from '../components/MultiSelect';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { PatternFormat } from 'react-number-format';
import { removeUnderscoreAndHyphen } from "../poll/PollQuestion.jsx";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min.js";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      paises: [],
      provinces: [],
      popupValidateRegister: false,
      popupErrorRegister: false,
      alertShow: false,
      alertMessage: "",
      alertType: "",
      municipalities: [],
      organizations: [],
      municipalitiesToSelect: [],
      sectors: [],
      isEditing: false,
      editingData: [],
      sectorsToSelect: [],
      dataForSubmit: [],
      dataToPopupError: [],
      paisSeleccionado: 62,
      responseFormSubmited: [],
      entryform: [],
      configs: [],
      id: '',
      indirectForms: [],
      leaveValidation: true,
      files: [],
      tipoDiscapacidades: [],
      tipoVulnerabilidades: []
    };
    this.show_PopUpValidateRegister = this.show_PopUpValidateRegister.bind(this);
    this.hide_PopUpValidateRegister = this.hide_PopUpValidateRegister.bind(this);
    this.show_PopUpErrorRegister = this.show_PopUpErrorRegister.bind(this);
    this.hide_PopUpErrorRegister = this.hide_PopUpErrorRegister.bind(this);
  }

  show_PopUpValidateRegister(data, data2) {
    this.setState({ popupValidateRegister: true, dataForSubmit: data, responseFormSubmited: data2 });
  }
  hide_PopUpValidateRegister(data) {
    this.props.history.push(
      {
        pathname: "/beneficiarios/"
      }
    );
  }
  show_PopUpErrorRegister(data) {
    this.setState({ popupErrorRegister: true, dataToPopupError: data });
  }
  hide_PopUpErrorRegister(data) {
    this.setState({ popupErrorRegister: false });

  }

  handleUnload = (event) => {
    if (this.state.popupValidateRegister) {
      return;
    }
    const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = confirmationMessage;

    return confirmationMessage;

  };

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    window.removeEventListener('beforeunload', this.handleUnload);
  }
  componentDidMount() {
    // window.history.pushState(null, null, window.location.href);

    window.addEventListener('beforeunload', this.handleUnload);
    $(".nav-link").removeClass("active");
    $(".nav-link[href='/registro']").addClass("active");

    const requestConfigs = req.get(`${API_URL}config`, { params: { key: 'entryForm' } });
    const requestAllConfigs = req.get(`${API_URL}config`, { params: { paginacion: 999999 } });
    const requestForms = req.get(`${API_URL}selectores/formularios`);
    const requestPaises = req.get(`${API_URL}selectores/paises`);
    const requestorganizations = req.get(`${API_URL}selectores/organizaciones`);
    const tiposDiscapacidad = req.get(`${API_URL}selectores/tipos/discapacidad`);
    const tiposVulnerabilidad = req.get(`${API_URL}selectores/tipos/vulnerabilidad`);
    const tiposGenero = req.get(`${API_URL}selectores/generos_particulares`);

    axios.all([requestConfigs, requestForms,
      requestPaises, requestorganizations, requestAllConfigs,
      tiposDiscapacidad, tiposVulnerabilidad, tiposGenero]).then(axios.spread((...responses) => {
        const responseConfigs = responses[0].data.data || responses[0].data;
        const responseForms = responses[1].data.data || responses[1].data;
        const responsePaises = responses[2]?.data?.data ?? [{ id: 62, nombre: 'República Dominicana' }];
        const responseTipoDiscapacidad = responses[5]?.data?.data || []
        const responseTiposVulnerabilidad = responses[6]?.data?.data || []
        const responseGeneros = responses[7]?.data?.data || [];
        this.paisSelected(62); // id: 62 = Dominican Republic
        const responseorganizations = responses[3].data.data || responses[3].data;
        const responseAllConfigs = responses[4].data.data || responses[4].data;

        const entryform = responseForms.find(elem => elem.id === responseConfigs[0]?.value);

        const iF = JSON.parse(responseAllConfigs.find(x => x.key === INDIRECT_FORMS)?.value || null) || [];
        this.setState({
          indirectForms: iF,
          entryform: entryform,
          forms: responseForms,
          paises: responsePaises,
          organizations: responseorganizations,
          configs: responseConfigs[0],
          tipoDiscapacidades: responseTipoDiscapacidad,
          tipoVulnerabilidades: responseTiposVulnerabilidad,
          generos: responseGeneros
        });

        const beneficiario_id = this.props.match.params.beneficiario_id;
        if (beneficiario_id !== undefined) {
          req.get(`${API_URL}participantes/${beneficiario_id}/show`).then((res) => {
            const data = res.data.data || res.data;
            this.paisSelected(data.pais.id);
            this.provinciaSelected(data.provincia.id).then(() => {
              return this.municipalitySelected(data.municipio.id);
            }).then(() => {
              this.setState({
                isEditing: true,
                editingData: res.data.data || res.data,
                sectorSeleccionado: res.data.data.sector !== null ? res.data.data.sector.id : res.data.data.sector,
                paisSeleccionado: res.data.data.pais_nacimiento.id,
              });
              setTimeout(() => {
                this.setState({ show: true });
              }, 200);
            });
          });

        } else {

          setTimeout(() => {
            this.setState({ show: true });
          }, 200);
        }
      })).catch(errors => {
        // react on errors.
      })
    this.fetchFiles();
  }



  paisSelected(pais) {
    return req.get(`${API_URL}selectores/provincias`, { params: { pais } }).then(response => {
      const provinces = response.data.data ?? [];
      this.setState({ provinces });
    });
  }

  setPaisSeleccionado(e) {
    this.setState({ paisSeleccionado: e });
  }

  async fetchFiles() {
    const beneficiario_id = this.props.match.params.beneficiario_id;
  
   
    if (!beneficiario_id) {
      console.error('ID del beneficiario no está definido o es inválido.');
      this.setState({ files: [] });
      return;
    }
  
    try {
      const response = await req.get(`${API_URL}participantes/documentos?participante=${beneficiario_id}`);
      if (response.status === 200) {
        this.setState({ files: response.data.data });
      } else {
        console.error('Error en la solicitud de archivos:', response.status, response.statusText);
      
      }
    } catch (error) {
      console.error('Error en la solicitud de archivos:', error);
  
    }
  }

  provinciaSelected(provincia) {
    this.setState({ provinciaSeleccionada: provincia, municipioSeleccionado: null, sectorSeleccionado: null });
    if (provincia === null) {
      return;
    }
    return req.get(`${API_URL}selectores/municipios`, { params: { provincia } }).then(response => {
      const municipalitiesToSelect = (response.data.data ?? []).sort((a, b) => {
        if (a.nombre < b.nombre) {
          return -1;
        } else if (a.nombre > b.nombre) {
          return 1;
        } else {
          return 0;
        }
      });
      this.setState({ municipalitiesToSelect });
    });
  }
  municipalitySelected(municipio) {
    this.setState({ municipioSeleccionado: municipio, sectorSeleccionado: null });
    if (municipio === null) {
      return;
    }
    req.get(`${API_URL}selectores/sectores`, { params: { municipio } }).then(response => {
      this.setState({ sectorsToSelect: response.data.data ?? [] });
    });
  }
  redirectToForm(value) {
    const user = this.state.responseFormSubmited;
    this.props.history.push({
      pathname: `/formularios/${value.id}/${user.id}`
    });
  }


  getVulnerabilidadValue(vulnerabilidad) {
    if ([1, true, 'si', 'Si', 'SI'].includes(vulnerabilidad)) {
      return 1
    } else if ([0, false, 'no', 'No', 'NO'].includes(vulnerabilidad)) {
      return 0
    }
    return ' '
  }

  render() {

    const {
      alertMessage,
      alertShow,
      entryform,
      isEditing,
      editingData,
      popupErrorRegister,
      popupValidateRegister,
      dataToPopupError,
      organizations,
      responseFormSubmited,
      alertType,
      indirectForms,
      leaveValidation
    } = this.state;
    const beneficiario_id = this.props.match.params.beneficiario_id;

    const auth = AuthService.getCurrentUser();


    const opcionesProvincias = [
      { label: 'Selecciona', value: null },
      ...this.state.provinces.map((p) => (
        {
          label: p.nombre,
          value: p.id
        }
      ))];
    const provinciaSeleccionada = opcionesProvincias.find(op => op.value === this.state.provinciaSeleccionada);

    const opcionesMunicipios = [
      { label: 'Selecciona', value: null },
      ...this.state.municipalitiesToSelect.map((m) => (
        {
          label: m.nombre,
          value: m.id
        }
      ))
    ];
    const municipioSeleccionado = opcionesMunicipios.find((m) => m.value === this.state.municipioSeleccionado);

    const opcionesSectores = [
      { label: 'Selecciona', value: null },
      ...this.state.sectorsToSelect.sort((a, b) => {
        return a.nombre - b.nombre;
      }).map((s) => (
        {
          label: s.nombre,
          value: s.id
        }
      ))
    ];
    const sectorSeleccionado = opcionesSectores.find(s => s.value === this.state.sectorSeleccionado);

    const opcionesPaises = [
      { label: 'Selecciona', value: null },
      ...this.state.paises.map(p => ({ label: p.nombre_espanol, value: p.id }))
    ];
    const paisSeleccionado = opcionesPaises.find(p => p.value === this.state.paisSeleccionado);

    const initialValues = {
      vulnerabilidad: this.getVulnerabilidadValue(editingData?.vulnerabilidad),
      tipo: editingData.tipo?.id.toString() ?? '1',
      registro: editingData.registro || "",
      nombres: editingData.nombres || "",
      apellidos: editingData.apellidos || "",
      pais: editingData.pais?.id ?? '62', // 62 = Dominican Republic
      provincia: editingData.provincia?.id || null,
      municipio: editingData.municipio?.id || null,
      sector: editingData.sector?.id || null,
      direccion: editingData.direccion || "",
      pais_nacimiento: editingData.pais_nacimiento?.id
        || 62,
      fecha_nacimiento: editingData.fecha_nacimiento || "",
      genero: editingData.genero || "Masculino",
      if_mobile_phone: editingData.movil === null ? "no" : "si",
      movil: editingData.movil || "",
      email: editingData.email || "",
      if_facebook: editingData.facebook === null ? "no" : "si",
      facebook: editingData.facebook || "",
      if_email: editingData.email === null ? "no" : "si",
      contacto_nombre: editingData.contacto_nombre || "",
      contacto_tipo: editingData.contacto_tipo || "",
      contacto_telefono: editingData.contacto_telefono || "",
      organizacion: editingData.organizacion?.id || (auth.user.role.nombre !== ROLE_SYSTEM_ADMIN ? auth.user.organizacion?.id : ""),
      telefono: editingData.telefono || "",
      if_home_phone: editingData.movil === null ? "no" : "si",
      institucion: editingData.institucion || "",
      funcion: editingData.funcion || "",
      discapacidad: editingData.discapacidad,
      genero_particular: editingData.genero_particular || 'Hombre',
      tipo_discapacidad: editingData?.discapacidad?.id.toString(),
      genero_particular: editingData?.genero_particular?.id.toString(),
      tipo_vulnerabilidad: editingData?.vulnerabilidad?.id
    };
    return (
      <Container>
        <Prompt
          when={!this.state.popupValidateRegister && leaveValidation}
          message="Estás seguro de que deseas dejar la página? Tus cambios podrían no ser guardados!"
        />
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}

              enableReinitialize={true}
              onSubmit={(values, { setSubmitting, resetForm, }) => {
                setSubmitting(true);
                if (values.vulnerabilidad === 'Selecciona') {
                  delete values.vulnerabilidad;
                }
                delete values.if_email;
                delete values.if_facebook;
                delete values.if_mobile_phone;
                delete values.if_home_phone;
                delete values.discapacidad;
                values.activo = true;

                if (values.telefono) values.telefono = removeUnderscoreAndHyphen(values.telefono);
                if (values.movil) values.movil = removeUnderscoreAndHyphen(values.movil);
                if (values.contacto_telefono) values.contacto_telefono = removeUnderscoreAndHyphen(values.contacto_telefono);

                if (isEditing) {
                  // const formData = new FormData();
                  // let discapacidadPayload = null;


                  // if (values.discapacidad === 'No definido') {
                  //   discapacidadPayload = 'no-contesto';
                  // } else if (values.discapacidad === true) {
                  //   discapacidadPayload = '1';
                  // } else if (values.discapacidad === 'No') {
                  //   discapacidadPayload = '0';
                  // }

                  // formData.append('discapacidad', discapacidadPayload);
                  // req.post(`${API_URL}participantes/${this.props.match.params.beneficiario_id}/discapacidad`, formData);


                  req.post(`${API_URL}participantes/${this.props.match.params.beneficiario_id}/update`, values).then((response) => {
                    this.props.history.push({
                      pathname: "/beneficiarios",
                      state: {
                        alertShow: true,
                        alertMessage: "El beneficiario ha sido actualizado",
                        alertType: "success",
                      },
                    });
                  });

                } else {
                  if (values.tipo === "1" && values.fecha_nacimiento === "") {
                    values.fecha_nacimiento = "1980-01-01"
                  }

                  req.post(API_URL + "participantes/store", values).then((response) => {
                    resetForm();
                    setSubmitting(false);
                    this.setState({ id: response.data.data.codigo });
                    this.show_PopUpValidateRegister(values, response.data.data);
                  }).catch((error) => {
                    if (error.response) {
                      this.show_PopUpErrorRegister(error.response.data);
                      setSubmitting(false);
                    }

                  });
                }
                this.setState({ leaveValidation: false });
                $(window).scrollTop(0);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <SecuredComponent roles={[ROLE_SYSTEM_ADMIN]}>
                      <FS
                        label="Organización"
                        name="organizacion"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="organizacion" as="select">
                          <option value="null"> Selecciona </option>
                          {organizations.map((p) => (
                            <option key={p.id} value={p.id}> {p.nombre}</option>
                          ))}
                        </Field>
                      </FS>
                    </SecuredComponent>
                    <FS
                      label="Tipo de Participante"
                      name="tipo"
                      errors={errors}
                      col={[6]}
                    >
                      <Field
                        name="tipo"
                        as="select"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue('tipo', e.target.value);
                          if (e.target.value === '2') {
                            setFieldValue('fecha_nacimiento', '1984-01-01');
                          }
                          this.setState({ tipo: e.target.value });
                        }}
                      >
                        <option value="1"> Directo </option>
                        <option value="2"> Indirecto </option>
                      </Field>
                    </FS>
                    <FS
                      label="Fecha de registro"
                      name="registro"
                      errors={errors}
                      col={[6]}
                    >
                      <DatePickerField name="registro" />
                    </FS>
                    <FS label="Nombres" name="nombres" errors={errors} col={[6]}>
                      <Field type="text" name="nombres" />
                    </FS>
                    <FS
                      label="Apellidos"
                      name="apellidos"
                      errors={errors}
                      col={[6]}
                    >
                      <Field type="text" name="apellidos" />
                    </FS>
                    <div className='form-group col-6'>
                      <label className='form-label'>Provincia</label>
                      <MultiSelect
                        name='provincia'
                        defaultValue={provinciaSeleccionada}
                        value={provinciaSeleccionada}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange({
                            target: { name: 'provincia', value: e },
                          });
                          setFieldValue('municipio', null);
                          setFieldValue('sector', null);
                          this.provinciaSelected(e);
                        }}
                        options={opcionesProvincias}
                      />
                      <ErrorMessage
                        name='provincia'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label className='form-label'>Municipio</label>
                      <MultiSelect
                        name='municipio'
                        defaultValue={municipioSeleccionado}
                        value={municipioSeleccionado}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange({
                            target: { name: 'municipio', value: e },
                          });
                          setFieldValue('sector', null);
                          this.municipalitySelected(e);
                        }}
                        options={opcionesMunicipios}
                      />
                      <ErrorMessage
                        name='municipio'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label className='form-label'>Sector</label>
                      <MultiSelect
                        name='sector'
                        defaultValue={sectorSeleccionado}
                        value={sectorSeleccionado}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange({
                            target: { name: 'sector', value: e },
                          });
                          this.setState({ sectorSeleccionado: opcionesSectores.find(elem => elem.value === e) });
                        }}
                        options={opcionesSectores}
                      />
                      <ErrorMessage
                        name='sector'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                    {values.tipo === "1" ? (
                      <FS
                        label="Calle y Número"
                        name="direccion"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="direccion" />
                      </FS>
                    ) : null}
                    <FS
                      label="Fecha de nacimiento"
                      name="fecha_nacimiento"
                      errors={errors}
                      col={[6]}
                    >
                      <DatePickerField name="fecha_nacimiento" />
                    </FS>
                    <FS
                      label="¿Discapacidad?"
                      name="tipo_discapacidad"
                      errors={errors}
                      col={[6]}
                    >

                      <Field as="select" value={values.tipo_discapacidad}
                        onChange={(e) => {
                          if (e.target.value === 'Dato no recolectado') {

                          }
                          handleChange({
                            target: {
                              name: 'tipo_discapacidad',
                              value: e.target.value === 'Dato no recolectado'
                                ? null :
                                e.target.value
                            },
                          })
                        }}
                      >
                        <option>{'Dato no recolectado'} </option>
                        {this.state.tipoDiscapacidades.map(x => (
                          <option value={x.id}>{x.nombre} </option>
                        ))}
                      </Field>
                    </FS>

                    <div className='form-group col'>
                      <label className='form-label'>País de nacimiento</label>
                      <MultiSelect
                        name='pais_nacimiento'
                        defaultValue={paisSeleccionado}
                        value={paisSeleccionado}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          this.setPaisSeleccionado(e);
                          handleChange({
                            target: { name: 'pais_nacimiento', value: e },
                          });

                        }}
                        options={opcionesPaises}
                      />
                      <ErrorMessage
                        name='pais_nacimiento'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                    <FS label="Sexo" name="genero" errors={errors} col={[6]}>
                      <Field name="genero" as="select">
                        <option value="Masculino"> Masculino </option>
                        <option value="Femenino"> Femenino </option>
                      </Field>
                    </FS>
                    {/* <FS label="Género" name="genero_particular" errors={errors} col={[6]}>
                      <Field name="genero_particular" as="select">
                        <option value="Dato no recolectado"> Dato no recolectado </option>
                        <option value="Hombre"> Hombre </option>
                        <option value="Mujer"> Mujer </option>
                        <option value="No Binario"> No Binario </option>
                        <option value="Otro"> Otro </option>
                        <option value="No deseo responder"> No deseo responder </option>
                      </Field>
                    </FS> */}
                    <FS
                      label="Género"
                      name="genero"
                      errors={errors}
                      col={[6]}
                    >

                      <Field as="select" value={values.genero_particular}
                        onChange={(e) => {
                          if (e.target.value === 'Dato no recolectado') {

                          }
                          handleChange({
                            target: {
                              name: 'genero_particular',
                              value: e.target.value === 'Dato no recolectado'
                                ? null :
                                e.target.value
                            },
                          })
                        }}
                      >
                        <option>{'Dato no recolectado'} </option>
                        {this.state.generos.map(x => (
                          <option value={x.id}>{x.nombre} </option>
                        ))}
                      </Field>
                    </FS>
                    <FS
                      label="¿Tienes teléfono celular?"
                      name="if_mobile_phone"
                      errors={errors}
                      col={[6]}
                    >
                      <Field name="if_mobile_phone" as="select">
                        <option value="null"> Selecciona </option>
                        <option value="no"> No </option>
                        <option value="si"> Si </option>
                      </Field>
                    </FS>

                    {values.if_mobile_phone === "si" ? (
                      <FS
                        label="Teléfono Celular"
                        name="movil"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="movil">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => (
                            <PatternFormat
                              format="###-###-####"
                              mask='_'
                              {...field}
                            />
                          )}
                        </Field>
                      </FS>
                    ) : null}
                    {values.tipo === "1" ? (
                      <FS
                        label="¿Tienes teléfono de casa?"
                        name="if_home_phone"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="if_home_phone" as="select">
                          <option value="null"> Selecciona </option>
                          <option value="no"> No </option>
                          <option value="si"> Si </option>
                        </Field>
                      </FS>
                    ) : null}
                    {values.tipo === "1" &&
                      values.if_home_phone === "si" ? (
                      <FS
                        label="Teléfono Casa"
                        name="telefono"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="telefono">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => (
                            <PatternFormat
                              format="###-###-####"
                              mask='_'
                              {...field}
                            />
                          )}
                        </Field>

                      </FS>
                    ) : null}
                    <FS
                      label="¿Tienes correo electrónico?"
                      name="if_email"
                      errors={errors}
                      col={[6]}
                    >
                      <Field name="if_email" as="select">
                        <option value="null"> Selecciona </option>
                        <option value="no"> No </option>
                        <option value="si"> Si </option>
                      </Field>
                    </FS>

                    {values.if_email === "si" ? (
                      <FS
                        label="Correo electrónico"
                        name="email"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="email" />
                      </FS>
                    ) : null}
                    {values.tipo === "1" ? (
                      <FS
                        label="¿Tienes Facebook?"
                        name="if_facebook"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="if_facebook" as="select">
                          <option value="null"> Selecciona </option>
                          <option value="no"> No </option>
                          <option value="si"> Si </option>
                        </Field>
                      </FS>
                    ) : null}
                    {values.tipo === "1" &&
                      values.if_facebook === "si" ? (
                      <FS
                        label="Usuario de Facebook"
                        name="facebook"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="facebook" />
                      </FS>
                    ) : null}
                    {values.tipo === "2" ? (
                      <FS
                        label="Institución"
                        name="institucion"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="institucion" />
                      </FS>
                    ) : null}
                    {values.tipo === "2" ? (
                      <FS
                        label="Función"
                        name="funcion"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="funcion" />
                      </FS>
                    ) : null}
                  </Row>
                  {values.tipo === "1" ? (
                    <div>
                      <strong>
                        En caso de no poder localizarte, ¿a quién podemos
                        contactar?
                      </strong>
                      <Row>
                        <FS
                          label="Nombre de la persona de contacto"
                          name="contacto_nombre"
                          errors={errors}
                          col={[6]}
                        >
                          <Field type="text" name="contacto_nombre" />
                        </FS>
                        <FS
                          label="Parentesco"
                          name="contacto_tipo"
                          errors={errors}
                          col={[6]}
                        >
                          <Field name="contacto_tipo" as="select">
                            <option value="null"> Selecciona </option>
                            <option value="Abuelo/a"> Abuelo/a </option>
                            <option value="Amigo/a"> Amigo/a </option>
                            <option value="Cunado/a"> Cuñado/a </option>
                            <option value="Esposo/a"> Esposo/a </option>
                            <option value="Hermano/a"> Hermano/a </option>
                            <option value="Madrastra/Padrastro"> Madrastra/Padrastro </option>
                            <option value="Madre"> Madre </option>
                            <option value="Padre"> Padre </option>
                            <option value="Padrino/Madrina"> Padrino/Madrina </option>
                            <option value="Primo/a"> Primo/a </option>
                            <option value="Suegro/a"> Suegro/a </option>
                            <option value="Tio/a"> Tio/a </option>
                            <option value="Vecino/a"> Vecino/a </option>
                          </Field>
                        </FS>
                        <FS
                          label="Teléfono de la persona de contacto"
                          name="contacto_telefono"
                          errors={errors}
                          col={[6]}
                        >
                          <Field name="contacto_telefono">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <PatternFormat
                                format="###-###-####"
                                mask='_'
                                {...field}
                              />
                            )}
                          </Field>

                        </FS>
                        <FS
                          label="¿Formas parte de algún grupo excluido o vulnerable?"
                          name="tipo_vulnerabilidad"
                          errors={errors}
                          col={[6]}

                        >
                          <Field name="tipo_vulnerabilidad" as="select">
                            <option >{'Dato no recolectado'} </option>
                            {this.state.tipoVulnerabilidades.map(x => (
                              <option value={x.id}>{x.nombre} </option>
                            ))}
                          </Field>
                        </FS>
                      </Row>
                    </div>
                  ) : (
                    <FS
                      label="¿Formas parte de algún grupo excluido o vulnerable?"
                      name="tipo_vulnerabilidad"
                      errors={errors}
                      col={[6]}

                    >
                      <Field name="tipo_vulnerabilidad" as="select">
                        <option >{'Dato no recolectado'} </option>
                        {this.state.tipoVulnerabilidades.map(x => (
                          <option value={x.id}>{x.nombre} </option>
                        ))}
                      </Field>
                    </FS>
                  )}
                  {this.state.isEditing && (
                    <>
                      <FS
                        label="Archivo(s) del participante"
                        name="file"
                        errors={errors}
                        col={[6]}
                      >
                        <input
                          id="file"
                          name="file"
                          type="file"
                          accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
                          onChange={async (event) => {
                            const reader = new FileReader();

                            reader.onload = () => {
                              setFieldValue('file', reader.result);
                              const API = Utils.API;
                              const payload = new FormData();

                              const fileName = event.target.files[0]?.name;
                              fetch(reader.result)
                                .then(res => res.blob())
                                .then(blob => {
                                  const file = new File([blob], blob.name);
                                  payload.append('participante', beneficiario_id);
                                  payload.append('documento', file);
                                  payload.append('descripcion', fileName);
                                  req.post(API + 'participantes/documentos/store', payload)
                                    .then(() => {
                                      this.fetchFiles();
                                    });

                                })
                            };
                            const file = event.currentTarget.files[0];
                            reader.readAsDataURL(file);
                          }}
                        />
                      </FS>

                      <FS
                        label="Archivos"
                        name='archivos'
                        errors={errors}
                        col={[6]}

                      >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {(this.state.files || []).map(x => (
                            <div style={{ marginBlock: 5 }} >
                              <a href={x.url} target={'_blank'} rel='noopener'>

                                {x.descripcion}
                              </a>
                              <RemoveCircleOutlineIcon
                                style={{ marginLeft: 10, cursor: 'pointer' }}
                                color='error'
                                onClick={() => {

                                  req.delete(`${API_URL}participantes/documentos/${x.id}/delete`, { params: { participante: beneficiario_id } })
                                    .then(() => {
                                      this.fetchFiles().then(() => {
                                        setFieldValue('file', null);
                                      });
                                    });
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </FS>
                    </>
                  )}
                  <button type="submit" className="buttonSubmit">{isEditing ? "Actualizar" : "Registrar"}</button>
                </form>
              )}
            </Formik>
            <Modal
              size="md"
              centered
              show={popupValidateRegister}
              onHide={this.hide_PopUpValidateRegister}
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <p style={{ textAlign: 'center' }}>¡El beneficiario <strong>{`${responseFormSubmited.nombres} ${responseFormSubmited.apellidos} ID: ${this.state.id}`}</strong> fue creado satisfactoriamente!</p>
                {responseFormSubmited.tipo?.id !== 1 ?
                  <>
                    <p style={{ textAlign: 'center' }}>¿Desea completarle un formulario?</p>
                    {indirectForms.map((form) => (
                      <div style={{ textAlign: 'center' }} key={form.id} className='d-block'>
                        <Link to={`/formularios/${form.id}/${responseFormSubmited.id}`}>
                          {`${form.nombre}`}
                        </Link>
                      </div>
                    ))}
                  </>
                  :
                  <p style={{ textAlign: 'center' }}>¿Desea completarle la <strong>{entryform.nombre}</strong>?</p>
                }
              </Modal.Body>
              {
                responseFormSubmited.tipo?.id === 1 ?
                  <Modal.Footer>
                    <button onClick={this.hide_PopUpValidateRegister}  >No</button>
                    <button onClick={(e) => { this.redirectToForm(entryform) }}  >Si</button>
                  </Modal.Footer>
                  :
                  <Modal.Footer>
                    <button onClick={() => this.props.history.push("/beneficiarios")}>No</button>
                  </Modal.Footer>
              }
            </Modal>
            <Modal
              size="md"
              centered
              show={popupErrorRegister}
              onHide={this.hide_PopUpErrorRegister}
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <p style={{ textAlign: 'center' }}>El/la beneficiario/a "No" creado. Existe al menos un beneficiario con datos similares en el nombre, apellido, sexo, fecha de nacimiento y país de nacimiento.</p>
                <p style={{ textAlign: 'center' }}>El participante es: <strong className='text-danger text-uppercase'>{`${dataToPopupError.nombres} ${dataToPopupError.apellidos} (ID: ${dataToPopupError.codigo})`}</strong></p>
              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => this.props.history.push("/beneficiarios")}>Ok</button>
              </Modal.Footer>
            </Modal>
          </div>
        </CSSTransition>
      </Container>
    );
  }
}
export default withRouter(Register);