import React, { Component } from "react";
import { Container, Modal, Row, Nav, Button } from "react-bootstrap";
import DatePickerField from "../common/DatePicker";
import { Formik, Field } from "formik";
import { CSSTransition } from "react-transition-group";
import FS from "../components/FieldStructure";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Alerts from "../common/Alerts";
import { GET } from "../utils/request.js";
import $ from "jquery";
import Select from 'react-select';
import Utils from "../utils/config";
import { components } from "react-select";
import makeAnimated from "react-select/animated"
import Audits from '../components/Audits';
import { Col } from "react-bootstrap";
import * as Yup from "yup";

const API = Utils.API;

let auth = JSON.parse(localStorage.getItem('user'));

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label style={{ display: "inline" }}>{props.label}</label>
            </components.Option>
        </div>
    );
};
const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);
const animatedComponents = makeAnimated();

class PollView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formwizard: [],
            initialValues: [],
            validationSchema: [],
            entryForm: [],
            exitForm: [],
            laboralForm: [],
            formfields: [],
            answerforms: [],
            position: 0,
            forms: [],
            popupPollCreated: false,
            popupPollCreatedData: [],
            participants: [],
            auditType: "field_response",
            auditID: 0,
            showAudit: false
        };


        this.show_PopUpPollCreated = this.show_PopUpPollCreated.bind(this);
        this.hide_PopUpPollCreated = this.hide_PopUpPollCreated.bind(this);
        this.closeAudit = this.closeAudit.bind(this);
    }
    show_PopUpPollCreated(response) {
        this.setState({ popupPollCreated: true, popupPollCreatedData: response });
    }
    hide_PopUpPollCreated() {
        this.setState({ popupPollCreated: false });
        this.props.history.push({
            pathname: '/beneficiarios'
        });
    }


    showAudit(data) {
        this.setState({
            auditID: data,
            showAudit: true
        })
    }
    closeAudit() {
        this.setState({
            showAudit: false
        })
    }
    componentDidMount() {
        $(".nav-link").removeClass("active");
        let these = this;
        setTimeout(function () {
            these.setState({ show: true });
        }, 200);
        const formId = this.props.match.params.formId;
        const participantId = this.props.match.params.participantId;
        const editForm = this.props.match.params.editForm;
        console.log(editForm);
        if (formId !== undefined) {
            axios.get(API + "configs", { params: { key: 'entryForm', is_enabled: true }, headers: { Authorization: "Bearer " + auth.access_token } }).then((res) => {
                this.setState({
                    isLoaded: true,
                    entryForm: res.data.data || res.data,
                });
            });
            axios.get(API + "configs", { params: { key: 'exitForm', is_enabled: true }, headers: { Authorization: "Bearer " + auth.access_token } }).then((res) => {
                this.setState({
                    isLoaded: true,
                    exitForm: res.data.data || res.data,
                });
            });
            axios.get(API + "configs", { params: { key: 'laboralForm', is_enabled: true }, headers: { Authorization: "Bearer " + auth.access_token } }).then((res) => {
                this.setState({
                    isLoaded: true,
                    laboralForm: res.data.data || res.data,
                });
            });
            if (editForm !== undefined) {
                axios.get(API + "answer-forms/" + editForm, { headers: { Authorization: "Bearer " + auth.access_token } }).then((res) => {
                    let data = res.data.data || res.data;
                    this.setState({
                        answerforms: data,
                        position: res.data.position
                    });
                });
            }
            GET(this, "forms/" + formId);
            GET(this, "form-fields");
            GET(this, "participants/" + participantId);
            axios.get(API + "form-wizard/" + formId, { headers: { Authorization: "Bearer " + auth.access_token } }).then((response) => {
                let realResponse = response.data.data || response.data;

                let validationSchema = {};
                let initialValues = {};
                let these = this;
                let realSection = [];
                Object.keys(realResponse.section).forEach(function (status, sid) {
                    let section = realResponse.section[sid];

                    let fieldSchema = "";

                    let dependents_section = [];
                    initialValues["section_" + section.id] = "";

                    // dependencias de secciones
                    if (section.has_dependency.length > 0) {
                        let formfields = these.state.formfields;
                        let sectionSchema = [];
                        Object.keys(section.has_dependency).forEach(function (status, key) {

                            if (section.has_dependency[key].is_enabled === true) {
                                dependents_section.push(section.has_dependency[key]);
                            }
                        });
                        section.has_dependency = dependents_section;
                        if (dependents_section.length !== 0) {

                            let conditionSection = []
                            Object.keys(dependents_section).forEach(function (status, key) {
                                conditionSection.push("field_" + dependents_section[key].form_field_id);
                            });
                            sectionSchema = generateValidation(dependents_section, conditionSection, section, Yup.string());
                        } else {
                            sectionSchema = Yup.string();
                        }
                        validationSchema["section_" + section.id] = sectionSchema;
                    }

                    //Preguntas de la sección en initialValues
                    Object.keys(section.fields).forEach(function (status, key) {
                        let field = section.fields[key];
                        initialValues["field_" + field.id] = these.state.answerforms["field_" + field.id] || "";

                        //dependencias de las preguntas

                        let dependents_fields = JSON.parse(JSON.stringify(dependents_section));;

                        if (field.dependent_fields !== []) {
                            Object.keys(field.dependent_fields).forEach(function (status, key) {
                                if (field.dependent_fields[key].is_enabled === true && field.dependent_fields[key].form_fiedl_id_show === field.id) {
                                    dependents_fields.push(field.dependent_fields[key]);
                                }
                            });
                        }
                        if (dependents_fields.length !== 0) {
                            realResponse.section[sid].fields[key].is_dependent = true;
                            let condition = [];
                            Object.keys(dependents_fields).forEach(function (status, key) {
                                condition.push("field_" + dependents_fields[key].form_field_id);
                            });
                            if (field.type._id === "621e56e0ef6d6654e9371c82") {
                                if (field.required) {
                                    fieldSchema = generateValidationArray(dependents_fields, condition, field, Yup.array().min(1, "Es un campo requerido").required("Es un campo requerido"));
                                } else {
                                    fieldSchema = generateValidationArray(dependents_fields, condition, field, Yup.array());
                                }
                            } else {

                                if (field.required) {
                                    fieldSchema = generateValidation(dependents_fields, condition, field, Yup.string().nullable().required("Es un campo requerido"));
                                } else {
                                    fieldSchema = generateValidation(dependents_fields, condition, field, Yup.string());
                                }
                            }
                        } else {

                            if (field.type._id === "621e56e0ef6d6654e9371c82") {
                                if (field.required) {
                                    fieldSchema = Yup.array().min(1, "Es un campo requerido").required("Es un campo requerido");
                                } else {
                                    fieldSchema = Yup.array();
                                }
                            } else {
                                if (field.required) {
                                    fieldSchema = Yup.string().nullable().required("Es un campo requerido");
                                } else {
                                    fieldSchema = Yup.string();
                                }
                            }
                        }
                        validationSchema["field_" + field.id] = fieldSchema;
                    });
                    realSection.push(section);
                });
                realResponse.section = realSection;
                $("h1").children("span").remove();
                $("h1").children("em").remove();
                $("h1").append("<span>" + this.state.forms.name + "</span>");
                $("a").click(function () {
                    $("h1").children("span").remove();
                })
                this.setState({
                    formwizard: realResponse,
                    initialValues: initialValues,
                    validationSchema: validationSchema
                });
            });
        } else {
        }
    }
    render() {
        const editForm = this.props.match.params.editForm;
        const { alertMessage, popupPollCreated, popupPollCreatedData, alertShow, alertType, formwizard, initialValues, validationSchema, position, participants } = this.state;
        return (
            <Container>
                {this.state.showAudit && <Audits close={this.closeAudit} show={this.state.showAudit} tipo={this.state.auditType} id={this.state.auditID} />}
                <div className="titleButtons">
                    <div class="justify-content-end nav">
                        <div class="nav-item">
                            <p class="nav-link" onClick={() => this.showAudit(this.props.match.params.formId)}><span>Historico</span></p></div>
                    </div>
                    <Nav className="justify-content-end">
                        <Alerts
                            these={this}
                            type={alertType}
                            message={alertMessage}
                            show={alertShow}
                        />
                    </Nav>
                </div>
                <CSSTransition
                    unmountOnExit
                    in={this.state.show}
                    timeout={200}
                    classNames="transitionPage"
                >
                    <div className="mainBox">
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validateOnMount={true}
                            validationSchema={Yup.object().shape(validationSchema)}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);

                                let type = "";
                                switch (this.props.match.params.formId) {
                                    case this.state.entryForm[0].value:
                                        type = "EE";
                                        break;
                                    case this.state.exitForm[0].value:
                                        type = "ES";
                                        break;
                                    case this.state.laboralForm[0].value:
                                        type = "ET";
                                        break;
                                    default:
                                        type = this.props.match.params.formId;
                                        break;
                                }

                                Object.keys(formwizard.section).forEach(function (status, key) {
                                    delete values["section_" + formwizard.section[key].id];
                                });
                                // Object.keys(values).forEach(function (key) {
                                //    let check = Array.isArray(values[key]);
                                //    if(check){
                                //        let newValue = [];
                                //     values[key].forEach(function(k){
                                //         newValue.push(k._id);
                                //     })
                                //     values[key] = newValue;
                                //    }
                                //  });

                                if (editForm !== undefined) {
                                    axios.put(API + "answer-forms/" + editForm, {
                                        form_id: this.props.match.params.formId,
                                        participant_id: this.props.match.params.participantId,
                                        organization_id: participants.organization_id,
                                        data: values,
                                        type: type,
                                        is_enabled: true
                                    }, { headers: { Authorization: "Bearer " + auth.access_token } })

                                    this.props.history.push({
                                        pathname: "/beneficiarios",
                                        state: {
                                            alertShow: true,
                                            alertMessage: "Encuesta actualizada",
                                            alertType: "success",
                                        },
                                    });

                                } else {
                                    axios.post(API + "answer-forms", {
                                        form_id: this.props.match.params.formId,
                                        participant_id: this.props.match.params.participantId,
                                        data: values,
                                        type: type,
                                        organization_id: participants.organization_id,
                                        address: participants.address,
                                        collateral: participants.collateral,
                                        municipality_id: participants.municipality_id,
                                        province_id: participants.province_id,
                                        sector_id: participants.sector_id,
                                        is_enabled: true
                                    }, { headers: { Authorization: "Bearer " + auth.access_token } }).then((response) => {

                                        this.show_PopUpPollCreated(response);
                                    })

                                }


                                resetForm();
                                setSubmitting(false);
                            }}
                        >
                            {(formik) => (
                                <form onSubmit={formik.handleSubmit}>
                                    <Row>
                                        <Col lg="6">
                                            <p>Participante: {participants.name + " " + participants.lastname}  (UUID: {participants.uuid !== undefined ? participants.uuid.toUpperCase() : null})</p></Col>
                                        <Col lg="6">
                                            {editForm !== undefined ? (
                                                <p style={{ textAlign: "right" }} >Encuesta No. {position}</p>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        {formwizard.section && formwizard.section.length > 0
                                            ? formwizard.section.sort(function (a, b) {
                                                return a.order - b.order;
                                            }).map((s) => (
                                                <Col lg={12}>
                                                    <div className={s.has_dependency && s.has_dependency.length > 0 ? editForm !== undefined ? "is_dependent hideField subBox mb-4" : "is_dependent hideField subBox mb-4" : "subBox mb-4"} id={"section_" + s.id}>
                                                        <strong>{s.name}</strong>
                                                        <Row>
                                                            {s.fields.sort(function (a, b) {
                                                                return a.order - b.order;
                                                            }).map((f) => (
                                                                <FS
                                                                    label={f.required ? f.name + " *" : f.name}
                                                                    name={"field_" + f.id}
                                                                    className={f.is_dependent ? editForm !== undefined ? "is_dependent hideField" : "is_dependent hideField" : ""}
                                                                    errors={formik.errors}
                                                                    col={[4]}
                                                                >
                                                                    {f.type._id === "621e56e0ef6d6654e9371c82" ? (
                                                                        <Select
                                                                            isMulti
                                                                            name={"field_" + f.id}
                                                                            getOptionLabel={(option) => option.name}
                                                                            getOptionValue={(option) => option._id}
                                                                            options={[{ name: "Seleccionar todos", _id: "*" }, ...f.responses_fields.sort(function (a, b) {
                                                                                if (a.name < b.name) { return -1; }
                                                                            })]}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            onChange={e => {
                                                                                // let value = e;
                                                                                // if(value.length != 0){
                                                                                //   if (e[0]._id == '*') {
                                                                                //     value = f.responses_fields;
                                                                                //   }
                                                                                // }
                                                                                var value = [];
                                                                                for (var i = 0, l = e.length; i < l; i++) {
                                                                                    value.push(e[i]._id);
                                                                                }
                                                                                console.log(value);
                                                                                formik.setFieldValue("field_" + f.id, value);
                                                                            }}
                                                                            value={findLabel(formik.values["field_" + f.id], f.responses_fields)}
                                                                        />

                                                                    ) : null}

                                                                    {f.type._id === "621e56d609a37e4ee83e23a2" ? (
                                                                        <Field name={"field_" + f.id} as="select">
                                                                            <option value="null"> Selecciona </option>
                                                                            {f.responses_fields.sort(function (a, b) {
                                                                                if (a.name < b.name) { return -1; }
                                                                            }).map((p) => (
                                                                                <option value={p._id}> {p.name}</option>
                                                                            ))}
                                                                        </Field>
                                                                    ) : null}

                                                                    {/* Si o No */}
                                                                    {f.type._id === "621e574f99bc356e77757d93" ? (
                                                                        <Field name={"field_" + f.id} as="select">
                                                                            <option value="null"> Selecciona </option>
                                                                            <option value="1"> Si </option>
                                                                            <option value="0"> No </option>
                                                                        </Field>
                                                                    ) : null}

                                                                    {/* Verdadero o Falso */}
                                                                    {f.type._id === "621e5757148269271b6b0512" ? (
                                                                        <Field name={"field_" + f.id} as="select">
                                                                            <option value="null"> Selecciona </option>
                                                                            <option value="1"> Verdadero </option>
                                                                            <option value="0"> Falso </option>
                                                                        </Field>
                                                                    ) : null}
                                                                    {/* Fecha */}
                                                                    {f.type._id === "621e56f599bc356e77757d92" ? (
                                                                        <DatePickerField name={"field_" + f.id} />

                                                                    ) : null}
                                                                    {/* Otros */}
                                                                    {f.type._id !== "621e56e0ef6d6654e9371c82" &&
                                                                        f.type._id !== "621e56d609a37e4ee83e23a2" &&
                                                                        f.type._id !== "621e574f99bc356e77757d93" &&
                                                                        f.type._id !== "621e5757148269271b6b0512" &&
                                                                        f.type._id !== "621e56f599bc356e77757d92" ? (
                                                                        <Field type="text" name={"field_" + f.id} />
                                                                    ) : null}

                                                                    {formik.touched && formik.error && <div>{formik.error}</div>}
                                                                </FS>

                                                            ))}

                                                        </Row>
                                                    </div>
                                                </Col>
                                            ))
                                            : null}
                                    </Row>
                                    <button type="submit" className="buttonSubmit">Enviar</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </CSSTransition>
                <Modal
                    centered
                    show={popupPollCreated}
                    onHide={this.hide_PopUpPollCreated}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <strong style={{ textAlign: "center" }}>La encuesta ha sido guardada con éxito</strong>
                        <p style={{ textAlign: "center" }}>Nombre y Apellido: {participants.name} {participants.lastname}</p>
                        <p style={{ textAlign: "center" }}>Código Único: {participants.uuid !== undefined ? participants.uuid.toUpperCase() : null}</p>
                        <p style={{ textAlign: "center" }}>Encuesta No. {popupPollCreatedData.data !== undefined ? popupPollCreatedData.data.position : null}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.hide_PopUpPollCreated}
                        >Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>

        );
    }
}
function generateValidation(dependents, condition, mainfield, valueThen) {

    return Yup.string().when(condition, {
        is: (field1, field2, field3, field4, field5, field6) => {
            let answera = dependents[0].answer;
            let answerb = null;
            let answerc = null;
            let answerd = null;
            let answere = null;
            if (dependents.length > 1) {
                answerb = dependents[1].answer;
            }
            if (dependents.length > 2) {
                answerc = dependents[2].answer;
            }
            if (dependents.length > 3) {
                answerd = dependents[3].answer;
            }
            if (dependents.length > 4) {
                answere = dependents[4].answer;
            }
            let finalReturn = false;
            let finalReturnb = false;
            let finalReturnc = false;
            let finalReturnd = false;
            let finalReturne = false;
            switch (dependents[0].operator) {
                case "0":
                    if (field1 === answera) { finalReturn = true; }
                    break;
                case "1":
                    if (parseInt(field1) <= parseInt(answera)) { finalReturn = true; }
                    break;
                case "2":
                    if (parseInt(field1) >= parseInt(answera)) { finalReturn = true; }
                    break;
                case "3":
                    if (field1 !== answera) { finalReturn = true; }
                    break;
                case "4":
                    if (field1 !== "null") { finalReturn = true; }
                    break;
                default:
                    break;
            }
            if (field2 !== undefined) {
                switch (dependents[1].operator) {
                    case "0":
                        if (field2 === answerb) { finalReturnb = true; }
                        break;
                    case "1":
                        if (parseInt(field2) <= parseInt(answerb)) { finalReturnb = true; }
                        break;
                    case "2":
                        if (parseInt(field2) >= parseInt(answerb)) { finalReturnb = true; }
                        break;
                    case "3":
                        if (field2 !== answerb) { finalReturnb = true; }
                        break;
                    case "4":
                        if (field2 !== "null") { finalReturnb = true; }
                        break;
                    default:
                        break;
                }
            } else {
                if (finalReturn === true) {
                    finalReturnb = true;
                } else {
                    finalReturnb = true;
                }
            }
            if (field3 !== undefined) {
                switch (dependents[2].operator) {
                    case "0":
                        if (field3 === answerc) { finalReturnc = true; }
                        break;
                    case "1":
                        if (parseInt(field3) <= parseInt(answerc)) { finalReturnc = true; }
                        break;
                    case "2":
                        if (parseInt(field3) >= parseInt(answerc)) { finalReturnc = true; }
                        break;
                    case "3":
                        if (field3 !== answerc) { finalReturnc = true; }
                        break;
                    case "4":
                        if (field3 !== "null") { finalReturnc = true; }
                        break;
                    default:
                        break;
                }
            } else {
                if (finalReturn === true && finalReturnb === true) {
                    finalReturnc = true;
                } else {
                    finalReturnc = false;
                }
            }
            if (field4 !== undefined) {
                switch (dependents[3].operator) {
                    case "0":
                        if (field4 === answerd) { finalReturnd = true; }
                        break;
                    case "1":
                        if (parseInt(field4) <= parseInt(answerd)) { finalReturnd = true; }
                        break;
                    case "2":
                        if (parseInt(field4) >= parseInt(answerd)) { finalReturnd = true; }
                        break;
                    case "3":
                        if (field4 !== answerd) { finalReturnd = true; }
                        break;
                    case "4":
                        if (field4 !== "null") { finalReturnd = true; }
                        break;
                    default:
                        break;
                }
            } else {
                if (finalReturn === true && finalReturnb === true && finalReturnc === true) {
                    finalReturnd = true;
                } else {
                    finalReturnd = false;
                }
            }
            if (field5 !== undefined) {
                switch (dependents[4].operator) {
                    case "0":
                        if (field5 === answere) { finalReturne = true; }
                        break;
                    case "1":
                        if (parseInt(field5) <= parseInt(answere)) { finalReturne = true; }
                        break;
                    case "2":
                        if (parseInt(field5) >= parseInt(answere)) { finalReturne = true; }
                        break;
                    case "3":
                        if (field5 !== answere) { finalReturne = true; }
                        break;
                    case "4":
                        if (field5 !== "null") { finalReturne = true; }
                        break;
                    default:
                        break;
                }
            } else {
                if (finalReturn === true && finalReturnb === true && finalReturnc === true && finalReturnd === true) {
                    finalReturne = true;
                } else {
                    finalReturne = false;
                }
            }
            if (mainfield !== undefined) {
                if (finalReturn === true && finalReturnb === true && finalReturnc === true && finalReturnd === true && finalReturne === true) {
                    $("#section_" + mainfield.id).removeClass("hideField");
                    $("#field_" + mainfield.id).parent().removeClass("hideField");
                    return true;
                } else {
                    $("#section_" + mainfield.id).addClass("hideField");

                    $("#field_" + mainfield.id).parent().addClass("hideField");
                    return false;
                }
            }

        },
        then: valueThen,
        otherwise: Yup.string()
    })

}
function generateValidationArray(dependents, condition, mainfield, valueThen) {

    return Yup.array().when(condition, {
        is: (field1, field2, field3) => {
            let answera = dependents[0].answer;
            let answerb = null;
            let answerc = null;
            if (dependents.length > 1) {
                answerb = dependents[1].answer;
            }
            if (dependents.length > 2) {
                answerc = dependents[2].answer;
            }
            let finalReturn = false;
            let finalReturnb = false;
            let finalReturnc = false;
            switch (dependents[0].operator) {
                case "0":
                    if (field1 === answera) { finalReturn = true; }
                    break;
                case "1":
                    if (parseInt(field1) <= parseInt(answera)) { finalReturn = true; }
                    break;
                case "2":
                    if (parseInt(field1) >= parseInt(answera)) { finalReturn = true; }
                    break;
                case "3":
                    if (field1 !== answera) { finalReturn = true; }
                    break;
                default:
                    break;
            }
            if (field2 !== undefined) {
                switch (dependents[1].operator) {
                    case "0":
                        if (field2 === answerb) { finalReturnb = true; }
                        break;
                    case "1":
                        if (parseInt(field2) <= parseInt(answerb)) { finalReturnb = true; }
                        break;
                    case "2":
                        if (parseInt(field2) >= parseInt(answerb)) { finalReturnb = true; }
                        break;
                    case "3":
                        if (field2 !== answerb) { finalReturnb = true; }
                        break;
                    default:
                        break;
                }
            } else {
                if (finalReturn === true) {
                    finalReturnb = true;
                } else {
                    finalReturnb = true;
                }
            }
            if (field3 !== undefined) {
                switch (dependents[2].operator) {
                    case "0":
                        if (field3 === answerc) { finalReturnc = true; }
                        break;
                    case "1":
                        if (parseInt(field3) <= parseInt(answerc)) { finalReturnc = true; }
                        break;
                    case "2":
                        if (parseInt(field3) >= parseInt(answerc)) { finalReturnc = true; }
                        break;
                    case "3":
                        if (field3 !== answerc) { finalReturnc = true; }
                        break;
                    default:
                        break;
                }
            } else {
                if (finalReturn === true && finalReturnb === true) {
                    finalReturnc = true;
                } else {
                    finalReturnc = false;
                }
            }

            if (mainfield !== undefined) {
                if (finalReturn === true && finalReturnb === true && finalReturnc === true) {
                    $("#section_" + mainfield.id).removeClass("hideField");
                    $("#field_" + mainfield.id).parent().removeClass("hideField");
                    return true;
                } else {
                    $("#section_" + mainfield.id).parent().addClass("hideField");

                    $("#field_" + mainfield.id).parent().addClass("hideField");
                    return false;
                }
            }

        },
        then: valueThen,
        otherwise: Yup.array()
    })

}
function findLabel(ids, data) {
    let realData = []
    if (ids !== undefined && ids !== "") {
        ids.map((id) =>
            data.filter(obj => {
                if (obj._id === id) {
                    realData.push({ name: obj.name, _id: obj._id })
                }
            })
        )
        return realData;
    }
}
export default withRouter(PollView);
