import React, { Component } from "react";
import { Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Link, withRouter } from "react-router-dom";
import Alerts from "../common/Alerts";
import Audits from '../components/Audits';
import $ from "jquery";
import { checkStatus, getName } from "../components/functions";

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { req } from '../utils/request.js';
import Utils from '../utils/config.js';

const url = `${Utils.API}municipios`;

class Municipalities extends Component {
  provinceNames = {};
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      municipalities: [],
      provinces: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      showAudit: null,
      auditType: 'municipios'
    };

    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);

  }
  componentDidMount() {
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.administracion .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.municipios").addClass("active");
    });
    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);
    req.get(`${Utils.API}provincias`, { estado: '$activos' }).then(response => {
      this.setState({ provinces: response.data ?? [] });
      (response.data.data ?? []).forEach(province => {
        this.provinceNames[province.id] = province.nombre;
      });
    });
    req.get(url).then(response => {
      this.setState({ municipalities: response.data.data ?? [] });
    });
  }

  desactivateRow(data) {
    data.activo = false;
    req.post(`${url}/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertMessage: `El Municipio ${data.nombre} ha sido desactivado con éxito`,
        alertType: 'success'
      });
      return req.get(`${url}`);
    }).then(response => this.setState({
      municipalities: response.data.data ?? []
    }));
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${url}/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertMessage: `El Municipio ${data.nombre} ha sido activado con éxito`,
        alertType: 'success'
      });
      return req.get(`${url}`);
    }).then(response => this.setState({
      municipalities: response.data.data ?? []
    }));
  }

  render() {
    const {
      municipalities,
      alertMessage,
      alertShow,
      alertType,
      provinces,
    } = this.state;
    return (
      <Container>
        {!!this.state.showAudit && (
          <Audits
            close={() => this.setState({ showAudit: null })}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        )}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <Link className="nav-link" to='/municipios/nuevo'>
                Crear Municipio
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              columns={[
                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "nombre" },
                {
                  title: "Provincia",
                  field: "provincia_id",
                  render: (rowData) => this.provinceNames[rowData.provincia_id] ?? 'Sin provincia',
                  customFilterAndSearch: (term, rowData) => ((getName(rowData.provincia_id, provinces) || "").toLowerCase()).indexOf(term.toLowerCase()) !== -1
                },
                {
                  title: "Estatus", field: "activo",
                  render: rowData => (
                    checkStatus(rowData.activo)
                  )
                },
              ]}

              actions={[
                rowData => ({
                  icon: ModeEditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: () => this.props.history.push(`/municipios/${rowData.id}/editar`)
                }),
                rowData => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: 'Desactivar',
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: rowData.activo === false
                }),
                rowData => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: 'Activar',
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.setState({ showAudit: rowData.id })
                })
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                paging: false
              }}
              data={municipalities}
            />
          </div>
        </CSSTransition>
      </Container>
    );
  }
}
export default withRouter(Municipalities);