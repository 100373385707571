import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { clearReportData, loadReportData } from './ReportDataService.js';
import unorm from 'unorm';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


function removeAccents(str) {
  return unorm.nfkd(str).replace(/[\u0300-\u036f]/g, '');
}
const customFilter = (term, rowData) => {
  term = removeAccents(term).toLowerCase();
  const rowDataString = Object.values(rowData).join(' ').toLowerCase();
  return rowDataString.includes(term);
};


export function TableReporte21({ numeroReporte, origen }) {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const tData = loadReportData();
    setTableData((tData ?? []).map(x => ({ ...x, sexo: capitalizeFirstLetter(x.sexo) })));

    return () => clearReportData();
  }, []);

  const downloadCsv = () => {
    if (tableData.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'reporte',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of tableData) {
      const row = { ...elem };
      delete row.tableData; // remove this property added by MaterialTable
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };

  return (
    <div className='container'>
      <div className='mainBox'>
        <div className='d-flex justify-content-end'>
          {tableData.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
        <MaterialTable
          customFilterAndSearch={customFilter}
          title=''
          columns={[
            {
              title: 'Organización',
              field: 'organizacion'
            },
            {
              title: 'Código',
              field: 'codigo'
            },
            {
              title: 'Programa',
              field: 'programa'
            },
            {
              title: 'Nombres',
              field: 'nombres'
            },
            {
              title: 'Apellidos',
              field: 'apellidos'
            },
            {
              title: 'Sexo',
              field: 'sexo'
            },
            { title: 'Género', field: 'genero' },
            {
              title: 'Edad',
              field: 'edad'
            },
            { title: 'Edad en el Registro', field: 'edad_registro'},
            {
              title: 'Completo el Porcentaje del Programa',
              field: 'completo_programa'
            },
            {
              title: 'Observación',
              field: 'observacion'
            },
            {
              title: 'Motivo',
              field: 'motivo'
            },
            {
              title: 'Cuenta con Discapacidad',
              field: 'discapacidad'
            },
            {
              title: 'Pertenece a algún Grupo Vulnerable?',
              field: 'vulnerabilidad'
            },
            {
              title: 'Donde Naciste',
              field: 'donde_naciste'
            },
            {
              title: 'Dirección',
              field: 'direccion'
            },
            {
              title: 'Barrio',
              field: 'barrio'
            },
            {
              title: 'Sector',
              field: 'sector'
            },
            {
              title: 'Municipio',
              field: 'municipio'
            },
            {
              title: 'Sector',
              field: 'sector'
            },
            {
              title: 'Provincia',
              field: 'provincia'
            },
            {
              title: 'Celular',
              field: 'celular'
            },
            {
              title: 'Telefono',
              field: 'telefono'
            },
            {
              title: 'Contacto',
              field: 'contacto'
            },
            {
              title: 'Tipo Contacto',
              field: 'tipo_contacto'
            },
            {
              title: 'Telefono Contacto',
              field: 'telefono_contacto'
            },
          ]}
          options={{
            search: true,
            draggable: false,
            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 60,
          }}
          data={tableData}
        />
      </div>
    </div>
  );
}

export default TableReporte21;
