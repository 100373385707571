import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class AnswerService {
  query(questionId, perPage = 30, estado) {
    const params = { paginacion: perPage };
    if (!!questionId) {
      params.formulario_item = questionId;
    }
    if (!!estado) {
      params.estado = estado;
    }
    return req.get(`${API_URL}formularios/respuestas`, { params });
  }
}

const service = new AnswerService();

export default service;
