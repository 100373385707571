import React, { Component, createRef } from "react";
import { Button, Col, Row, Container, Nav, Form } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MaterialTable, { MTableToolbar } from "material-table";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import RowOptions from "../components/RowOptions.js";
import { req } from "../utils/request.js";
import { ExportToCsv } from "export-to-csv";
import FS from "../components/FieldStructure.js";
import { withRouter } from "react-router-dom";
import Alerts from "../common/Alerts.js";
import Audits from "../components/Audits.js";
import $ from "jquery";
import { API_URL } from "../utils/config.js";
import MultiSelect from "../components/MultiSelect.js";

class CreateEditQuestionType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questiontypes: [],
            formfields: [],
            dependentfields: [],
            formfieldsAll: [],
            sections: [],
            sectionsFiltered: [],
            fieldresponses: [],
            fieldresponsesSelected: [],

            tableData: [],
            selectableQuestions: [],
            dependentfieldsSelected: [],
            isEditing: false,
            alertShow: false,
            alertMessage: "",
            alertType: "",
            dependence_type: "",
            auditType: "dependent_field",
            auditID: 0,
            showAudit: false,
            formCreation: [],
        };
        this.answer = React.createRef();
        this.peso = React.createRef();
        this.answerSubmit = React.createRef();
        this.addAnswer = this.addAnswer.bind(this);
        this.tableRef = createRef();


        this.action_RowOptions = this.action_RowOptions.bind(this);

        this.closeAudit = this.closeAudit.bind(this);
    }

    showAudit(data) {
        this.setState({
            auditID: data._id,
            showAudit: true,
        });
    }
    closeAudit() {
        this.setState({
            showAudit: false,
        });
    }
    async componentDidMount() {

        if (this.props.match.params.id) {
            try {
                const [response1, response2] = await Promise.all([
                    req.get(`${API_URL}tipos/pregunta/${this.props.match.params.id}/show`),
                    req.get(`${API_URL}respuestas/generales?tipo_pregunta=${this.props.match.params.id}`)
                ]);

                if (!response1?.data?.data || !response2?.data?.data) {
                    alert('Ocurrió un error tratando de obtener los datos de este tipo de pregunta')
                }

                this.setState({
                    formfields: {
                        nombre: response1?.data?.data?.nombre,
                        descripcion: response1?.data?.data?.descripcion,
                        tipo_dato: response1?.data?.data?.tipo_dato,
                    },
                    fieldresponsesSelected: (response2.data?.data || []).map((
                        { activo, peso, respuesta, id }) => ({
                            activo, peso, respuesta, id, mode: 'update'
                        }))
                })
            } catch (error) {
                alert(error?.message);
            }

        }

        $(document).ready(function () {
            $(".nav-link").removeClass("active");
            $(".nav-item.encuesta .nav-link").addClass("active");
            $(".dropdown-item").removeClass("active");
            $(".dropdown-item.campos").addClass("active");
        });
        let formCreation = JSON.parse(sessionStorage.getItem("formCreation"));
        req
            .get(`${API_URL}tipos/pregunta`, {
                params: { estado: "activos", paginacion: 999999 },
            })
            .then((response) => {
                this.setState({
                    questiontypes: response.data.data ?? [],
                });
            });
        req
            .get(`${API_URL}formularios/secciones`, {
                params: {
                    formulario: formCreation,
                    paginacion: 999999,
                },
            })
            .then((response) => {
                this.setState({
                    sectionsFiltered: response.data.data ?? response.data,
                });
            });

        setTimeout(() => {
            this.setState({ show: true });
        }, 500);
    }
    action_RowOptions(action, data) {
        const rows = [...this.state.fieldresponsesSelected];
        const rowToEditIndex = rows.findIndex(
            (elem) => elem.id && elem.respuesta === data.data.respuesta
        );
        switch (action) {
            case "Desactivar":
                if (rowToEditIndex >= 0) {
                    req
                        .post(
                            `${API_URL}respuestas/generales/${rows[rowToEditIndex].id}/toggle`
                        )
                        .then(() => {
                            rows[rowToEditIndex].activo = false;
                            this.setState({ fieldresponsesSelected: rows });
                        });
                }
                break;
            case "Activar":
                if (rowToEditIndex >= 0) {
                    req
                        .post(
                            `${API_URL}respuestas/generales/${rows[rowToEditIndex].id}/toggle`
                        )
                        .then(() => {
                            rows[rowToEditIndex].activo = true;
                            this.setState({ fieldresponsesSelected: rows });
                        });
                }
                break;
            default:
                // do nothing
                break;
        }
    }

    addAnswer() {
        if (this.answer.current.value === "") {
            return false;
        }
        const answer = {
            respuesta: this.answer.current.value,
            peso: this.peso.current.value,
            activo: true,
            mode: 'create',
            ...(!!this.props.match.params.id ? { modified: true } : {})

        };
        this.setState((state) => ({
            fieldresponsesSelected: [...state.fieldresponsesSelected, answer],
        }));
        this.answer.current.value = "";
        this.peso.current.value = "";
        this.answer.current.focus();
        this.tableRef.current.onQueryChange({});
    }


    addFrequencyOptions() {
        const answers = [
            ...this.state.fieldresponsesSelected,
            { respuesta: "Nunca", activo: true, peso: 1 },
            { respuesta: "Ocasionalmente", activo: true, peso: 2 },
            { respuesta: "Rara vez", activo: true, peso: 3 },
            { respuesta: "Algo a menudo", activo: true, peso: 4 },
            { respuesta: "Siempre", activo: true, peso: 5 },
            { respuesta: "No responde", activo: true, peso: 6 },
        ];
        this.setState({ fieldresponsesSelected: [...answers] });
        this.tableRef.current.onQueryChange({});
    }

    addAgreementOptions() {
        const answers = [
            ...this.state.fieldresponsesSelected,
            { respuesta: "Totalmente en desacuerdo", activo: true, peso: 1 },
            { respuesta: "En desacuerdo", activo: true, peso: 2 },
            { respuesta: "Ni acuerdo ni en desacuerdo", activo: true, peso: 3 },
            { respuesta: "De acuerdo", activo: true, peso: 4 },
            { respuesta: "Totalmente de acuerdo", activo: true, peso: 5 },
            { respuesta: "No responde", activo: true, peso: 6 },
        ];
        this.setState({ fieldresponsesSelected: [...answers] });
        this.tableRef.current.onQueryChange({});
    }

    addMotivationOptions() {
        const answers = [
            ...this.state.fieldresponsesSelected,
            { respuesta: "No tengo ninguna motivación", activo: true, peso: 1 },
            { respuesta: "No estoy totalmente motivado/a", activo: true, peso: 2 },
            { respuesta: "Me da igual", activo: true, peso: 3 },
            { respuesta: "Me siento algo motivado/a", activo: true, peso: 4 },
            { respuesta: "Me siento totalmente motivado/a", activo: true, peso: 5 },
            { respuesta: "No responde", activo: true, peso: 6 },
        ];
        this.setState({ fieldresponsesSelected: [...answers] });
        this.tableRef.current.onQueryChange({});
    }

    add1To5() {
        const answers = this.state.fieldresponsesSelected;
        for (let i = 1; i <= 5; i++) {
            const answer = { respuesta: `${i}`, activo: true, peso: i };
            answers.push(answer);
        }
        answers.push({ respuesta: `No responde`, activo: true, peso: 6 });
        this.setState({ fieldresponsesSelected: [...answers] });
        this.tableRef.current.onQueryChange({});
    }

    renderPossibleAnswers(formik) {
        const { fieldresponsesSelected } = this.state;
        const downloadCsv = () => {
            if (fieldresponsesSelected.length === 0) {
                return;
            }
            const options = {
                fieldSeparator: ",",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: false,
                title: "",
                filename: "Posibles Respuestas",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            };
            const csvExporter = new ExportToCsv(options);
            const csvData = [];
            for (let elem of fieldresponsesSelected) {
                const row = { ...elem };
                delete row.tableData; // remove this property added by MaterialTable
                csvData.push(row);
            }
            csvExporter.generateCsv(csvData);
        };
        return (
            <Row>
                <Col lg={12}>
                    <strong>Posibles Respuestas</strong>
                    <div className="subBox">
                        <div className="form-group">
                            <Row>
                                <Col lg={4}>
                                    <label>Añadir Respuesta</label>
                                    <input name="answer" ref={this.answer} type="text"></input>
                                </Col>
                                <Col lg={4}>
                                    <label>Peso</label>
                                    <input name="peso" ref={this.peso} type="text"></input>
                                </Col>
                                <Col lg={4}>
                                    <br />
                                    <Button
                                        variant="primary "
                                        ref={this.answerSubmit}
                                        className="secondaryButton"
                                        onClick={this.addAnswer}
                                    >
                                        Añadir
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <MaterialTable
                            tableRef={this.tableRef}
                            title=""
                            columns={[
                                {
                                    title: "Respuesta",
                                    field: "respuesta",
                                    editable: "onUpdate",
                                },
                                {
                                    title: "Peso",
                                    field: "peso",
                                    editable: "onUpdate",
                                },
                                {
                                    title: "Estatus",
                                    field: "activo",
                                    editable: "never",
                                    render: (rowData) => (rowData.activo ? "Activo" : "Inactivo"),
                                },
                                {
                                    title: "",
                                    searchable: false,
                                    field: "actions",
                                    editable: "never",
                                    render: (rowData) =>
                                        rowData.activo ? (
                                            <RowOptions
                                                data={rowData}
                                                parentCallback={this.action_RowOptions}
                                                options={["Desactivar"]}
                                            />
                                        ) : (
                                            <RowOptions
                                                data={rowData}
                                                parentCallback={this.action_RowOptions}
                                                options={["Activar"]}
                                            />
                                        ),
                                },
                            ]}
                            components={{
                                Toolbar: (props) => (
                                    <div>
                                        <MTableToolbar {...props} />

                                        <div style={{ position: 'absolute', top: 0, zIndex: 9 }} className="d-flex justify-content-end">
                                            {fieldresponsesSelected.length > 0 && (
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    onClick={downloadCsv}
                                                    title="Descargar CSV"
                                                >
                                                    <FileDownloadIcon />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ),
                            }}
                            options={{
                                search: true,
                                selection: false,
                                searchFieldAlignment: "right",
                                paging: true,
                                pageSize: 10,
                                draggable: false,
                            }}
                            data={(query) =>
                                new Promise((resolve) => {
                                    const { search, page, pageSize } = query;
                                    const totalCount = fieldresponsesSelected.length;
                                    const data = fieldresponsesSelected
                                        .filter(
                                            (respuesta) =>
                                                respuesta.respuesta
                                                    .toLowerCase()
                                                    .indexOf(search.toLowerCase()) >= 0
                                        )
                                        .slice(page * pageSize, pageSize * (page + 1));
                                    resolve({
                                        data,
                                        page,
                                        totalCount,
                                    });
                                })
                            }
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        console.log({ testingX: true })
                                        const answers = [...fieldresponsesSelected];
                                        answers[oldData.tableData.id].respuesta = newData.respuesta;
                                        answers[oldData.tableData.id].peso = newData.peso;
                                        answers[oldData.tableData.id].mode = 'update';
                                        answers[oldData.tableData.id].modified = true;
                                        // 5. Set the state to our new copy
                                        this.setState({
                                            fieldresponsesSelected: answers,
                                        });
                                        resolve();

                                        req.post(
                                            `${API_URL}formularios/respuestas/${newData.id}/update`,
                                            {
                                                respuesta: newData.respuesta,
                                                peso: newData.peso,
                                                formulario_item: newData.formulario_item_id,
                                            }
                                        );
                                    }),
                            }}
                        />
                    </div>
                </Col>
            </Row>
        );
    }

    render() {

        const {
            alertType,
            alertMessage,
            alertShow,
            formfields
        } = this.state;
        const validationSchema = Yup.object().shape({
            nombre: Yup.string().required("El nombre es un campo requerido."),
            tipo_dato: Yup.string().required("El Tipo de Dato es un campo requerido."),
            // descripcion: Yup.string().required("La descripción es un campo requerido."),
        });
        console.log(this.state)
        return (
            <Container>
                {this.state.showAudit && (
                    <Audits
                        close={this.closeAudit}
                        show={this.state.showAudit}
                        tipo={this.state.auditType}
                        id={this.state.auditID}
                    />
                )}
                <div className="titleButtons">
                    <Nav className="justify-content-end">
                        <Alerts
                            these={this}
                            type={alertType}
                            message={alertMessage}
                            show={alertShow}
                        />
                    </Nav>
                </div>
                <CSSTransition
                    unmountOnExit
                    in={this.state.show}
                    timeout={200}
                    classNames="transitionPage"
                >
                    <div className="mainBox">
                        <Formik
                            initialValues={{
                                nombre: formfields.nombre || "",
                                tipo_dato: formfields.tipo_dato || 'multiple',
                            }}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                values.activo = true;
                                setSubmitting(true);
                                const id = this.props.match.params.id
                                const urlTipoRespuesta = !!id ? `${API_URL}tipos/pregunta/${this.props.match.params.id}/update` : `${API_URL}tipos/pregunta/store`;
                                console.log({ values })
                                req
                                    .post(urlTipoRespuesta, {
                                        general: 1,
                                        nombre: values.nombre,
                                        descripcion: values.descripcion,
                                        tipo_dato: values.tipo_dato,
                                    })
                                    .then(async (response) => {
                                        if (response.status !== 200 && !response?.data?.data) {
                                            alert('Algo salió mal al intentar almacenar el tipo de pregunta');
                                            return;
                                        }
                                        await Promise.all(this.state.fieldresponsesSelected.map(async (item) => {
                                            // it is for not update items which have not been modified by the user
                                            if (!!id && !item?.modified) {
                                                return;
                                            }
                                            const urlPreguntasGenerales = !!id && item?.id && item?.mode === 'update' ? `${API_URL}respuestas/generales/${item.id}/update` : `${API_URL}respuestas/generales/store`;
                                            req
                                                .post(urlPreguntasGenerales, {
                                                    tipo_pregunta: response.data.data.id,
                                                    respuesta: item.respuesta,
                                                    peso: item.peso
                                                })
                                                .then((response) => {
                                                    if (response.status !== 200 && !response?.data?.data) {
                                                        alert(`Fallo al intentar almacenar la respuesta: ${item.respuesta} ` + response?.data?.message);
                                                    }
                                                })
                                                .catch(error => {
                                                    alert(`Fallo al intentar almacenar la respuesta: ${item.respuesta} ` + error?.response?.data?.message || error?.message);
                                                });
                                        }));

                                        this.props.history.push({
                                            pathname: "/tipo-pregunta",
                                            state: {
                                                alertShow: true,
                                                alertMessage: "Tipo Pregunta Creada",
                                                alertType: "success",
                                            },
                                        });
                                    });


                                resetForm();
                                setSubmitting(false);
                            }}
                        >
                            {(formik) => {
                                const arr = [
                                    { label: 'Múltiple', value: 'multiple' },
                                    { label: 'Único', value: 'unico' },
                                ];

                                return (
                                    (
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Row>
                                                <FS
                                                    label="Nombre *"
                                                    name="nombre"
                                                    errors={formik.errors}
                                                    col={[4]}
                                                >
                                                    <Field type="text" name="nombre" />
                                                </FS>
                                                <FS
                                                    label="Descripción"
                                                    name="descripcion"
                                                    errors={formik.errors}
                                                    col={[4]}
                                                >
                                                    <Field type="text" name="descripcion" />
                                                </FS>

                                                <FS
                                                    label="Tipo de Dato"
                                                    name="tipo_dato"
                                                    errors={formik.errors}
                                                    col={[4]}
                                                >
                                                    <MultiSelect
                                                        name='tipo_dato'
                                                        value={arr.find(x => x.value === formik.values.tipo_dato)}
                                                        onChange={(e) => formik.setFieldValue('tipo_dato', e)}
                                                        options={arr}
                                                    />
                                                </FS>


                                                {/* <div className='form-group' col>
                                                    <label className='form-label'>Tipo de Dato</label>
                                                    <MultiSelect
                                                        name='tipo_dato'
                                                        value={arr.find(x => x.value === formik.values.tipo_dato)}
                                                        onChange={(e) => {
                                                            console.log({ e })
                                                            formik.setFieldValue('tipo_dato', e);
                                                        }}
                                                        options={arr}
                                                    />
                                                    <ErrorMessage
                                                        component='p'
                                                        name='tipo_dato'
                                                        className='small text-danger'
                                                    />
                                                </div> */}

                                            </Row>


                                            <br />
                                            {this.renderPossibleAnswers(formik)}
                                            <Button
                                                type="submit"
                                                className="buttonSubmit"
                                                variant="primary "
                                            >
                                                {formik.isSubmitting ? "Guardando..." : "Guardar"}
                                            </Button>
                                        </Form>
                                    )
                                )
                            }}
                        </Formik>
                    </div>
                </CSSTransition>
            </Container>
        );
    }
}

export default withRouter(CreateEditQuestionType);
