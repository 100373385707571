import { ErrorMessage, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { array, object, string } from 'yup';
import DatePickerField from '../common/DatePicker';
import MultiSelect from '../components/MultiSelect';
import SecuredComponent from '../components/SecuredComponent';
import { convertDate } from '../poll/PollQuestion';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import { ROLE_SYSTEM_ADMIN } from '../utils/roles';
import { saveReportData } from './ReportDataService.js';
import CsvGenerator from '../utils/generateR20Csv.jsx'

export function ModalReporte20({
  show,
  onHide,
  organizaciones,
  title,
  reportNo,
}) {
  const history = useHistory();

  const initialValues = {
    fecha_inicio: '',
    fecha_final: '',
    organizaciones: [],
    formulario: [],
    tipo_participantes: '',
    finalizado: 'finalizadas', // siempre finalizadas
    estado: 'no_canceladas', // siempre no canceladas
    pregunta: '',
    operador: '',
  };

  const validationSchema = object().shape({
    fecha_inicio: string()
      .nullable()
      .required('Fecha inicial es un campo requerido.'),
    fecha_final: string()
      .nullable()
      .required('Fecha final es un campo requerido.'),
    organizaciones: array()
      .of(object())
      .min(1, 'Organizaciones es un campo requerido.'),
  });


  const handleSubmit = (values, { setSubmitting }) => {
    const params = {};
    for (let key in values) {
      if (values[key]) {
        if (key === 'fecha_inicio' || key === 'fecha_final') {
          params[key] = convertDate(values[key]);
        } else {
          params[key] = values[key];
        }
      }
    }
    params.organizaciones = values.organizaciones
      .map((elem) => `${elem.value}`)
      .join(`,`);

    // handle questions and answers
    delete params['operador'];

    if (auth.user.role.nombre === ROLE_SYSTEM_ADMIN && params.financiador === '') {
      delete params.financiador;
    } else if (auth.user.role.nombre !== ROLE_SYSTEM_ADMIN) {
      params.financiador = auth.user.organizacion.financiador;
    }

    req.get(`${API_URL}reportes/${reportNo}`, { params }).then((response) => {
      setSubmitting(false);
      if (params.tipo_empleo === 'todos' && Array.isArray(response?.data?.data?.todos)) {
        CsvGenerator(response.data.data, response.data.type);
        onHide()
        return;
      }
      saveReportData(response.data.data)

      history.push(`/reportes/${reportNo}?type=${response.data.type}`);
    });
  };


  const auth = JSON.parse(localStorage.getItem('user'));
  return (
    <Modal
      size='lg'
      centered
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
        }) => (
          <Form>
            <Modal.Body>
              <SecuredComponent roles={[]}>
                <div className='form-group'>
                  <label className='form-label'>
                    * Fecha inicio
                  </label>
                  <DatePickerField name='fecha_inicio' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_inicio'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>
                    * Fecha fin
                  </label>
                  <DatePickerField name='fecha_final' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_final'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>* Organizaciones</label>
                  <MultiSelect
                    name='organizaciones'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('organizaciones', e);
                    }}
                    options={organizaciones.map((org) => ({
                      label: org.nombre,
                      value: org.id,
                    }))}
                  />
                  <ErrorMessage
                    component='p'
                    name='organizaciones'
                    className='small text-danger'
                  />
                </div>
                {reportNo !== 21 && (
                  <div className='form-group'>
                    <label className='form-label'>Financiador</label>
                    <select
                      name='financiador'
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value=''>Selecciona</option>
                      <option value='USAID'>USAID</option>
                      <option value='KOICA'>KOICA</option>
                      <option value='IREX'>IREX</option>
                    </select>
                    <ErrorMessage
                      className='small text-danger'
                      name='tipo_participantes'
                      component='p'
                    />
                  </div>
                )}
                <div className='form-group'>
                  <label className='form-label'>Tipo de empleo</label>
                  <MultiSelect
                    name='tipo_empleo'
                    // isMulti
                    // allowSelectAll
                    onChange={(e) => {
                      setFieldValue('tipo_empleo', e);
                    }}
                    options={[
                      {
                        label: 'Todos',
                        value: 'todos'
                      },
                      {
                        label: 'Nuevo Empleo',
                        value: 'nuevo_empleo'
                      },
                      {
                        label: 'Mejor Empleo',
                        value: 'mejor_empleo'
                      },
                      {
                        label: 'Nuevo Emprendimiento',
                        value: 'nuevo_emprendimiento'
                      },
                      {
                        label: 'Mejor Emprendimiento',
                        value: 'mejor_emprendimiento'
                      },
                      {
                        label: 'Indicador de Emprendimiento',
                        value: 'indicador_emprendimiento'
                      },
                    ]}
                  />
                  <ErrorMessage
                    component='p'
                    name='tipo_empleo'
                    className='small text-danger'
                  />
                </div>



              </SecuredComponent>
            </Modal.Body>

            <Modal.Footer>
              <button
                type='submit'
                className='buttonSubmit'
                disabled={
                  isSubmitting ||
                  !dirty ||
                  !isValid
                }
              >
                Buscar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
