import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class FormService {
  find(id) {
    return req.get(`${API_URL}formularios/${id}/show`);
  }
}

const service = new FormService();

export default service;
