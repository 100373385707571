export const initialAlert = {
  show: false,
  type: 'text-success',
  message: '',
  timeout: 5000,
};

export default function Alert({
  show = false,
  type = 'text-success',
  message = '',
}) {
  return (
    <div className={`alert ${type} ${show ? 'show' : ''}`}>
      <p>{message}</p>
    </div>
  );
}
