import React, { Component, createRef } from "react";
import { Button, Col, Row, Form, Modal, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { req } from "../utils/request.js";
import { withRouter } from "react-router-dom";
import FS from "../components/FieldStructure";
import Alerts from "../common/Alerts";
import $ from "jquery";
import { API_URL } from "../utils/config";
import { getTrueFalse, checkStatus } from "../components/functions";
import Audits from '../components/Audits';

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';



// const FilterColumnInputComponent = ({ name, setSearchParams, searchParams, tableRef, placeholder = '' }) => {
//   const inputRef = useRef(null);
//   console.log({ setSearchParams })
//   useEffect(() => {
//     // Debounce function to delay execution
//     const debounceTimeout = setTimeout(() => {
//       tableRef.current.onQueryChange({});
//     }, 1000);

//     // Cleanup: Clear the timeout if component unmounts or input value changes
//     return () => {
//       clearTimeout(debounceTimeout);
//     };
//   }, [searchParams[name]]);
//   useEffect(() => {
//     // Set focus when the component mounts or when state changes
//     inputRef.current.focus();
//   }, [searchParams]);
//   return (
//     // <TextField
//     //   ref={inputRef}
//     //   label={placeholder}
//     //   variant="outlined"
//     //   value={searchParams[name]}
//     //   onChange={(e) => {
//     //     setSearchParams({ ...searchParams, [name]: e.target.value })
//     //   }}
//     // />

//     <input
//       ref={inputRef}
//       style={{
//         padding: 5,
//         outline: 'none',
//         border: '1px solid #e5e5e5'
//       }}
//       value={searchParams[name]}
//       onChange={(e) => {
//         setSearchParams({ ...searchParams, [name]: e.target.value })
//       }}
//     />
//   )
// }

class Sections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      popupAddSection: false,
      editingData: [],
      alertShow: false,
      alertMessage: "",
      formfieldsAll: [],
      fieldresponses: [],
      dependentfieldsSelected: [],
      fieldresponsesForDependence: [],
      alertType: "",
      forms: [],
      dependence_type: "",
      auditType: "formularios/secciones",
      showAudit: false,
      searchParams: {
        estado: null,
        formulario: null,
        busqueda: null
      },
      typingTimeout: null
    };

    this.tableRef = createRef();
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);
    this.closeAudit = this.closeAudit.bind(this);

    this.dependence_formfield = React.createRef();
    this.dependence_operator = React.createRef();
    this.dependence_response = React.createRef();
    this.addDependenceSubmit = React.createRef();

    this.show_PopUpSections = this.show_PopUpSections.bind(this);
    this.hide_PopUpSections = this.hide_PopUpSections.bind(this);
    this.setSearchParams = this.setSearchParams.bind(this);
  }
  componentDidMount() {
    // this.setState({
    //   NombreFilterColumnInputComponent: function NombreFilterColumnInputComponent() {
    //     return <FilterColumnInputComponent name='busqueda' setState={this.setState} state={this.state} />
    //   }
    // })
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.encuesta .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.secciones").addClass("active");
    });
    req.get(`${API_URL}formularios`, { params: { estado: 'activos', paginacion: 999999 } }).then(response => {
      this.setState({
        forms: response.data.data ?? []
      });
    });
    setTimeout(() => {
      this.setState({ isLoaded: true, show: true });
    }, 500);

  }
  editRow(data) {
    this.show_PopUpSections(data);
  }

  showAudit(id) {
    this.setState({
      showAudit: id
    })
  }
  closeAudit() {
    this.setState({
      showAudit: null
    })
  }
  desactivateRow(data) {
    data.activo = false;
    req.post(`${API_URL}formularios/secciones/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'La Sección ha sido desactivada con éxito'
      });
    });
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${API_URL}formularios/secciones/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'La Sección ha sido activada con éxito'
      });
    });
  }
  toggleDependence(rowData) {
    const idx = this.state.dependentfieldsSelected.findIndex(data => data.id === rowData.id);
    if (idx < 0) {
      // do nothing
      return;
    }
    const elem = this.state.dependentfieldsSelected[idx];
    req.post(`${API_URL}formularios/secciones/dependencias/${elem.id}/toggle`).then(() => {
      elem.activo = !elem.activo;
      this.setState({
        dependentfieldsSelected: [...this.state.dependentfieldsSelected]
      });
    });
  }
  show_PopUpSections(data) {
    const isNew = data.id === undefined
    if (isNew) {
      this.setState({
        dependentfieldsSelected: []
      });
    } else {
      req.get(`${API_URL}formularios/secciones/dependencias`, { params: { seccion: data.id } }).then((response) => {
        const data = response.data.data || response.data;
        this.setState({
          dependentfieldsSelected: data.map(dep => ({ id: dep.id, form_field_id: dep.formulario_item_id_dependencia, operador: dep.operador, respuesta: dep.respuesta ?? dep.valor, valor: dep.valor, activo: dep.activo }))
        });
      });

      req.get(`${API_URL}formularios/items`, { params: { formulario: data.formulario_id, estado: 'activos', paginacion: 999999 } }).then((response) => {
        this.setState({
          formfieldsAll: response.data.data || response.data,
        });
      });
    }
    this.setState({ popupAddSection: true, editingData: data, isEditing: true });

  }
  hide_PopUpSections() {
    this.setState({ popupAddSection: false });
  }

  addDependence(values) {
    if (values.dependence_formfield === '') {
      return false;
    }
    if (values.dependence_operator === '') {
      return false;
    }
    if (values.dependence_response === '') {
      return false;
    }
    const respuesta = this.state.fieldresponses.find(resp => resp.id === values.dependence_response);
    const valor = this.renderAnswer({ pregunta_id: values.dependence_formfield, respuesta: respuesta, respuesta_id: values.dependence_response });
    this.setState({
      dependentfieldsSelected: [
        ...this.state.dependentfieldsSelected,
        {
          valor,
          formulario_respuesta: respuesta?.id,
          formulario_seccion: '', // to be set right before sending the post request to save
          form_field_id: values.dependence_formfield,
          operador: values.dependence_operator,
          activo: true
        },

      ],
    });
    values.dependence_response = "";
    values.dependence_formfield = "";
    values.dependence_operator = "";
  }

  selectForm(formik, formId) {
    formik.setFieldValue('formulario_id', formId);
    formik.setFieldValue("dependence_formfield", '');
    formik.setFieldValue("dependence_operator", '');
    formik.setFieldValue('dependence_response', '');

    req.get(`${API_URL}formularios/items`, { params: { formulario: formId, estado: 'activos', paginacion: 999999 } }).then((response) => {
      this.setState({
        formfieldsAll: (response.data.data ?? []).filter(pregunta => pregunta.seccion.id !== this.editingData?.id),
      });
    });
  }

  selectDependence_formfield(value, formik) {
    const pregunta = this.state.formfieldsAll.find(elem => elem.id === value);
    const tipo_pregunta = pregunta?.tipo_pregunta?.nombre?.toLowerCase();
    this.setState({
      dependence_type: tipo_pregunta
    });
    req.get(`${API_URL}formularios/respuestas`, { params: { formulario_item: pregunta?.id, paginacion: 999999 } }).then((response) => {
      this.setState({ fieldresponses: (response.data.data ?? []).filter(r => r.activo) });
    });
    formik.setFieldValue("dependence_formfield", value);
    formik.setFieldValue('dependence_response', '');
  }

  renderAnswer(rowData) {
    const { pregunta_id, respuesta, respuesta_id } = rowData;
    const pregunta = this.state.formfieldsAll.find(elem => elem.id === pregunta_id) ?? { tipo_pregunta: { nombre: '' } };
    const { tipo_pregunta } = pregunta;

    if (tipo_pregunta.nombre.toLowerCase() === 'selección múltiple' || tipo_pregunta.nombre.toLowerCase() === 'selección única') {
      return respuesta?.respuesta;
    }
    if (tipo_pregunta.nombre.toLowerCase() === 'si o no') {
      return respuesta_id === "1" ? "Si" : "No";
    }
    if (tipo_pregunta.nombre.toLowerCase() === "verdader o falso") {
      return respuesta_id === "1" ? "Verdadero" : "Falso";
    }
  }

  renderAnswerField(formik) {
    const { fieldresponses, dependence_type } = this.state;
    switch (dependence_type) {
      case "selección única":
      case "selección múltiple":
        return (
          <Field ref={this.answerDependence} name="dependence_response" as="select">
            <option value=""> Selecciona </option>
            {fieldresponses && fieldresponses.length > 0
              ?
              fieldresponses.map((respuesta, i) =>
                respuesta.formulario_item_id === formik.values.dependence_formfield ? (
                  <option key={respuesta.id} value={respuesta.id}>
                    {" " + respuesta.respuesta + " "}
                  </option>
                ) : null
              )
              : null}
          </Field>
        );
      case "si o no":
        return (
          <Field ref={this.answerDependence} name="dependence_response" as="select">
            <option value=""> Selecciona </option>
            <option value="1"> Si </option>
            <option value="0"> No </option>
          </Field>
        );
      case "verdadero o falso":
        return (
          <Field ref={this.answerDependence} name="dependence_response" as="select">
            <option value=""> Selecciona </option>
            <option value="1"> Verdadero </option>
            <option value="0"> Falso </option>
          </Field>
        );

      case "": // no question selected
        return null;
      default:
        return <Field ref={this.answerDependence} type="text" name="dependence_response" />;
    }
  }

  setSearchParams = (searchParams) => {
    this.setState({ searchParams })
  }

  render() {
    const {
      popupAddSection,
      editingData,
      alertMessage,
      alertShow,
      alertType,
      formfieldsAll,
      dependentfieldsSelected,
      forms
    } = this.state;
    const addSectionValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
    });

    return (
      <Container>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        )}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <span className="nav-link" onClick={this.show_PopUpSections}>
                Crear Sección
              </span>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              tableRef={this.tableRef}
              columns={[
                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  field: "id",
                },
                {
                  title: "Nombre",
                  field: "nombre",

                },
                {
                  title: "Formulario", field: "formulario_id",

                  render: (rowData) => (
                    // FIXME: it's very ineficient to do this search here
                    forms.find(form => form.id === rowData.formulario_id)?.nombre
                  ),


                },
                {
                  title: "Estatus", field: "activo",

                  render: rowData => (
                    checkStatus(rowData.activo)
                  )
                },
                { title: "Orden", field: "orden", filterComponent: () => <></> },

              ]}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, rowData) => this.editRow(rowData)
                },
                rowData => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: 'Desactivar',
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: rowData.activo === false
                }),
                rowData => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: 'Activar',
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.showAudit(rowData.id)
                })
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                selection: false,
                searchFieldAlignment: "right",
                actionsColumnIndex: -1,
                paging: true,
                emptyRowsWhenPaging: false,
                pageSize: 20,
                draggable: false,

              }}
              data={query => {
                return new Promise((resolve) => {
                  const params = { page: query.page + 1, paginacion: query.pageSize, ...this.state.searchParams };
                  // TODO implement search and filter

                  if (query.search) {
                    params.busqueda = query.search;
                  }
                  req.get(`${API_URL}formularios/secciones`, { params }).then(response => {
                    console.log('comprobating: ', response.data.data);
                    resolve({
                      data: response.data.data ?? [],
                      page: response.data.meta.current_page - 1,
                      totalCount: response.data.meta.total
                    });
                  });
                });
              }}
            />
          </div>
        </CSSTransition>
        <Modal size="xl"
          centered
          show={popupAddSection}
          onHide={this.hide_PopUpSections}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Sección</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                nombre: editingData.nombre || "",
                formulario_id: editingData.formulario_id || "",
                orden: editingData.orden || 0,
              }}
              validationSchema={addSectionValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                this.hide_PopUpSections();
                values.activo = 1;
                const isNew = editingData.id === undefined;
                if (isNew) {
                  req.post(`${API_URL}formularios/secciones/store`, {
                    formulario: values.formulario_id,
                    nombre: values.nombre,
                    orden: values.orden,
                  }).then((response) => {
                    dependentfieldsSelected.forEach((elem) => {
                      req.post(`${API_URL}formularios/secciones/dependencias/store`, {
                        formulario_seccion: response.data.data.id,
                        formulario_item_dependencia: elem.form_field_id,
                        formulario_respuesta: elem.respuesta,
                        operador: elem.operador,
                        valor: elem.valor,
                      });
                    });
                    this.setState({
                      alertShow: true,
                      alertMessage: "Sección Creada",
                      alertType: "success"
                    });
                    this.tableRef.current.onQueryChange({});
                  });
                } else {
                  req.post(`${API_URL}formularios/secciones/${editingData.id}/update`, {
                    formulario: values.formulario_id,
                    nombre: values.nombre,
                    orden: values.orden,
                  }).then((response) => {
                    dependentfieldsSelected.forEach(elem => {
                      if (elem.id !== undefined) {
                        // edit existing dependency
                        req.post(`${API_URL}formularios/secciones/dependencias/${elem.id}/update`, {
                          formulario_seccion: response.data.data.id,
                          formulario_item_dependencia: elem.form_field_id,
                          formulario_respuesta: elem.formulario_respuesta,
                          operador: elem.operador,
                          valor: elem.valor,
                        });
                      } else {
                        // save new dependency
                        req.post(`${API_URL}formularios/secciones/dependencias/store`, {
                          formulario_seccion: response.data.data.id,
                          formulario_item_dependencia: elem.form_field_id,
                          formulario_respuesta: elem.formulario_respuesta,
                          operador: elem.operador,
                          valor: elem.valor,
                        });
                      }
                    });
                    this.setState({
                      alertShow: true,
                      alertMessage: "Sección Actualizada",
                      alertType: "success"
                    });
                    this.tableRef.current.onQueryChange({});
                  });
                }
                this.hide_PopUpSections();
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg="6">
                      <div className="form-group">
                        <label>Nombre</label>
                        <Field type="text" name="nombre" />
                        <ErrorMessage
                          className="errorField"
                          name="name"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="form-group">
                        <label>Formulario</label>
                        <Field name="formulario_id" as="select" onChange={(e) => this.selectForm(formik, e.target.value)}>
                          <option value=""> Selecciona </option>
                          {forms.map((p) => (
                            <option value={p.id}> {p.nombre}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          className="errorField"
                          name="formulario_id"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="form-group">
                        <label>Orden</label>
                        <Field type="number" name="orden" />
                        <ErrorMessage
                          className="errorField"
                          name="orden"
                          component="p"
                        />
                      </div>
                    </Col>

                  </Row>
                  <br />
                  <Row>
                    <Col lg={12}>
                      <strong>Preguntas Dependientes</strong>
                      <div className="subBox">
                        <div className="form-group">

                          <Row>
                            <p style={{ marginBottom: "0" }}>
                              Esta sección se mostrará cuando:
                            </p>
                            <FS
                              label="Pregunta"
                              name="dependence_formfield"
                              errors={formik.errors}
                              col={[3]}
                            >
                              <Field name="dependence_formfield" onChange={(e) => this.selectDependence_formfield(e.target.value, formik)}
                                ref={this.question} as="select">
                                <option value=""> Selecciona </option>
                                {formfieldsAll
                                  .map((pregunta) => (
                                    <option key={pregunta.id} value={pregunta.id}>
                                      {` ${pregunta.pregunta} `}
                                    </option>
                                  ))}
                              </Field>
                            </FS>
                            <FS
                              label="Operador"
                              name="dependence_operator"
                              errors={formik.errors}
                              col={[3]}
                            >
                              <Field ref={this.operator} name="dependence_operator" as="select">
                                <option value=""> Selecciona </option>
                                <option value="0"> Sea Igual a</option>
                                <option value="3"> Sea Diferente a </option>
                              </Field>
                            </FS>
                            <FS
                              label="Respuesta"
                              name="dependence_response"
                              errors={formik.errors}
                              col={[3]}
                            >
                              {this.renderAnswerField(formik)}
                            </FS>
                            <Col lg={3}>
                              <br />
                              <Button
                                variant="primary "
                                className="secondaryButton"
                                disabled={!formik.values.dependence_formfield || !formik.values.dependence_operator || !formik.values.dependence_response}
                                onClick={() => this.addDependence(formik.values)}
                              >
                                Añadir
                              </Button>
                            </Col>
                          </Row>
                          <hr />
                          <MaterialTable
                            title=""
                            columns={[
                              { title: "Operador", field: "operador", hidden: true },
                              {
                                title: "Pregunta", field: "form_field_id",
                                render: (rowData) => (formfieldsAll.find(elem => elem.id === rowData.form_field_id)?.pregunta),
                                customFilterAndSearch: (term, rowData) => (formfieldsAll.find(elem => elem.id === rowData.form_field_id)?.pregunta?.toLowerCase()).indexOf(term.toLowerCase()) >= 0
                              },
                              {
                                title: "Operador", field: "operador",
                                render: (rowData) => (
                                  returnOperator(rowData.operador)
                                )
                              },
                              {
                                title: "Respuesta", field: "valor",
                                customFilterAndSearch: (term, rowData) => (rowData.valor.toLowerCase()).indexOf(term.toLowerCase()) >= 0
                              },
                              {
                                title: "Estatus",
                                field: "activo",
                                render: (rowData) =>
                                  getTrueFalse(
                                    rowData.activo,
                                    "Activo",
                                    "Inactivo"
                                  ),
                              }
                            ]}
                            options={{
                              search: false,
                              selection: false,
                              searchFieldAlignment: "right",
                              paging: false,
                              pageSize: 20,
                              draggable: false,
                            }}
                            data={dependentfieldsSelected}
                            actions={[
                              rowData => ({
                                icon: DoNotDisturbOnOutlinedIcon,
                                tooltip: 'Desactivar',
                                onClick: (event, rowData) => this.toggleDependence(rowData),
                                disabled: rowData.activo === false
                              }),
                              rowData => ({
                                icon: CheckCircleOutlinedIcon,
                                tooltip: 'Activar',
                                onClick: (event, rowData) => this.toggleDependence(rowData),
                                disabled: rowData.activo === true
                              })
                            ]}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          className="buttonSubmit"
                          disabled={formik.isSubmitting}
                          variant="primary "
                        >
                          {formik.isSubmitting ? "Guardando..." : "Guardar"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

function returnOperator(value) {
  switch (value) {
    case '0':
      return "Sea Igual a";
    case '1':
      return "Sea Menor o igual a";
    case '2':
      return "Sea Mayor o igual a";
    case '3':
      return "Sea Diferente a";
    default:
      return "Operador inválido";
  }
}
export default withRouter(Sections);