import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { req } from '../utils/request';
import { API_URL } from '../utils/config';

export default function Participacion({ beneficiario }) {
  const beneficiario_id = beneficiario?.id;

  const [isLoading, setLoading] = useState(true);
  const [programas, setProgramas] = useState([]);

  useEffect(() => {
    const params = {
      participante: beneficiario_id,
    };
    req
      .get(`${API_URL}participantes/${beneficiario_id}/programas`, { params })
      .then((response) => {
        setProgramas(response.data.data);
        setLoading(false);
      });
  }, [beneficiario_id]);

  return (
    <SpinnerPlaceholder isLoading={isLoading}>
      <MaterialTable
        title=''
        columns={[{ title: 'Nombre del Programa', field: 'nombre' }]}
        localization={{
          toolbar: {
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
          },
          actions: "Acciones",
          body: {
            emptyDataSourceMessage: 'No hay registros que mostrar',
          },
        }}
        options={{
          search: false,
          selection: false,
          searchFieldAlignment: 'right',
          actionsColumnIndex: -1,
          paging: false,
          pageSize: 20,
          draggable: false,
        }}
        data={programas}
      />
    </SpinnerPlaceholder>
  );
}
