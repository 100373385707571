import { DoNotDisturbOnOutlined, Login, Logout } from '@mui/icons-material';
import MaterialTable, { MTableToolbar } from 'material-table';
import { changeDateFormat } from '../components/functions';
import HistoryIcon from '@mui/icons-material/History';
import Audits from '../components/Audits';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import React, { useMemo } from 'react';

export function ParticipantsTable(props) {
  const { asistencias, setAsistencias, onRemove, onReturn, onConclude, title } = props;

  function setProperty(id, newItem) {
    if (('porcentaje' in newItem) && (newItem.porcentaje < 0 || newItem.porcentaje > 100)) {
      alert('Debes colocar un valor entre 0 y 100')
      return
    }

    setAsistencias(asistenciaData => {
      const newAsistencia = asistenciaData.map(asistencia => {
        if (asistencia.id === id) {
          const merged = { ...asistencia, ...newItem }
          updateAsistenciaActividades(merged);
          return merged;
        }
        return asistencia;
      })

      return newAsistencia;
    });

  }

  async function updateAsistenciaActividades(payload) {
    const { participante, actividad, observacion, motivo, porcentaje, id } = payload;

    const formData = new FormData();
    formData.append('participante', participante.id);
    formData.append('actividad', actividad.id);
    observacion && formData.append('observacion', observacion === 'Selecciona' ? '' : observacion);
    motivo && formData.append('motivo', motivo === 'Selecciona' ? '' : motivo);
    porcentaje && formData.append('porcentaje', porcentaje === 'Selecciona' ? '' : porcentaje);
    req.post(API_URL + `asistencia/actividades/${id}/update`, formData);

  }

  const getLookup = (items) => items.reduce((acc, current) => ({ ...acc, [current]: current }), {});

  const memoAsistencias = useMemo(() => asistencias.map(
    asistencia => {
      const voluntariamente = 'Voluntariamente dejó de asistir a los servicios o programas';
      const deserto = 'Desertó';
      return {
        ...asistencia,
        porcentaje: asistencia.porcentaje || 'Selecciona',
        observacion: asistencia.observacion || 'Selecciona',
        motivo: [voluntariamente, deserto].includes(asistencia.observacion) ? (asistencia.motivo || 'Selecciona') : null,
      }
    }), [asistencias]);


  return (
    <>
      {!!props.auditModal && (
        <Audits
          close={() => props.setAuditModal(null)}
          show={!!props.auditModal}
          tipo={'asistencia/actividades'}
          id={props.auditModal}
        />
      )}
      <MaterialTable
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              setProperty(rowData.id, { [columnDef.field]: newValue })
              resolve()
            });
          }
        }}
        title={title}
        columns={[
          {
            title: 'UUID',
            field: 'participante.codigo',
            editable: 'never',

          },
          {
            title: 'Nombres',
            field: 'participante.nombres',
            editable: 'never',
          },
          {
            title: 'Apellidos',
            field: 'participante.apellidos',
            editable: 'never',
          },
          {
            title: 'Sexo',
            field: 'participante.genero',
            editable: 'never',
            render: (rowData) =>
              rowData.participante.genero.charAt(0).toUpperCase() +
              rowData.participante.genero.slice(1),
          },
          {
            title: 'Tipo de Participante',
            field: 'participante.tipo.nombre',
            editable: 'never',
          },
          {
            title: 'Fecha de nacimiento',
            editable: 'never',
            render: (rowData) =>
              changeDateFormat(rowData.participante.fecha_nacimiento),
          },
          {
            title: 'Porcentaje',
            field: 'porcentaje',
            type: 'numeric',
          },
          {
            title: 'Observación',
            field: 'observacion',
            lookup: getLookup(['Selecciona', 'Completó asistencia a servicios o programas en que participó',
              'Falleció', 'No completó el porcentaje del programa', 'Voluntariamente dejó de asistir a los servicios o programas',
              'Desertó']),
          },
          {
            title: 'Motivo',
            field: 'motivo',
            lookup: getLookup(['Selecciona', 'Por falta de motivación o interés',
              'Se mudó de la comunidad', 'Por estudios', 'Distancia/Lejanía',
              'Cuidar a un familiar', 'No localizado/a', 'Por falta de recursos económicos']),

          },
        ]}
        localization={{
          header: {
            actions: "Acciones",
          },
          toolbar: {
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
          },
          pagination: {
            previousTooltip: 'Página anterior',
            nextTooltip: 'Página siguiente',
            firstTooltip: 'Primera página',
            lastTooltip: 'Última página',
          },
          body: {
            emptyDataSourceMessage: 'No hay registros que mostrar',
          },
        }}
        options={{
          search: true,
          selection: false,
          searchFieldAlignment: 'right',
          searchAutoFocus: false,
          actionsColumnIndex: -1,
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          draggable: false,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div>
                <p className='conteo'>{`Cantidad: ${asistencias.length}`}</p>
              </div>
            </div>
          ),
        }}
        data={memoAsistencias}
        // data={(query) => {
        //   return new Promise((resolve) => {
        //     const params = {
        //       page: query.page + 1,
        //       paginacion: query.pageSize,
        //     };
        //     if (query.search) {
        //       params.busqueda = query.search;
        //     }
        //     // if (query.orderBy) {
        //     //   const sort = `${query.orderBy.field},${query.orderDirection}`;
        //     //   params.ordenar = sort;
        //     // }
        //     // if (auth.user.role.nombre != ROLE_SYSTEM_ADMIN) {
        //     //   params.organizacion = auth.user.organizacion.id;
        //     // }
        //     // TODO implement filters
        //     req
        //       .get(API_URL + 'asistencia/actividades', { params })
        //       .then((response) => {
        //         resolve({
        //           data: response.data.data,
        //           page: response.data.meta.current_page - 1,
        //           totalCount: response.data.meta.total,
        //         });
        //       });
        //   });
        // }}
        actions={[
          (rowData) => ({
            icon: DoNotDisturbOnOutlined,
            tooltip: 'Remover',
            onClick: (event, rowData) => onRemove(rowData),
            hidden: rowData.finalizado,
          }),
          (rowData) => ({
            icon: Login,
            tooltip: 'Retornar',
            onClick: (event, rowData) => onReturn(rowData),
            hidden: !rowData.finalizado,
          }),
          (rowData) => ({
            icon: Logout,
            tooltip: 'Finalizó',
            onClick: (event, rowData) => onConclude(rowData),
            hidden: rowData.finalizado,
          }),
          rowData => ({
            icon: HistoryIcon,
            tooltip: 'Auditoria',
            onClick: (event, rowData) => props.setAuditModal(rowData.id)
          })
        ]}
      />
    </>
  );
}

export default ParticipantsTable;
