import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import HeaderWithoutRouter from './common/Header';
import Footer from './common/Footer';
import Login from './login/Login';
import ForgetPassword from './login/Forget_Password';
import Expired from './login/Expired';
import Register from './register/Register';
import BeneficiariesList from './beneficiaries/BeneficiariesList.js';
import Organizations from './organizations/Organizations';
import ProgramsTypesList from './programs/ProgramsTypesList';
import ProgramsList from './programs/ProgramsList';
import ActivitiesTypesList from './activities/ActivitiesTypesList';

import UsersList from './users/UsersList';
import Provinces from './pmsb/Provinces';
import Municipalities from './pmsb/Municipalities';
import Sectors from './pmsb/Sectors';
import Barrios from './pmsb/Barrios';

import PollForms from './poll/PollFormsList';
import Sections from './poll/Sections';
import QuestionTypes from './poll/QuestionTypes';
import Fields from './poll/Fields';
import FieldCreateEdit from './poll/FieldCreateEdit';
import FormFieldCreateEdit from './poll/FormFieldCreateEdit';
import Configurations from './configurations/Configurations';
import Reports from './reports/Reports';

import TableReporte1 from './reports/TableReporte1.jsx';
import Profile from './profile/Profile.jsx';

import PollView from './poll/PollView';
import FormTypeList from './poll/FormTypeList';
import SecuredRoute from './components/SecuredRoute';
import Unauthorized from './common/Unauthorized';
import { ROLE_COORDINADOR, ROLE_DIGITADOR, ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA } from './utils/roles';
import NewPollView from './poll/NewPollView.jsx';
import NewActivitiesList from './activities/NewActivitiesList.jsx';
import UpdateActivity from './activities/UpdateActivity.jsx';
import NewAttendances from './activities/NewAttendances.jsx';
import BeneficiaryDetail from './beneficiaries/BeneficiaryDetail';
import SectorsUpdate from './pmsb/SectorsUpdate';
import MunicipiosUpdate from './pmsb/MunicipiosUpdate';
import ProvinciasUpdate from './pmsb/ProvinciasUpdate';
import TableReporte3 from './reports/TableReporte3';
import TableReporte5 from './reports/TableReporte5';
import TableReporte7 from './reports/TableReporte7';
import TableReporte9 from './reports/TableReporte9';
import TableReporte17 from './reports/TableReporte17';
import TableReporte6 from './reports/TableReporte6';
import TableReporte10 from './reports/TableReporte10.jsx';
import TableReporte4 from './reports/TableReporte4.jsx';
import ChangePassword from './profile/ChangePassword.jsx';
import EditFieldOrderIndex from './poll/EditFieldOrderIndex';
import TableReporte12 from './reports/TableReporte12';
import TableReporte19 from './reports/TableReporte19';
import TableReporte4_1 from './reports/TableReporte4_1.jsx';
import TableReporte20 from './reports/TableReporte20.jsx';
import TableReporte21 from './reports/TableReporte21.jsx';
import PollFormsCreateEdit from './poll/PollFormsCreateEdit';
import AttendanceList from './activities/AttendanceList';
import CreateEditQuestionType from './poll/CreateEditQuestionType.jsx';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provider: {},
            ready: true
        };
    }
    render() {
        const { ready } = this.state;
        if (ready) {
            const Header = withRouter(HeaderWithoutRouter);
            return (
                <Router>
                    <div>
                        <Switch>
                            <Route path="/registro">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_DIGITADOR, ROLE_COORDINADOR]}>
                                    <Header title="Formulario de Registro" />
                                    <Register {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/login">
                                <Header title="" />
                                <Login />
                                <Footer />
                            </Route>
                            <Route path="/olvido_contrasena">
                                <Header title="" />
                                <ForgetPassword />
                                <Footer />
                            </Route>
                            <Route path="/expired">
                                <Header title="" />
                                <Expired />
                                <Footer />
                            </Route>

                            <Route path="/beneficiarios/detalle/:beneficiario_id">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_DIGITADOR, ROLE_COORDINADOR]}>
                                    <Header back="/beneficiarios" title="Detalle Beneficiario" />
                                    <BeneficiaryDetail  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/beneficiarios/:beneficiario_id">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_DIGITADOR, ROLE_COORDINADOR]}>
                                    <Header back="/beneficiarios/" title="Edición de Beneficiarios" />
                                    <Register  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/beneficiarios">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_DIGITADOR, ROLE_COORDINADOR]}>
                                    <Header title="Listado de Beneficiarios" />
                                    <BeneficiariesList  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/organizaciones">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Organizaciones" />
                                    <Organizations />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/programas">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Programas" />
                                    <ProgramsList />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/tipos-programas">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Tipos de Programas" />
                                    <ProgramsTypesList />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/tipos-actividades">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Tipos de Actividades" />
                                    <ActivitiesTypesList />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/actividades">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_DIGITADOR, ROLE_COORDINADOR]}>
                                    <Header title="Actividades" />
                                    <NewActivitiesList {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/actividades/nueva" >
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back="/actividades" title="Crear Actividad" />
                                    <UpdateActivity {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/actividades/:activity_id" >
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back="/actividades" title="Editar Actividad" />
                                    <UpdateActivity {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/asistencia/:activity_id" >
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <AttendanceList {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/actividades/:activity_id/asistencias" >
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <NewAttendances   {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/usuarios">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header title="Usuarios" />
                                    <UsersList />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/provincias" exact>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header title="Provincias" />
                                    <Provinces />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/provincias/nueva">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header back='' title="Provincias" />
                                    <ProvinciasUpdate />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/provincias/:id/editar">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header back='' title="Provincias" />
                                    <ProvinciasUpdate />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/municipios" exact>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header title="Municipios" />
                                    <Municipalities />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/municipios/nuevo">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header back='' title="Crear Municipio" />
                                    <MunicipiosUpdate />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/municipios/:id/editar">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header back='' title="Editar Municipio" />
                                    <MunicipiosUpdate />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/sectores" exact>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header title="Sectores" />
                                    <Sectors />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/sectores/nuevo">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header back='' title="Crear sector" />
                                    <SectorsUpdate />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/sectores/:id/editar">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header back='' title='Editar sector' />
                                    <SectorsUpdate {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/barrios">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
                                    <Header title="Barrios" />
                                    <Barrios />
                                    <Footer />
                                </SecuredRoute>
                            </Route>

                            <Route exact path="/formularios">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Formularios" />
                                    <PollForms  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/formularios/crear">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header back="/formularios" title="Formularios" />
                                    <PollFormsCreateEdit  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/formulario/edit-order-index-field/:formularyId" >
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <EditFieldOrderIndex   {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/formulario/pregunta">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header back="/formularios" title="Creación de Pregunta" />
                                    <FormFieldCreateEdit  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/formulario/pregunta/:questionId">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header back="/formularios" title="Creación de Pregunta" />
                                    <FormFieldCreateEdit  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/formulario/:formularyId">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header back="/formularios" title="Edición de Formulario" />
                                    <PollFormsCreateEdit  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/tipo-formulario">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Tipo de Formulario" />
                                    <FormTypeList />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/secciones">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Secciones" />
                                    <Sections />
                                    <Footer />
                                </SecuredRoute>
                            </Route>

                            <Route exact path="/tipo-pregunta">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Tipo de Pregunta" />
                                    <QuestionTypes />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/tipo-pregunta/crear">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Crear Tipo de Pregunta" />
                                    <CreateEditQuestionType />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/tipo-pregunta/:id">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Editar Tipo de Pregunta" />
                                    <CreateEditQuestionType />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path='/unauthorized'>
                                <Header title='No autorizado' />
                                <Unauthorized />
                                <Footer />
                            </Route>
                            <Route exact path="/preguntas/crear">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Creación de Pregunta" />
                                    <FieldCreateEdit  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/preguntas">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Preguntas" />
                                    <Fields {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>

                            <Route path="/preguntas/:fieldId" >
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header back="/preguntas/" title="Edición de Pregunta" />
                                    <FieldCreateEdit   {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/formularios/:formId/:participantId/:editForm" >
                                <Header back="/beneficiarios/" title=" " />
                                <PollView {...this.props} />
                                <Footer />
                            </Route>
                            <Route path="/formularios/:formId/:participantId" >
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_DIGITADOR, ROLE_COORDINADOR]}>
                                    <Header back="/beneficiarios/" title=" " />
                                    <NewPollView {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/configuraciones">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN]}>
                                    <Header title="Configuraciones" />
                                    <Configurations {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route exact path="/reportes">
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_DIGITADOR, ROLE_COORDINADOR]}>
                                    <Header title="Reportes" />
                                    <Reports  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/1'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes con Registro y Encuesta de Inscripción (Numérico)' />
                                    <TableReporte1 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/2'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes sin Encuesta de Inscripción (Numérico)' />
                                    <TableReporte1 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/3'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes sin Encuesta de Inscripción (Desglosado)' />
                                    <TableReporte3 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/4'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Cantidad de Formularios de Registro por Tipo de Participante (Numérico)' />
                                    <TableReporte4 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/4.1'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Cantidad de Formularios por Tipo de Participante (Desglosado)' />
                                    <TableReporte4_1 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/5'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Resultados de Encuestas (Numérico)' />
                                    <TableReporte5 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/6'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Resultados de Encuestas (Desglosado)' />
                                    <TableReporte6 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/7'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Servicios por Programa' />
                                    <TableReporte7 numeroReporte='7' {...this.props} showActivities={true} origen={true} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/8'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes Únicos por Programas (Numérico)' />
                                    <TableReporte7 numeroReporte='8' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/9'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes Únicos por Programas (Desglosado)' />
                                    <TableReporte9 numeroReporte='9' {...this.props} origen={true} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/10'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes Únicos Recibiendo Servicios (Numérico)' />
                                    <TableReporte10 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/11'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes Únicos Recibiendo Servicios (Desglosado)' />
                                    <TableReporte9 numeroReporte='11' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/12'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Listado de Actividades' />
                                    <TableReporte12 numeroReporte='12' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/13'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes con o sin Servicios (Desglosado)' />
                                    <TableReporte9 numeroReporte='13' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/14'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Capacitados en curso vocacional y sin empleos y/o emprendimiento (Desglosado)' />
                                    <TableReporte9 numeroReporte='14' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/15'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Servidos sin Encuesta de Inscripción a Participantes (Desglosado)' />
                                    <TableReporte9 numeroReporte='15' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/16'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Estatus de Participantes (Desglosado)' />
                                    <TableReporte9 numeroReporte='16' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/17'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Total de Beneficiarios de Salida (Numérico)' />
                                    <TableReporte17 {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/18'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Total de Beneficiarios de Salida (Desglosado)' />
                                    <TableReporte9 numeroReporte='18' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/19'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Participantes Recibiendo Servicios (Desglosado)' />
                                    <TableReporte19 numeroReporte='19' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path='/reportes/20'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Colocación Laboral Basado en Encuesta Laboral (Desglosado)' />
                                    <TableReporte20 numeroReporte='20' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>

                            <Route path='/reportes/21'>
                                <SecuredRoute roles={[ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                    <Header back='/reportes' title='Colocación Laboral Basado en Encuesta Laboral (Desglosado)' />
                                    <TableReporte21 numeroReporte='21' {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/perfil">
                                <SecuredRoute>
                                    <Header title="Perfil" />
                                    <Profile  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/cambiar-contraseña">
                                <SecuredRoute>
                                    <Header title="Cambiar contraseña" back='/perfil' />
                                    <ChangePassword  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/cambiar-contraseña">
                                <SecuredRoute>
                                    <Header title="Cambiar contraseña" back='/perfil' />
                                    <ChangePassword  {...this.props} />
                                    <Footer />
                                </SecuredRoute>
                            </Route>
                            <Route path="/">
                                <Header />
                                <Login  {...this.props} />
                                <Footer />
                            </Route>
                        </Switch>
                    </div>
                </Router>
            );
        } else {
            return <div></div>
        }
    }
}
// function searchParams(element) {
//     let searchParams = new URLSearchParams(window.location.search);
//     return searchParams.get(element) || ''
// }

// function setCookie(cname, cvalue, exdays) {
//     var d = new Date();
//     d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
//     var expires = "expires=" + d.toUTCString();
//     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
// }
export default App;
