import React from 'react';
import { useField, useFormikContext } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

function range(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}
export const DatePickerField = ({ ...props }) => {
  // const [startDate, setStartDate] = useState(new Date());
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const years = range(1960, 2070);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ];
  return (
    <DatePicker
    locale="es"

      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <a onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </a>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <a onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </a>
        </div>
      )}
      selected={field.value !== undefined ? ((field.value.replace(/-/g, '/') && new Date(field.value + " 00:00")) || null ) : null }
      dateFormat="dd/MM/yyyy"
      onChange={val => {
        setFieldValue(field.name, convertDate(val));
      }}
    />
  );
};
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
function convertDate(inputFormat) {
  if(isValidDate(inputFormat)){
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getFullYear()), pad(d.getMonth()+1), ("0" + d.getDate()).slice(-2)].join('-')
  }else{
    return inputFormat;
  }
}
export default DatePickerField;
