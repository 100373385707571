import React, { Component } from "react";
import { Button, Col, Row, Form, Container } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { withRouter } from "react-router-dom";
import { Formik, Field } from "formik";
import FS from "../components/FieldStructure";
import AuthService from "../services/auth.service";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error: false,
      message: "",
      sended: false
    };
  }
  componentDidMount() {
    
    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);
  }

  render() {
    return (
      <Container>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
            <Row>
                      <Col lg="3">
                      </Col>
                      <Col lg="6">
            <div className="mainBox" style={{ padding: "4.5%" }}>
            <Formik
              initialValues={{
                title: "",
                dependent: "",
                }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                values.is_enabled = 1;
                setSubmitting(true);
                AuthService.forgetPassword(values.email, values).then(
                    (data) => {
                        this.setState({sended: true});
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                      this.setState({
                        error: true,
                        message: resMessage
                      });
                    }
                  );
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                      <Col lg="12">
                            <h3 className="title">¿Olvidaste tu contraseña?</h3> <br/> 
                            <FS
                            label="Correo"
                            name="email"
                            errors={formik.errors}
                            col={[12]}
                            >
                            <Field  type="email" name="email" />
                            </FS>
                            <Button
                    type="submit"
                    className="buttonSubmit"
                    variant="primary "
                  >Recuperar</Button>
                  {this.state.sended ? 
                    <p style={{textAlign: "center", diplay: "block", marginTop: "20px", color: "#478ddd"}}>Email restablecimiento de contraseña enviado</p>
                : null}
                    </Col>
                   </Row>
                </Form>
              )}
            </Formik>
            </div>
                </Col>
                <Col lg="3">
                      </Col>
                      </Row>
        </CSSTransition>
      </Container>
    );
  }
}
export default withRouter(ForgetPassword);