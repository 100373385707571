import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { clearReportData, loadReportData } from './ReportDataService.js';
import unorm from 'unorm';
import { getCustomReportFilterFunction } from '../utils/getCustomReportFilterFunction'

function removeAccents(str) {
  return unorm.nfkd(str).replace(/[\u0300-\u036f]/g, '');
}
const customFilter = (term, rowData) => {
  term = removeAccents(term).toLowerCase();
  const rowDataString = Object.values(rowData).join(' ').toLowerCase();
  return rowDataString.includes(term);
};


export function TableReporte12({ numeroReporte, origen }) {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const tData = loadReportData();
    setTableData(tData ?? []);
    return () => clearReportData();
  }, []);

  const downloadCsv = () => {
    if (tableData.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'reporte',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of tableData) {
      const row = { ...elem };
      delete row.tableData; // remove this property added by MaterialTable
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };

  return (
    <div className='container'>
      <div className='mainBox'>
        <div className='d-flex justify-content-end'>
          {tableData.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
        <MaterialTable
          customFilterAndSearch={customFilter}
          title=''
          columns={[
            {
              title: 'Organización', field: 'organizacion',
              customFilterAndSearch: getCustomReportFilterFunction('organizacion')
            },
            { title: 'Programa', field: 'programa' },
            {
              title: 'Id Actividad', field: 'id_actividad',
              customFilterAndSearch: getCustomReportFilterFunction('codigo')
            },
            {
              title: 'Actividad', field: 'actividad',
              customFilterAndSearch: getCustomReportFilterFunction('nombres')
            },
            { title: 'Fecha de Inicio', field: 'fecha_inicio' },
            {
              title: 'Fecha de Cierre',
              field: 'fecha_cierre',
              cellStyle: { textTransform: 'capitalize' },
            },
            {
              title: 'Masculino',
              field: 'masculino',
              customFilterAndSearch: getCustomReportFilterFunction('fecha_nacimiento'),
            },
            { title: 'Femenino', field: 'femenino', customFilterAndSearch: getCustomReportFilterFunction('femenino'), },
            {
              title: 'Total', field: 'total',
              customFilterAndSearch: getCustomReportFilterFunction('total'),
            },
          ]}
          options={{
            search: true,
            draggable: false,
            emptyRowsWhenPaging: false,
            pageSize: 20,
          }}
          data={tableData}
        />
      </div>
    </div>
  );
}

export default TableReporte12;
