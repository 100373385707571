import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { array, object, string } from 'yup';
import DatePickerField from '../common/DatePicker';
import MultiSelect from '../components/MultiSelect';
import SecuredComponent from '../components/SecuredComponent';
import AuthService from '../services/auth.service';
import { API_URL } from '../utils/config';
import { download } from '../utils/file';
import { req } from '../utils/request';
import { ROLE_SYSTEM_ADMIN } from '../utils/roles';

export function ModalExportableEncuestas({ show, onHide }) {
  const [organizaciones, setOrganizaciones] = useState([]);
  const [formularios, setFormularios] = useState([]);

  useEffect(() => {
    req.get(`${API_URL}selectores/organizaciones`).then((response) => {
      setOrganizaciones(response?.data?.data ?? []);
    });

    req.get(`${API_URL}selectores/formularios`).then((response) => {
      setFormularios(response?.data?.data ?? []);
    });
  }, []);

  const validationSchema = object().shape({
    fecha_inicio: string()
      .nullable()
      .required('Fecha inicial es un campo requerido.'),
    fecha_final: string()
      .nullable()
      .required('Fecha final es un campo requerido.'),
    organizaciones: array()
      .of(object())
      .min(1, 'Organizaciones es un campo requerido'),
    tipo_participantes: string().nullable(),
    formulario1: string()
      .nullable()
      .required('Formulario 1 es un campo requerido.'),
    formulario2: string()
      .nullable()
      .required('Formulario 2 es un campo requerido.'),
  });

  const auth = AuthService.getCurrentUser();
  const isAdmin = auth.user.role.nombre === ROLE_SYSTEM_ADMIN;
  const initialValues = {
    fecha_inicio: '',
    fecha_final: '',
    organizaciones: isAdmin ? [] : [auth.user.organizacion.id],
    tipo_participantes: '1,2',
    formulario1: '',
    formulario2: '',
  };

  return (
    <Modal size='lg' centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Exportación de Encuesta</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(
          {
            fecha_inicio,
            fecha_final,
            organizaciones,
            tipo_participantes,
            formulario1,
            formulario2,
          },
          { setSubmitting }
        ) => {
          const params = {
            fecha_inicio,
            fecha_final,
            organizaciones: organizaciones
              .map((org) => `${org.value}`)
              .join(','),
            tipo_participantes,
            formulario1,
            formulario2,
            finalizado: 'finalizadas'
          };
          req
            .get(`${API_URL}reportes/exportar/encuestas_multiples`, {
              params,
              responseType: 'blob',
            })
            .then((response) => {
              const url = URL.createObjectURL(response.data);
              download(url, 'Encuestas.xlsx');
              URL.revokeObjectURL(url);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <Modal.Body>
              <SecuredComponent roles={[ROLE_SYSTEM_ADMIN]}>
                <div className='form-group'>
                  <label className='form-label'>* Organizaciones</label>
                  <MultiSelect
                    name='organizaciones'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('organizaciones', e);
                    }}
                    options={organizaciones.map((org) => ({
                      label: org.nombre,
                      value: org.id,
                    }))}
                  />
                  <ErrorMessage
                    component='p'
                    name='organizaciones'
                    className='small text-danger'
                  />
                </div>
              </SecuredComponent>
              <div className='form-group'>
                <label className='form-label'>* Fecha inicio registro</label>
                <DatePickerField name='fecha_inicio' />
                <ErrorMessage
                  className='small text-danger'
                  name='fecha_inicio'
                  component='p'
                />
              </div>
              <div className='form-group'>
                <label className='form-label'>* Fecha fin registro</label>
                <DatePickerField name='fecha_final' />
                <ErrorMessage
                  className='small text-danger'
                  name='fecha_final'
                  component='p'
                />
              </div>
              <div className='form-group'>
                <label className='form-label'>Tipo de beneficiario</label>
                <select
                  name='tipo_participantes'
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value='1,2'>Ambos</option>
                  <option value='1'>Directo</option>
                  <option value='2'>Indirecto</option>
                </select>
                <ErrorMessage
                  className='small text-danger'
                  name='tipo_participantes'
                  component='p'
                />
              </div>
              <div className='form-group'>
                <label className='form-label'>* Formulario 1</label>
                <select
                  name='formulario1'
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value=''>Selecciona...</option>
                  {formularios.map((formulario) => (
                    <option key={`form_${formulario.id}`} value={formulario.id}>
                      {formulario.nombre}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  component='p'
                  name='formulario1'
                  className='small text-danger'
                />
              </div>
              <div className='form-group'>
                <label className='form-label'>* Formulario 2</label>
                <select
                  name='formulario2'
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value=''>Selecciona...</option>
                  {formularios.map((formulario) => (
                    <option key={`form_${formulario.id}`} value={formulario.id}>
                      {formulario.nombre}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  component='p'
                  name='formulario2'
                  className='small text-danger'
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type='submit' disabled={isSubmitting}>
                {isSubmitting ? 'Descargando...' : 'Descargar'}
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalExportableEncuestas;
