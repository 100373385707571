import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import $ from "jquery";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { Container, Nav } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Alerts from "../common/Alerts";
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { checkStatus } from "../components/functions";
import { API_URL } from '../utils/config.js';
import { req } from "../utils/request.js";
import MultiSelect from '../components/MultiSelect';
import './Sectors.css';
import Audits from '../components/Audits';
import HistoryIcon from '@mui/icons-material/History';

class Sectors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      show: false,
      municipalities: [],
      provinces: [],
      sectors: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      showAudit: null,
      auditType: 'sectores'
    };

    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);
    this.showAudit = this.showAudit.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.administracion .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.sector").addClass("active");
    });
    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);

    const queryParams = new URLSearchParams(this.props.location.search);
    const parametroProvincia = queryParams.get('provincia');
    const parametroMunicipio = queryParams.get('municipio');

    req.get(`${API_URL}provincias`, { params: { estado: 'activo' } }).then(response => {
      this.setState({ provinces: response.data.data ?? [] });
      if (parametroProvincia) {
        this.provinciaSelected(parseInt(parametroProvincia)).then(() => {
          if (parametroMunicipio) {
            return this.municipioSelected(parseInt(parametroMunicipio));
          }
        }).then(() => this.setState({ isLoading: false }));
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  provinciaSelected(id) {
    const location = this.props.location;
    const params = new URLSearchParams({ provincia: id });
    this.props.history.replace({ pathname: location.pathname, search: params.toString() });
    this.setState({ provinciaSeleccionada: id, sectors: [], municipioSeleccionado: null });
    return req
      .get(`${API_URL}municipios`, { params: { estado: 'activo', provincia: id } })
      .then(response => {
        this.setState({ municipalities: response.data.data ?? [] });
      });
  }

  municipioSelected(id) {
    this.setState({ municipioSeleccionado: id });
    if (!id) {
      return;
    }
    const location = this.props.location;
    const params = new URLSearchParams({ provincia: this.state.provinciaSeleccionada, municipio: id });
    this.props.history.replace({ pathname: location.pathname, search: params.toString() });
    return req.get(`${API_URL}sectores`, { params: { municipio: id } }).then(response => {
      const municipio = this.state.municipalities.find(m => m.id === parseInt(id, 10));
      const provincia = this.state.provinces.find(p => p.id === municipio.provincia_id);
      const data = (response.data.data ?? []).map(sector => ({ ...sector, provincia, municipio }));
      this.setState({ sectors: data });
    });
  }

  desactivateRow(data) {
    data.activo = false;
    req.post(`${API_URL}sectores/${data.id}/toggle`).then(response => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Sector ha sido desactivado con éxito'
      });
    });
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${API_URL}sectores/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Sector ha sido activado con éxito'
      });
    });
  }

  showAudit(id) {
    this.setState({
      showAudit: id
    })
  }

  closeAudit() {
    this.setState({
      showAudit: null
    })
  }

  render() {
    const {
      isLoading,
      sectors,
      provinciaSeleccionada,
      municipioSeleccionado,
      alertMessage,
      alertShow,
      alertType,
      provinces,
      municipalities,
    } = this.state;

    const opcionesProvincias = [
      { label: 'Seleccione provincia', value: null },
      ...provinces.map(p => ({ label: p.nombre, value: p.id }))
    ];
    const opcionProvinciaSeleccionada = opcionesProvincias.find(p => p.value === provinciaSeleccionada);

    const opcionesMunicipios = [
      { label: 'Seleccione municipio', value: null },
      ...municipalities.map(p => ({ label: p.nombre, value: p.id }))
    ];
    const opcionMunicipioSeleccionado = opcionesMunicipios.find(p => p.value === municipioSeleccionado);

    return (
      <Container>
        {!!this.state.showAudit ? (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        ) : null}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <Link className="nav-link" to='/sectores/nuevo'>
                Crear Sector
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <SpinnerPlaceholder isLoading={isLoading}>
              <div className='row'>
                <div className='form-group col-3'>
                  <MultiSelect
                    name='provincia'
                    defaultValue={opcionProvinciaSeleccionada}
                    value={opcionProvinciaSeleccionada}
                    required
                    onChange={(e) => {
                      this.provinciaSelected(e);
                    }}
                    options={opcionesProvincias}
                  />
                </div>
                <div className='form-group col-3'>
                  <MultiSelect
                    name='municipio'
                    defaultValue={opcionMunicipioSeleccionado}
                    value={opcionMunicipioSeleccionado}
                    required
                    onChange={(e) => {
                      this.municipioSelected(e);
                    }}
                    options={opcionesMunicipios}
                  />
                </div>
              </div>
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "id",
                    hidden: true,
                    defaultSort: "desc",
                    field: "id",
                  },
                  { title: "Nombre", field: "nombre" },
                  {
                    title: "Provincia",
                    field: "provincia.id",
                    render: (rowData) => rowData.provincia.nombre,
                    customFilterAndSearch: (term, rowData) => ((rowData.provincia.nombre).toLowerCase()).indexOf(term.toLowerCase()) >= 0
                  },
                  {
                    title: "Municipio",
                    field: "municipio.id",
                    render: (rowData) => rowData.municipio.nombre,
                    customFilterAndSearch: (term, rowData) => (rowData.municipio.nombre.toLowerCase()).indexOf(term.toLowerCase()) >= 0
                  },
                  {
                    title: "Estatus", field: "activo",
                    render: rowData => (
                      checkStatus(rowData.activo)
                    )
                  }
                ]}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  pagination: {
                    previousTooltip: "Página anterior",
                    nextTooltip: "Página siguiente",
                    firstTooltip: "Primera página",
                    lastTooltip: "Última página",
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay registros que mostrar',
                  }
                }}
                options={{
                  actionsColumnIndex: -1,
                  paging: false
                }}
                actions={[
                  rowData => ({
                    icon: ModeEditOutlinedIcon,
                    tooltip: 'Editar',
                    onClick: () => this.props.history.push(`/sectores/${rowData.id}/editar`)
                  }),
                  rowData => ({
                    icon: DoNotDisturbOnOutlinedIcon,
                    tooltip: 'Desactivar',
                    onClick: (event, rowData) => this.desactivateRow(rowData),
                    disabled: rowData.activo === false
                  }),
                  rowData => ({
                    icon: CheckCircleOutlinedIcon,
                    tooltip: 'Activar',
                    onClick: (event, rowData) => this.activeRow(rowData),
                    disabled: rowData.activo === true
                  }),
                  rowData => ({
                    icon: HistoryIcon,
                    tooltip: 'Auditoria',
                    onClick: (event, rowData) => this.showAudit(rowData.id)
                  })
                ]}
                data={sectors}
              />
            </SpinnerPlaceholder>
          </div>
        </CSSTransition>
      </Container>
    );
  }
}
export default withRouter(Sectors);