import React, { Component, createRef } from "react";
import { Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { withRouter, } from 'react-router-dom';
import { req } from "../utils/request.js";
import Alerts from "../common/Alerts";
import Audits from '../components/Audits';
import $ from "jquery";
import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import {
  Link
} from "react-router-dom";

import { getTrueFalse, checkStatus } from "../components/functions";
import { API_URL } from '../utils/config.js'

class Fields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      formfields: [],
      editingData: [],
      questiontypes: [],
      sections: [],
      sectionFilter: [],
      forms: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      auditType: "formularios/items",
      showAudit: false
    };
    this.tableRef = createRef();
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }
  showAudit(id) {
    this.setState({
      showAudit: id
    })
  }
  closeAudit() {
    this.setState({
      showAudit: null
    })
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        alertShow: this.props.location.state.alertShow,
        alertMessage: this.props.location.state.alertMessage,
        alertType: this.props.location.state.alertType,
      })
    }
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.encuesta .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.formulario").addClass("active");
    });

    this.loadItems();
    req.get(`${API_URL}tipos/pregunta`).then(response => {
      this.setState({
        questiontypes: response.data.data ?? []
      });
    });
    req.get(`${API_URL}formularios`, { params: { estado: 'activo' } }).then(response => {
      this.setState({
        forms: response.data.data ?? []
      });
    });

    req.get(`${API_URL}formularios/secciones`).then((response) => {

      let data = response.data.data || response.data;
      let sectionFilter = [];
      data.forEach(element => {
        sectionFilter[element.id] = element.name
      });
      this.setState({
        isLoaded: true,
        sections: response.data.data || response.data,
        sectionFilter: sectionFilter
      });
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);

  }

  loadItems(page = 1) {
    req.get(`${API_URL}formularios/items`, { params: { page } }).then(response => {
      this.setState({
        formfields: response.data.data ?? []
      });
    });
  }

  editRow(data) {
    this.props.history.push("/preguntas/" + data.id);
  }
  desactivateRow(data) {
    data.activo = false;
    req.post(`${API_URL}formularios/items/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'La Pregunta ha sido desactivada con éxito'
      });
    });
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${API_URL}formularios/items/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'La Pregunta ha sido activada con éxito'
      });
    });
  }
  render() {
    const {
      alertMessage,
      alertShow,
      alertType,
    } = this.state;
    return (
      <Container>
        {this.state.showAudit && <Audits close={this.closeAudit} show={!!this.state.showAudit} tipo={this.state.auditType} id={this.state.showAudit} />}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <span className="nav-link" >
                <Link className="dropdown-item campos" to="/preguntas/crear"><span>Crear Pregunta</span></Link>

              </span>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              tableRef={this.tableRef}
              title=""
              columns={[
                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  filtering: false,
                  field: "id"
                },
                { title: "Titulo", field: "pregunta", filtering: false, width: "40%" },
                {
                  title: "Tipo de Pregunta", field: "tipo_pregunta", filtering: false,
                  render: (rowData) => (
                    <span>{rowData.tipo_pregunta.nombre}</span>
                  ),
                  customFilterAndSearch: (term, rowData) => (rowData.tipo_pregunta.nombre.toLowerCase()).indexOf(term.toLowerCase()) >= 0
                },
                {
                  title: "Requerido", field: "requerido", filtering: false,
                  render: (rowData) => (
                    getTrueFalse(rowData.requerido, "Requerido", "No Requerido")
                  )
                },
                {
                  title: "Sección", field: "section",
                  render: (rowData) => (
                    rowData.seccion.nombre
                  ),
                  customFilterAndSearch: (term, rowData) => (rowData.seccion.nombre.toLowerCase()).indexOf(term.toLowerCase()) !== -1
                },
                {
                  title: "Formulario", field: "formulario", width: "30%",

                  render: (rowData) => (
                    rowData.formulario.nombre
                  ),
                  customFilterAndSearch: (term, rowData) => (rowData.formulario.nombre.toLowerCase()).indexOf(term.toLowerCase()) !== -1

                },
                {
                  title: "Estatus", field: "activo", filtering: false,
                  render: rowData => (
                    checkStatus(rowData.activo)
                  )
                },
                { title: "Orden", field: "orden", width: "5%" }
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "right",
                paging: true,
                pageSize: 30,
                emptyRowsWhenPaging: false,
                filtering: false,
                actionsColumnIndex: -1,
                draggable: false,

              }}
              data={query => {
                const params = { page: query.page + 1 };
                if (query.search) {
                  params.pregunta = query.search;
                }
                // TODO implement filters
                return req.get(`${API_URL}formularios/items`, { params }).then(response => {
                  return new Promise((resolve, reject) => {
                    this.tableRef.current.dataManager.changePageSize(response.data.meta.per_page);
                    resolve({
                      data: response.data.data,
                      page: response.data.meta.current_page - 1,
                      totalCount: response.data.meta.total
                    });
                  });
                });
              }}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, rowData) => this.editRow(rowData)
                },
                rowData => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: 'Desactivar',
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: rowData.activo === false
                }),
                rowData => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: 'Activar',
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.showAudit(rowData.id)
                }),

              ]}
            />
          </div>
        </CSSTransition>
      </Container>
    );
  }
}
export default withRouter(Fields);
