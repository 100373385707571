function replaceDiacritics(str) {
   var diacritics = [
      { char: 'A', base: /[\300-\306]/g },
      { char: 'a', base: /[\340-\346]/g },
      { char: 'E', base: /[\310-\313]/g },
      { char: 'e', base: /[\350-\353]/g },
      { char: 'I', base: /[\314-\317]/g },
      { char: 'i', base: /[\354-\357]/g },
      { char: 'O', base: /[\322-\330]/g },
      { char: 'o', base: /[\362-\370]/g },
      { char: 'U', base: /[\331-\334]/g },
      { char: 'u', base: /[\371-\374]/g }
   ]
   diacritics.forEach(function (letter) {
      str = str.replace(letter.base, letter.char);
   });
   return str;
};

export const getCustomReportFilterFunction = (field) => {
   return (term, rowData) => {
      console.log({ rompel: rowData[field] })
      const rowDataFieldLowerCase = (rowData[field]?.toString() || '').toLowerCase();
      const termLowerCase = (term || '').toLowerCase();

      const withoutDiacritic = replaceDiacritics(rowDataFieldLowerCase);
      return withoutDiacritic.includes(termLowerCase) || rowDataFieldLowerCase.includes(termLowerCase)
   }
}