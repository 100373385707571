import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { clearReportData, loadReportData } from './ReportDataService.js';

export function TableReporte17() {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const data = loadReportData();
    const tData = [];
    for (let key in data) {
      const row = data[key];
      tData.push(row);
    }
    setTableData(tData);

    return () => clearReportData();
  }, []);

  const downloadCsv = () => {
    if (tableData.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'reporte',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of tableData) {
      const row = { ...elem };
      delete row.tableData; // remove this property added by MaterialTable
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };

  return (
    <div className='container'>
      <div className='mainBox'>
        <div className='d-flex justify-content-end'>
          {tableData.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
        <MaterialTable
          title=''
          columns={[
            { title: 'Organización', field: 'organizacion' },
            { title: 'Hombres', field: 'hombres' },
            { title: 'Mujeres', field: 'mujeres' },
            { title: 'Total', field: 'total' },
          ]}
          options={{
            search: false,
            draggable: false,
            emptyRowsWhenPaging: false,
            paging: false,
          }}
          data={tableData}
        />
      </div>
    </div>
  );
}

export default TableReporte17;
