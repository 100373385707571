import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class AttendanceService {
  query(params) {
    return req.get(`${API_URL}asistencia/actividades/asistencia`, { params });
  }

  save(actividad) {
    return req.post(`${API_URL}asistencia/actividades/asistencia/store`, {
      ...actividad,
    });
  } 

  find(id) {
    return req.get(`${API_URL}asistencia/actividades/asistencia/${id}/show`);
  }
 
}

const service = new AttendanceService();

export default service;
