import React, { Component, createRef } from "react";
import { Button, Col, Row, Form, Modal, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field, ErrorMessage } from "formik";
import { withRouter, Link } from "react-router-dom";
import * as Yup from "yup";
import { req } from "../utils/request.js";
import SortIcon from '@mui/icons-material/Sort';
import Alerts from "../common/Alerts.js";
import $ from "jquery";
import Audits from '../components/Audits.js';
import { checkStatus } from "../components/functions.js";

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { API_URL } from '../utils/config.js';
import MultiSelect from '../components/MultiSelect.js';

class PollForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      popupAddForm: false,
      forms: [],
      allForms: [],
      opcionesRequisito: [],
      editingData: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      auditType: "formularios",
      auditID: 0,
      showAudit: false
    };
    this.tableRef = createRef();
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);
    this.show_PopUpPullProgram = this.show_PopUpPullProgram.bind(this);
    this.hide_PopUpPullProgram = this.hide_PopUpPullProgram.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }
  showAudit(data) {
    this.setState({
      auditID: data.id,
      showAudit: true
    })
  }
  closeAudit() {
    this.setState({
      showAudit: false
    })
  }

  componentDidMount() {
    sessionStorage.clear();
    if (this.props.location.state) {
      this.setState({
        alertShow: this.props.location.state.alertShow,
        alertMessage: this.props.location.state.alertMessage,
        alertType: this.props.location.state.alertType,
      })
    }
    req
      .get(`${API_URL}formularios`, { params: {paginacion: 999999 } })
      .then((response) => {
        this.setState({
          allForms: response.data.data ?? [],
        });
      });
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.encuesta .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.formulario").addClass("active");
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }

  show_PopUpPullProgram(data) {
    console.log(data);
    req.get(`${API_URL}tipos/formulario`, { params: { estado: 'activos', paginacion: 999999 } }).then(response => {
      this.setState({ popupAddPollProgram: true, formtypes: response.data.data ?? [], editingData: data });
    });
    req.get(`${API_URL}selectores/formularios`).then(response => {
      this.setState({
        opcionesRequisito: [
          { label: 'Ninguno', value: null },
          ...response.data.data.filter(elem => elem.id !== data.id && elem.requisito === null)
            .map(elem => ({
              label: elem.nombre,
              value: elem.id,
            }))]
      });
    });
  }
  hide_PopUpPullProgram() {
    this.setState({ popupAddPollProgram: false });
  }
  editRow(data) { 
    this.props.history.push(`/formulario/${data.id}`)
  }
  desactivateRow(data) {
    data.activo = false;
    req.post(`${API_URL}formularios/${data.id}/toggle`).then(response => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Formulario ha sido desactivado con éxito'
      });
    });
  }
  goToEditOrderIndexField(data) {
    this.props.history.push(`/formulario/edit-order-index-field/${data.id}?formulary_name=${data.nombre}`);
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${API_URL}formularios/${data.id}/toggle`).then(response => {
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El Formulario ha sido activado con éxito'
      });
    });
  }
  render() {
    const {
      popupAddPollProgram,
      forms,
      opcionesRequisito,
      formtypes,
      editingData,
      alertMessage,
      alertShow,
      alertType,
    } = this.state;
    const addFormValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
      requisito: Yup.string().nullable(),
    });
    const formularioRequisitoSeleccionado = opcionesRequisito.find(elem => elem.value === editingData.requisito);
    return (
      <Container>
        {this.state.showAudit && <Audits close={this.closeAudit} show={this.state.showAudit} tipo={this.state.auditType} id={this.state.auditID} />}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <Link className="nav-link" to="/formularios/crear"><span>Crear Formulario</span></Link>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              tableRef={this.tableRef}
              columns={[
                {
                  title: "id",
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "nombre" },
                {
                  title: "Tipo de Encuesta", field: "tipo",
                  render: rowData => (
                    rowData.tipo.nombre
                  )
                },
                {
                  title: 'Formulario requisito',
                  field: 'requisito',
                  render: rowData => (
                    returnFormularioRequerido(this.state.allForms, rowData.requisito)
                  )
                },
                {
                  title: "Estatus", field: "activo",
                  render: rowData => (
                    checkStatus(rowData.activo)
                  )
                }
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "right",
                paging: true,
                actionsColumnIndex: -1,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                draggable: false,
              }}
              data={query => {
                this.setState({ query })
                // TODO implement filters
                return new Promise(resolve => {
                  const params = { page: query.page + 1, paginacion: this.tableRef.current.dataManager.pageSize };
                  if (query.search) {
                    params.busqueda = query.search;
                  }
                  req.get(`${API_URL}formularios`, { params }).then(response => {
                    
                    resolve({
                      data: response.data.data,
                      page: response.data.meta.current_page - 1,
                      totalCount: response.data.meta.total
                    })
                  });
                });
              }}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, rowData) => this.editRow(rowData)
                },
                rowData => ({
                  icon: SortIcon,
                  tooltip: 'Editar Orden/Índice',
                  onClick: (event, rowData) => this.goToEditOrderIndexField(rowData)
                }),
                rowData => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: 'Desactivar',
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                }),
                rowData => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: 'Activar',
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.showAudit(rowData)
                })
              ]}
            />
          </div>
        </CSSTransition>
        <Modal
          centered
          show={popupAddPollProgram}
          onHide={this.hide_PopUpPullProgram}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Formulario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                nombre: editingData.nombre || "",
                tipo: editingData.tipo?.id || "",
                requisito: editingData.requisito || '',
              }}
              validationSchema={addFormValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                this.hide_PopUpPullProgram();
                values.activo = 1;
                if (!values.requisito) {
                  delete values.requisito;
                }

                if (editingData.id !== undefined) {
                  delete values.id;
                  req.post(`${API_URL}formularios/${editingData.id}/update`, values).then(() => {
                    this.setState({
                      alertShow: true,
                      alertType: 'success',
                      alertMessage: 'El formulario fue actualizado con éxito'
                    });
                    this.tableRef.current.onQueryChange({});
                  });
                } else {
                  req.post(`${API_URL}formularios/store`, values).then(response => {
                    this.setState({
                      forms: [...forms, response.data.data],
                      alertShow: true,
                      alertType: 'success',
                      alertMessage: 'El formulario fue creado con éxito'
                    });
                    this.tableRef.current.onQueryChange({});
                  });
                }
                this.hide_PopUpPullProgram();
                resetForm();
                setSubmitting(false);

              }}
            >
              {({ handleSubmit, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <div className="form-group">
                        <label>Nombre</label>
                        <Field type="text" name="nombre" />
                        <ErrorMessage
                          className="errorField"
                          name="nombre"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="form-group">
                        <label>Tipo de Encuesta</label>
                        <Field name="tipo" as="select">
                          <option value="null"> Selecciona </option>
                          {
                            formtypes.map(elem => <option key={elem.id} value={elem.id}>{elem.nombre}</option>)
                          }
                        </Field>
                        <ErrorMessage
                          className="errorField"
                          name="tipo"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg='12'>
                      <div className='form-group'>
                        <label>Formulario requisito</label>
                        <MultiSelect
                          name='requisito'
                          defaultValue={formularioRequisitoSeleccionado}
                          onChange={(e) => {
                            setFieldValue('requisito', e);
                          }}
                          options={opcionesRequisito}
                        />
                        <ErrorMessage
                          component='p'
                          name='requisito'
                          className='small text-danger'
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="primary "
                        >
                          {isSubmitting ? "Guardando..." : "Guardar"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
function returnFormularioRequerido(data, value){
  console.log(value);
  for (var i = 0, len = data.length; i < len; i++) {
    if(data[i].id === value){
      console.log("paso");
        return data[i].nombre
    }
}
}
export default withRouter(PollForms);
