import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class ProvinceService {
  query(pais, estado) {
    const params = {};
    if (pais) {
      params.pais = pais;
    }
    if (estado) {
      params.estado = estado;
    }
    return req.get(`${API_URL}provincias`, { params });
  }
}

const service = new ProvinceService();

export default service;
