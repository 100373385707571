import {
  CheckCircleOutlined,
  DoNotDisturbOnOutlined,
  ModeEditOutlined,
  PeopleOutline,
} from '@mui/icons-material';
import MaterialTable from 'material-table';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { createRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { changeDateFormat, checkStatus } from '../components/functions';
import activityService from '../services/activities';
import AuthService from '../services/auth.service';
import { ROLE_USUARIO_CONSULTA } from '../utils/roles'; 
import Audits from '../components/Audits';
import HistoryIcon from '@mui/icons-material/History';

export function NewActivitiesList(props) {
  const [auditModal, setAuditModal] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    type: 'success',
    message: '',
  });
  const [alertTimeout, setAlertTimeout] = useState();

  const auth = AuthService.getCurrentUser();
  const tableRef = createRef();

  const isAdmin = AuthService.isAdmin();

  const fetchData = (query) => {
    return new Promise((resolve) => {
      const params = { page: query.page + 1, paginacion: query.pageSize };
      // TODO implement filter
      if (query.search) {
        params.busqueda = query.search;
      }
      activityService.query(params).then((response) => {
        resolve({
          data: response.data.data ?? [],
          page: response.data.meta.current_page - 1,
          totalCount: response.data.meta.total,
        });
      });
    });
  };

  const toggle = (id) => {
    clearTimeout(alertTimeout);
    activityService
      .toggle(id)
      .then((response) => {
        tableRef.current.onQueryChange({});
        const activo = response.data.data.activo;
        setAlert({
          show: true,
          type: 'success',
          message: `La actividad ha sido ${activo ? 'activada' : 'desactivada'
            } con éxito`,
        });
      })
      .finally(() => {
        const timeoutId = setTimeout(() => {
          setAlert({ show: false, type: 'success', message: '' });
        }, 5000);
        setAlertTimeout(timeoutId);
      });
  };

  return (
    <div className='container'>
      {!!auditModal && (
        <Audits
          close={() => setAuditModal(null)}
          show={!!auditModal}
          tipo={'actividades'}
          id={auditModal}
        />
      )}
      <div className='titleButtons'>
        <div className='justify-content-end nav'>
          {/* Alerts */}
          <div className={`alert ${alert.show ? 'show' : ''} ${alert.type}`}>
            <p>{alert.message}</p>
          </div>
          {/* Create activity button */}
          <Link to='/actividades/nueva' className='nav-item'>
            <span className='nav-link'>Crear Actividad</span>
          </Link>
        </div>
      </div>
      <div className='mainBox'>
        <MaterialTable
          title=''
          tableRef={tableRef}
          columns={[
            {
              title: 'ID',
              defaultSort: 'desc',
              field: 'id',
            },
            {
              title: 'Nombre',
              field: 'nombre',
            },
            {
              title: 'Tipo',
              field: 'tipo.nombre',
            },
            {
              title: 'Programa',
              field: 'programa.nombre',
            },
            {
              title: 'Organización',
              field: 'organizacion.nombre',
              hidden: !isAdmin,
            },
            {
              title: 'Fecha de inicio',
              field: 'fecha_inicio',
              render: (rowData) => changeDateFormat(rowData.fecha_inicio),
            },
            {
              title: 'Fecha de cierre',
              field: 'fecha_cierre',
              render: (rowData) => changeDateFormat(rowData.fecha_cierre),
            },
            {
              title: 'Estatus',
              field: 'activo',
              render: (rowData) => checkStatus(rowData.activo),
            },
          ]}
          data={fetchData}
          actions={[
            {
              icon: ModeEditOutlined,
              tooltip: 'Editar',
              onClick: (event, rowData) =>
                props.history.push(`/actividades/${rowData.id}`),
            },
            (rowData) => ({
              icon: CheckCircleOutlined,
              tooltip: 'Activar',
              onClick: (event, rowData) => toggle(rowData.id),
              disabled: rowData.activo,
            }),
            (rowData) => ({
              icon: DoNotDisturbOnOutlined,
              tooltip: 'Desactivar',
              onClick: (event, rowData) => toggle(rowData.id),
              disabled: !rowData.activo,
            }),
            {
              icon: PeopleOutline,
              tooltip: 'Participantes',
              onClick: (event, rowData) =>
                props.history.push(`actividades/${rowData.id}/asistencias`),
            },
            (rowData) => ({
              icon: HowToRegOutlinedIcon,
              tooltip: 'Asistencia',
              disabled: auth?.user?.role?.nombre !== ROLE_USUARIO_CONSULTA && (rowData.meta === null || rowData.meta === 0),
              onClick: (event, rowData) =>
                props.history.push(`/asistencia/${rowData.id}`),
            }),
            rowData => ({
              icon: HistoryIcon,
              tooltip: 'Auditoria',
              onClick: (event, rowData) => setAuditModal(rowData.id)
            })
          ]}
          localization={{
            header: {
              actions: 'Acciones',
            },
            toolbar: {
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
            },
            pagination: {
              previousTooltip: 'Página anterior',
              nextTooltip: 'Página siguiente',
              firstTooltip: 'Primera página',
              lastTooltip: 'Última página',
            },
            body: {
              emptyDataSourceMessage: 'No hay registros que mostrar',
            },
          }}
          options={{
            search: true,
            selection: false,
            searchFieldAlignment: 'right',
            actionsColumnIndex: -1,
            paging: true,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            draggable: false,
          }}
        />
      </div>
    </div>
  );
}

export default withRouter(NewActivitiesList);
