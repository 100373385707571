
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }
    render() {
        return (
            <footer style={{textAlign: 'center', marginBottom: 20, marginTop: 20, fontSize: 14, color: '#626262' }}>
                <Container>
                    <em>Este sitio web fue desarrollado con el auspicio de la Agencia de los Estados Unidos para el Desarrollo Internacional (USAID por sus siglas en inglés), bajo el Acuerdo de Cooperación No. AID-517-A-12-00002. La información proporcionada en este sitio no es información oficial del Gobierno de los Estados Unidos y no representa las visiones o posiciones de USAID o del Gobierno de los Estados Unidos. © 2022 Entrena SRL. Todos los derechos reservados, excepto los que estén a favor del Gobierno de los Estados Unidos.</em>
                </Container>
            </footer>
        )
    }
}
export default Footer;
