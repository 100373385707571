import React, { Component, createRef } from "react";
import { Button, Col, Row, Form, Modal, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { req } from "../utils/request.js";
import { withRouter } from "react-router-dom";
import Alerts from "../common/Alerts";
import FS from "../components/FieldStructure";
import $ from "jquery";
import Audits from '../components/Audits';
import { checkStatus } from "../components/functions";

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import organizationService from '../services/organizations.js';
import municipalityService from '../services/municipality.js';
import sectorService from '../services/sector.js';
import provinceService from '../services/province.js';
import { API_URL } from '../utils/config.js';
import MultiSelect from '../components/MultiSelect';

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      popupAddOrganization: false,
      isLoadingPopupData: false,
      sectoores: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      editingData: [],
      organizaciones: [],
      paisesToSelect: [],
      provinciasToSelect: [],
      municipiosToSelect: [],
      sectoresToSelect: [],
      auditType: "organizaciones",
      showAudit: null
    };
    this.tableRef = createRef();
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);

    this.show_PopUpOrganization = this.show_PopUpOrganization.bind(this);
    this.hide_PopUpOrganization = this.hide_PopUpOrganization.bind(this);
    this.closeAudit = this.closeAudit.bind(this);

  }
  componentDidMount() {
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.administracion .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.organizaciones").addClass("active");
    });
    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);

  }

  showAudit(id) {
    this.setState({
      showAudit: id
    })
  }
  closeAudit() {
    this.setState({
      showAudit: null
    })
  }

  loadOrganizations() {
    this.tableRef.current.onQueryChange({});
  }

  editRow(data) {
    this.show_PopUpOrganization(data);
  }
  desactivateRow(data) {
    data.activo = false;
    organizationService.toggle(data.id).then(() => {
      this.setState({
        alertShow: true,
        alertMessage: 'La organización ha sido desactivada con éxito',
        alertType: 'success'
      });
    });
  }
  activeRow(data) {
    data.activo = true;
    organizationService.toggle(data.id).then(() => {
      this.setState({
        alertShow: true,
        alertMessage: 'La organización ha sido activada con éxito',
        alertType: 'success'
      });
    });
  }

  show_PopUpOrganization(data) {
    console.log({ data })
    this.setState({ municipiosToSelect: [], sectoresToSelect: [] });
    const pais = 62 // TODO: 62 is Dominican Republic. We eventually need to replace with the following: data.pais?.id;
    // load paises
    req.get(`${API_URL}paises`, { params: { estado: 'activos' } }).then(response => {
      this.setState({ paisesToSelect: response.data.data ?? [] });
    });
    const editingData = data ?? { pais };
    this.setState({ isLoadingPopupData: true });
    if (data === undefined) {
      this.loadProvinces(pais).then(() => {
        this.setState({
          popupAddOrganization: true, isLoadingPopupData: false, editingData,
          provinciaSeleccionada: null,
          municipioSeleccionado: null,
          sectorSeleccionado: null,
        });
      });
      return;
    }
    const provincia = editingData.provincia?.id ?? '';
    const municipio = editingData.municipio?.id ?? '';
    const sector = editingData.sector?.id ?? '';
    const barrio = editingData.barrio?.id ?? '';
    Promise.all([
      this.loadProvinces(pais),
      this.loadMunicipios(provincia).then(() => (this.loadSectores(municipio)))
    ]).then(() => {
      this.setState({
        isLoadingPopupData: false,
        editingData: { ...data, pais, municipio, provincia, sector, barrio },
        provinciaSeleccionada: provincia,
        municipioSeleccionado: municipio,
        sectorSeleccionado: sector,
      });
    });
    this.setState({ popupAddOrganization: true });
  }

  hide_PopUpOrganization() {
    this.setState({ popupAddOrganization: false });
  }

  countrySelected(pais, setFieldValue) {
    setFieldValue('provincia', '');
    return this.loadProvinces(pais);
  }

  loadProvinces(pais) {
    return provinceService.query(pais, 'activos').then(response => {
      this.setState({ provinciasToSelect: response.data.data ?? [] });
    });
  }

  providerSelected(provincia, setFieldValue) {
    setFieldValue('municipio', null);
    setFieldValue('sector', null);
    this.setState({
      municipiosToSelect: [],
      sectoresToSelect: [],
      provinciaSeleccionada: provincia,
      municipioSeleccionado: null,
      sectorSeleccionado: null
    });
    return this.loadMunicipios(provincia);
  }

  loadMunicipios(provincia) {
    if (provincia === null) {
      return;
    }
    return municipalityService.query(provincia, 'activos').then(response => {
      this.setState({ municipiosToSelect: response.data.data ?? [] });
    });
  }

  municipalitySelected(municipio, setFieldValue) {
    setFieldValue('sector', null);
    this.setState({ sectoresToSelect: [], municipioSeleccionado: municipio, sectorSeleccionado: null });
    return this.loadSectores(municipio);
  }

  loadSectores(municipio) {
    if (municipio === null) {
      return;
    }
    return sectorService.query(municipio, 'activos').then(response => {
      this.setState({ sectoresToSelect: response.data.data ?? [] });
    })
  }

  render() {
    const {
      popupAddOrganization,
      alertMessage,
      alertShow,
      alertType,
      editingData,
      paisesToSelect,
      provinciasToSelect,
      municipiosToSelect,
      sectoresToSelect,
    } = this.state;
    const addOrganizationValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
      director_nombre: Yup.string().required("Director es un campo requerido."),
      contacto_nombre: Yup.string().required(
        "Contacto principal es un campo requerido."
      ),
      telefono: Yup.string().required("Teléfino es un campo requerido."),
      email: Yup.string().required("Email es un campo requerido."),
      provincia: Yup.string()
        .nullable()
        .required("Provincia es un campo requerido."),
      municipio: Yup.string()
        .nullable()
        .required("Municipio es un campo requerido."),
      sector: Yup.string()
        .nullable()
        .required("Municipio es un campo requerido."),
    });

    const opcionesProvincias = [
      { label: 'Selecciona', value: null },
      ...provinciasToSelect.map(p => ({ label: p.nombre, value: p.id }))
    ];
    const provinciaSeleccionada = opcionesProvincias.find(p => p.value === this.state.provinciaSeleccionada);

    const opcionesMunicipios = [
      { label: 'Selecciona', value: null },
      ...municipiosToSelect.map((m) => ({ label: m.nombre, value: m.id }))
    ];
    const municipioSeleccionado = opcionesMunicipios.find(m => m.value === this.state.municipioSeleccionado);

    const opcionesSectores = [
      { label: 'Selecciona', value: null },
      ...sectoresToSelect.map((m) => ({ label: m.nombre, value: m.id }))
    ];

    const sectorSeleccionado = opcionesSectores.find(m => m.value === this.state.sectorSeleccionado);

    return (
      <Container>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        )}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <span className="nav-link" onClick={() => {
                this.show_PopUpOrganization();
              }}>
                Crear Organización
              </span>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              tableRef={this.tableRef}
              title=""
              columns={[
                {
                  title: "Logo",
                  field: "imageUrl",
                  render: (rowData) => (
                    <img
                    alt={rowData.name}
                      src={rowData.imagen}
                      style={{
                        minWidth: "50px",
                        height: "40px",
                        background: "#dadada",
                        borderRadius: "4px",
                        padding: "4px",
                      }}
                    />
                  ),
                },
                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  field: "id",
                },
                {
                  title: "Nombre",
                  field: "nombre",
                },
                { title: "Contacto Primario", field: "contacto_nombre" },
                { title: "Teléfono", field: "telefono" },
                { title: "Correo Electrónico", field: "email" },
                {
                  title: "Estatus",
                  field: "activo",
                  render: (rowData) => checkStatus(rowData.activo),
                },
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "right",
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                draggable: false,
              }}
              data={query => {
                const params = { page: query.page + 1, paginacion: query.pageSize };
                if (query.search) {
                  params.busqueda = query.search;
                }
                // TODO implement filters
                return organizationService.query(params).then(response => {
                  this.tableRef.current.dataManager.changePageSize(response.data.meta.per_page);
                  return {
                    data: response.data.data,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total
                  };
                });
              }}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: "Editar",
                  onClick: (event, rowData) => this.editRow(rowData),
                },
                (rowData) => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: "Desactivar",
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: rowData.activo === false,
                }),
                (rowData) => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: "Activar",
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true,
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.showAudit(rowData.id)
                })
              ]}
            />
          </div>
        </CSSTransition>
        <Modal
          size="xl"
          centered
          show={popupAddOrganization}
          onHide={this.hide_PopUpOrganization}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Organización</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.isLoadingPopupData ? (
              // show a spinner when loading
              <div className='text-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            )
              :
              (
                <Formik
                  initialValues={{
                    id: editingData.id || "",
                    nombre: editingData.nombre || "",
                    director_nombre: editingData.director_nombre || "",
                    contacto_nombre: editingData.contacto_nombre || "",
                    telefono: editingData.telefono || "",
                    email: editingData.email || "",
                    direccion: editingData.direccion || "",
                    pais: editingData.pais ?? '',
                    provincia: editingData.provincia || '',
                    municipio: editingData.municipio || '',
                    sector: editingData.sector || '',
                    descripcion: editingData.descripcion || "",
                    imagen: editingData.imagen || "",
                    financiador: editingData.financiador || ""
                  }}
                  enableReinitialize={true}
                  validationSchema={addOrganizationValidationSchema}
                  onSubmit={(values, { setSubmitting }) => {

                    setSubmitting(true);
                    this.hide_PopUpOrganization();
                    values.activo = true;
                    if (editingData.id !== undefined) {
                      if (values.financiador === 'Selecciona') {
                        values.financiador = '';
                      }
                      organizationService.update(values).then(() => {
                        this.setState({
                          alertType: 'success',
                          alertShow: true,
                          alertMessage: 'La organización ha sido actualizada con éxito'
                        });
                        this.loadOrganizations();
                      });
                    } else {
                      if (values.financiador === 'Selecciona') {
                        delete values.financiador;
                      }
                      organizationService.save(values).then(() => {
                        this.setState({
                          alertType: 'success',
                          alertShow: true,
                          alertMessage: 'La organización fue creada con éxito'
                        });
                        this.loadOrganizations();
                      });
                    }
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    errors,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <FS label="Nombre" name="nombre" errors={errors} col={[6]}>
                          <Field type="text" name="nombre" />
                        </FS>
                        <FS
                          label="Logo (Alto Maximo: 55px)"
                          name="imagen"
                          errors={errors}
                          col={[6]}
                        >
                          <input
                            id="imagen"
                            name="imagen"
                            type="file"
                            onChange={(event) => {
                              const reader = new FileReader();
                              reader.onload = () => {
                                setFieldValue('imagen', reader.result);
                              };
                              const file = event.currentTarget.files[0];
                              reader.readAsDataURL(file);
                            }}
                          />
                          {values.imagen !== "" ? (
                            <Row style={{ marginTop: 20 }}>
                              <Col lg="5">
                                <img
                                alt={values.nombre}
                                  style={{
                                    width: "100%",
                                    background: "#dadada",
                                    borderRadius: "4px",
                                    padding: "8px",
                                  }}
                                  src={values.imagen}
                                />
                              </Col>
                              <Col lg="6">
                                <Button style={{ marginTop: 10 }} onClick={() => { setFieldValue("imagen", "") }} className="secondaryButton ">
                                  Borrar
                                </Button>
                              </Col>
                            </Row>
                          ) : null}
                        </FS>
                        <FS
                          label="Director"
                          name="director_nombre"
                          errors={errors}
                          col={[6]}
                        >
                          <Field type="text" name="director_nombre" />
                        </FS>
                        <FS
                          label="Contacto principal"
                          name="contacto_nombre"
                          errors={errors}
                          col={[6]}
                        >
                          <Field type="text" name="contacto_nombre" />
                        </FS>
                        <FS
                          label="Teléfono"
                          name="telefono"
                          errors={errors}
                          col={[6]}
                        >
                          <Field type="text" name="telefono" />
                        </FS>
                        <FS label="Email" name="email" errors={errors} col={[6]}>
                          <Field type="text" name="email" />
                        </FS>
                        <FS
                          label="Dirección"
                          name="direccion"
                          errors={errors}
                          col={[6]}
                        >
                          <Field type="text" name="direccion" />
                        </FS>
                        <FS
                          label="País"
                          name="pais"
                          errors={errors}
                          className='d-none'
                          col={[6]}
                        >
                          <Field
                            onChange={(e) => {
                              handleChange(e);
                              this.countrySelected(e.target.value, setFieldValue);
                            }}
                            name="pais"
                            as="select"
                          >
                            <option value=""> Selecciona </option>
                            {paisesToSelect.map((p) => (
                              <option key={p.id} value={p.id}> {p.nombre_espanol}</option>
                            ))}
                          </Field>
                        </FS>
                        <div className='form-group col-6'>
                          <label className='form-label'>Provincia</label>
                          <MultiSelect
                            name='provincia'
                            defaultValue={provinciaSeleccionada}
                            value={provinciaSeleccionada}
                            required
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange({
                                target: { name: 'provincia', value: e },
                              });
                              this.providerSelected(e, setFieldValue);
                            }}
                            options={opcionesProvincias}
                          />
                          <ErrorMessage
                            name='provincia'
                            className='small text-danger'
                            component='p'
                          />
                        </div>
                        <div className='form-group col-6'>
                          <label className='form-label'>Municipio</label>
                          <MultiSelect
                            name='municipio'
                            defaultValue={municipioSeleccionado}
                            value={municipioSeleccionado}
                            required
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange({
                                target: { name: 'municipio', value: e },
                              });
                              this.municipalitySelected(e, setFieldValue);
                            }}
                            options={opcionesMunicipios}
                          />
                          <ErrorMessage
                            name='municipio'
                            className='small text-danger'
                            component='p'
                          />
                        </div>
                        <div className='form-group col-6'>
                          <label className='form-label'>Sector</label>
                          <MultiSelect
                            name='sector'
                            defaultValue={sectorSeleccionado}
                            value={sectorSeleccionado}
                            required
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange({
                                target: { name: 'sector', value: e },
                              });
                              this.setState({ sectorSeleccionado: e });
                            }}
                            options={opcionesSectores}
                          />
                          <ErrorMessage
                            name='sector'
                            className='small text-danger'
                            component='p'
                          />
                        </div>
                        <div className='form-group col-6'>
                          <label className='form-label'>Financiador</label>
                          <MultiSelect
                            name='financiador'
                            value={{ label: values.financiador, value: values.financiador }}
                            required
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange({
                                target: { name: 'financiador', value: e },
                              });
                            }}
                            options={['Selecciona', 'USAID', 'KOICA'].map(x => ({ label: x, value: x }))}
                          />
                          <ErrorMessage
                            name='sector'
                            className='small text-danger'
                            component='p'
                          />
                        </div>
                        <FS
                          label="Descripción"
                          name="descripcion"
                          errors={errors}
                          col={[6]}
                        >
                          <Field type="text" name="descripcion" />
                        </FS>
                        <Col lg="12">
                          <Button
                            type="submit"
                            className="buttonSubmit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Guardando..." : "Guardar"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
export default withRouter(Organizations);
