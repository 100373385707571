import { Parser } from 'json2csv';



const CsvGenerator = (data, type) => {


  function getAgeColumns(type) {
    const ageColumns = {
      USAID: [
        {
          title: 'Masculino 10-14 años',
          field: 'm_1014'
        },
        {
          title: 'Femenino 10-14 años',
          field: 'f_1014'
        },
        {
          title: 'Masculino 15-19 años',
          field: 'm_1519'
        },
        {
          title: 'Femenino 15-19 años',
          field: 'f_1519'
        },
        {
          title: 'Masculino 20-24 años',
          field: 'm_2024'
        },
        {
          title: 'Femenino 20-24 años',
          field: 'f_2024'
        },
        {
          title: 'Masculino 25-29 años',
          field: 'm_2529'
        },
        {
          title: 'Femenino 25-29 años',
          field: 'f_2529'
        },
        {
          title: 'Masculino 30-35 años',
          field: 'm_3035'
        },
        {
          title: 'Femenino 30-35 años',
          field: 'f_3035'
        },
      ],
      KOICA: [
        {
          title: 'Masculino 10-11 años',
          field: 'm_1011'
        },
        {
          title: 'Femenino 10-11 años',
          field: 'f_1011'
        },
        {
          title: 'Masculino 12-14 años',
          field: 'm_1214'
        },
        {
          title: 'Femenino 12-14 años',
          field: 'f_1214'
        },
        {
          title: 'Masculino 15-17 años',
          field: 'm_1517'
        },
        {
          title: 'Femenino 15-17 años',
          field: 'f_1517'
        },
        {
          title: 'Masculino 18-24 años',
          field: 'm_1824'
        },
        {
          title: 'Femenino 18-24 años',
          field: 'f_1824'
        },
        {
          title: 'Masculino 25-29 años',
          field: 'm_2529'
        },
        {
          title: 'Femenino 25-29 años',
          field: 'f_2529'
        },
        {
          title: 'Masculino 30-35 años',
          field: 'm_3035'
        },
        {
          title: 'Femenino 30-35 años',
          field: 'f_3035'
        },
      ],
      IREX: [
        { title: 'Masculino 10-15 años', field: 'm_1015' },
        { title: 'Femenino 10-15 años', field: 'f_1015' },
        { title: 'Masculino 16-20 años', field: 'm_1620' },
        { title: 'Femenino 16-20 años', field: 'f_1620' },
        { title: 'Masculino 21-25 años', field: 'm_2125' },
        { title: 'Femenino 21-25 años', field: 'f_2125' },
        { title: 'Masculino 26-30 años', field: 'm_2630' },
        { title: 'Femenino 26-30 años', field: 'f_2630' },
        { title: 'Masculino 31-35 años', field: 'm_3135' },
        { title: 'Femenino 31-35 años', field: 'f_3135' },
      ],
    }
    return ageColumns[type];
  }
  const columns = [
    {
      title: 'Organización',
      field: 'organizacion'
    },
    {
      title: 'Masculino Total',
      field: 'total_masculino'
    },
    {
      title: 'Femenino Total',
      field: 'total_femenino'
    },
    {
      title: 'Masculino 5-9 años',
      field: 'm_59'
    },
    {
      title: 'Femenino 5-9 años',
      field: 'f_59'
    },
    ...getAgeColumns(type),
    {
      title: 'Masculino +36 años',
      field: 'm_36'
    },
    {
      title: 'Femenino +36 años',
      field: 'f_36'
    },
    {
      title: 'República Dominicana',
      field: 'republica_dominicana'
    },
    {
      title: 'Haití',
      field: 'haiti'
    },
    {
      title: 'Otros',
      field: 'otros_paises'
    },
    { title: 'Con discapacidad', field: 'con_discapacidad' },
    { title: 'Sin discapacidad', field: 'sin_discapacidad' },
    { title: 'No Contestó Discapacidad', field: 'no_contesto_discapacidad' },
    { title: 'Con Vulnerabilidad', field: 'con_vulnerabilidad' },
    { title: 'Sin Vulnerabilidad', field: 'sin_vulnerabilidad' },
    { title: 'Vulnerabilidad no Colectada', field: 'vulnerabilidad_no_colectada' },
    { title: 'No Contestó Vulnerabilidad', field: 'no_contesto_vulnerabilidad' },
    {
      title: 'Total',
      field: 'total'
    }
  ];

  function buildDataObject(data) {
    let copyData = data;

    copyData = copyData.map(x => Object.entries(x).reduce((acc, [key, value]) => {
      const foundColumnItem = columns.find(x => x.field === key);
      if (foundColumnItem) {
        return {
          ...acc,
          [foundColumnItem?.title]: value
        };
      }

      return {
        ...acc
      }

    }, {}));

    if (copyData.length) {
      copyData.push(Object.entries(copyData[0]).reduce((acc, [key, value]) => ({ ...acc, [key]: '' }), {}))
    }


    return [...copyData];
  }


  const mejorEmpleoData = buildDataObject(data.mejor_empleo);
  const emprendimientoMejoradoData = buildDataObject(data.emprendimiento_mejorado);
  const nuevoEmprendimientoData = buildDataObject(data.nuevo_emprendimiento);
  const nuevoEmpleoData = buildDataObject(data.nuevo_empleo);
  const indicadorEmprendimientoData = buildDataObject(data.indicador_emprendimiento);
  const todosData = buildDataObject(data.todos);
  const columnTitles = columns.map(x => x.title);

  const mejorEmpleoCsv = new Parser({ fields: columnTitles }).parse(mejorEmpleoData);
  const emprendimientoMejoradoCsv = new Parser({ fields: columnTitles }).parse(emprendimientoMejoradoData);
  const nuevoEmprendimientoCsv = new Parser({ fields: columnTitles }).parse(nuevoEmprendimientoData);
  const nuevoEmpleoCsv = new Parser({ fields: columnTitles }).parse(nuevoEmpleoData);
  const indicadorEmprendimientoCsv = new Parser({ fields: columnTitles }).parse(indicadorEmprendimientoData);
  const todosCsv = new Parser({ fields: columnTitles }).parse(todosData);

  console.log({ todosCsv })
  const mejorEmpletoContentCsvString = `Mejor Empleo\n${mejorEmpleoCsv}`;
  const emprendimientoMejoradoCsvString = `Emprendimiento Mejorado\n${emprendimientoMejoradoCsv}`;
  const nuevoEmprendimientoCsvString = `Nuevo Emprendimiento\n${nuevoEmprendimientoCsv}`;
  const nuevoEmpleoCsvString = `Nuevo Empleo\n${nuevoEmpleoCsv}`;
  const indicadorEmprendimientoString = `Indicador Emprendimiento\n${indicadorEmprendimientoCsv}`;
  const todosCsvString = `Todos\n${todosCsv}`;


  const csvContent = [todosCsvString,
    nuevoEmpleoCsvString,
    mejorEmpletoContentCsvString,
    nuevoEmprendimientoCsvString,
    emprendimientoMejoradoCsvString,
    indicadorEmprendimientoString
  ].filter(x => x.length).join(`\n\n`);

  const csvWithBom = `\uFEFF${csvContent}`;
  // console.log({ csvContent });
  const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'R20.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

};

export default CsvGenerator;
