import React, { Component } from 'react';
import { Button, Col, Row, Form, Modal, Container, Nav } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import MaterialTable from 'material-table';
import { Formik, Field } from 'formik';
import { withRouter } from "react-router-dom";
import {GET, POST} from '../utils/request.js'
import * as Yup from 'yup';
import Alerts from '../common/Alerts';
import FS from "../components/FieldStructure";
import $ from "jquery";

class Barrios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            popupAddBarrios: false,
            municipalities: [],
            provinces: [],
            alertShow: false,
            alertMessage: "",
            alertType: "",
            barrios:[],
            municipalitiesToSelect: [],
            sectorToSelect:[]
        }
        this.show_PopUpBarrios = this.show_PopUpBarrios.bind(this);
        this.hide_PopUpBarrios = this.hide_PopUpBarrios.bind(this);
    }
    componentDidMount() {
        $(".nav-link").removeClass("active");
        $(".nav-link[href='/administracion]").addClass("active");
        let these = this;
        setTimeout(function () {
            these.setState({ show: true });
        }, 200)
        GET(this, "provinces");
        GET(this, "municipalities");
        GET(this, "sectors");
        GET(this, "barrios");
    }

    show_PopUpBarrios() {
        this.setState({ popupAddBarrios: true });
    }
    hide_PopUpBarrios() {
        this.setState({ popupAddBarrios: false });
    }
    providerSelected(province_id){
        this.state.municipalities.filter(m => {
            if (m.province_id === province_id) {
                this.setState({municipalitiesToSelect: [m]})
            }
        })
    }
    municipalitySelected(municipality_id){
        this.state.sectors.filter(m => {
            if (m.municipality_id === municipality_id) {
                this.setState({sectorToSelect: [m]})
            }
        })
    }
    render() {
        const { barrios, popupAddBarrios, alertMessage, alertShow, alertType, provinces, municipalities, municipalitiesToSelect, sectorToSelect, sectors } = this.state;
        const addMunicipalityValidationSchema = Yup.object().shape({
            name: Yup.string().required("El nombre es un campo requerido.")
        });
        return (
            <Container>
                <div className="titleButtons">
                    <Nav className="justify-content-end">
                        <Alerts these={this} type={alertType} message={alertMessage} show={alertShow} />
                        <Nav.Item><span className="nav-link" onClick={this.show_PopUpBarrios} >Crear Barrio</span></Nav.Item>
                    </Nav>
                </div>
                <CSSTransition unmountOnExit in={this.state.show} timeout={200} classNames="transitionPage">
                    <div className="mainBox">
                        <MaterialTable
                            title=""
                            columns={[
                                { title: 'id', hidden: 'true', defaultSort: 'desc', field: '_id' },
                                { title: 'Nombre', field: 'name' },
                                {
                                    title: 'Provincia', field: 'province_id',
                                    render: rowData => findName(rowData.province_id, provinces)
                                },
                                {
                                    title: 'Municipio', field: 'municipality_id',
                                    render: rowData => findName(rowData.municipality_id, municipalities)
                                },
                                {
                                    title: 'Sector', field: 'sector_id',
                                    render: rowData => findName(rowData.sector_id, sectors)
                                }
                            ]}
                            localization={{
                                toolbar: {
                                    searchTooltip: 'Buscar',
                                    searchPlaceholder: 'Buscar'
                                },
                                pagination: {
                                    previousTooltip: 'Página anterior',
                                    nextTooltip: 'Página siguiente',
                                    firstTooltip: 'Primera página',
                                    lastTooltip: 'Última página'
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay registros que mostrar',
                                }
                            }}
                            options={{
                                search: true,
                                selection: false,
                                searchFieldAlignment: "left",
                                paging: false,
                                pageSize: 20,
                                draggable: false,
                            }}
                            data={barrios}
                        />
                    </div>
                </CSSTransition>
                <Modal centered show={popupAddBarrios} onHide={this.hide_PopUpBarrios}>
                    <Modal.Header closeButton>
                        <Modal.Title>Crear Barrio</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                name: "",
                            }}
                            validationSchema={addMunicipalityValidationSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                POST(this, "barrios", values, "El barrio fue creado con éxito");
                                this.hide_PopUpBarrios();
                                resetForm();
                                setSubmitting(false);
                            }}
                        >
                            {({ values, handleBlur, handleSubmit, isSubmitting, errors }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <FS label="Nombre" name="name" errors={errors} col={[12]}>
                                            <Field type="text" name="name" />
                                        </FS>
                                        <FS label="Provincia" name="province_id" errors={errors} col={[12]}>
                                            <Field 
                                            onBlur={e => {
                                                handleBlur(e);
                                                this.providerSelected(values.province_id)}
                                              } name="province_id" as="select">
                                                <option value="null"> Selecciona </option>
                                                {provinces.map((p) =>
                                                    <option value={p._id}> {p.name}</option>
                                                )}
                                            </Field>
                                        </FS>
                                        <FS label="Municipio" name="municipality_id" errors={errors} col={[12]}>
                                            <Field name="municipality_id"
                                            onBlur={e => {
                                                handleBlur(e);
                                                this.municipalitySelected(values.municipality_id)}
                                              }
                                               as="select">
                                                <option value="null"> Selecciona </option>
                                                {municipalitiesToSelect.map((p) =>
                                                    <option value={p._id}> {p.name}</option>
                                                )}
                                            </Field>
                                        </FS>
                                        <FS label="Sector" name="sector_id" errors={errors} col={[12]}>
                                            <Field name="sector_id" as="select">
                                                <option value="null"> Selecciona </option>
                                                {sectorToSelect.map((p) =>
                                                    <option value={p._id}> {p.name}</option>
                                                )}
                                            </Field>
                                        </FS>
                                        <Col lg="12">
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                variant="primary "
                                            >
                                                {isSubmitting ? "Creando..." : "Crear"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}
function findName(id, data) {
    let name = "";
    data.filter(n => {
        if (n._id === id) {
            name = n.name;
        }
    })
    return name;
}

export default withRouter(Barrios);