import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class SectorService {
  query(municipalityId, status) {
    const params = { municipio: municipalityId };
    if (status) {
      params.estado = status;
    }
    return req.get(`${API_URL}sectores`, { params });
  }
}

const service = new SectorService();

export default service;
