import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class ActivityService {
  query(params) {
    return req.get(`${API_URL}actividades/buscar`, { params });
  }

  save(actividad) {
    return req.post(`${API_URL}actividades/store`, {
      ...actividad,
    });
  }

  update(actividad) {
    const { id } = actividad;
    if (!id) {
      return;
    }
    return req.post(`${API_URL}actividades/${id}/update`, {
      ...actividad,
    });
  }

  find(id) {
    return req.get(`${API_URL}actividades/${id}/show`);
  }

  toggle(id) {
    return req.post(`${API_URL}actividades/${id}/toggle`);
  }

  close(id) {
    return req.post(`${API_URL}actividades/${id}/cerrar`);
  }
}

const service = new ActivityService();

export default service;
