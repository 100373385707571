import { Modal } from 'react-bootstrap';

export function ConfirmationDialog({ show, children, onHide, onConfirm }) {
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <button className='btn btn-primary' onClick={onConfirm}>
          Si
        </button>
        <button className='btn btn-danger' onClick={onHide}>
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationDialog;
