import { API_URL } from '../utils/config';
import { req } from '../utils/request';

class MunicipalityService {
  query(provinceId, status) {
    const params = { provincia: provinceId };
    if (status) {
      params.estado = status;
    }
    return req.get(`${API_URL}municipios`, { params });
  }
}

const service = new MunicipalityService();

export default service;
