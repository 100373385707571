import { ErrorMessage, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { object, string } from 'yup';
import Alert from '../common/Alert';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';

export const Profile = () => {
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    type: 'success',
  });
  const [profileInfo, setProfileInfo] = useState({});
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const initialValues = {
    nombre: profileInfo.profile?.nombre,
    telefono: profileInfo.profile?.telefono,
    email: profileInfo.profile?.email,
    username: profileInfo.username,
    organizacion: profileInfo.organizacion?.nombre,
    role: profileInfo.role?.nombre,
  };

  const validationSchema = object().shape({
    nombre: string().required('Nombre es un campo requerido'),
    email: string().required('Correo electrónico es un campo requerido'),
    username: string().required('Nombre de usuario es un campo requerido'),
    telefono: string().nullable(),
    movil: string().nullable(),
  });

  useEffect(() => {
    req.get(`${API_URL}profile`).then((response) => {
      const data = response.data.data;
      setProfileInfo(data);
      setFormData({
        nombre: data.profile?.nombre,
        telefono: data.profile?.telefono,
        email: data.profile?.email,
        username: data.username,
        organizacion: data.organizacion?.nombre,
        role: data.role?.nombre,
      });
      setIsLoading(false);
    });
  }, []);

  const saveData = (values, { setSubmitting }) => {
    const { username, email, nombre, telefono } = formData;
    req
      .post(`${API_URL}profile/update`, { username, email, nombre, telefono })
      .then(() => {
        setAlert({
          show: true,
          message: 'Perfil actualizado.',
          type: 'success',
        });
        setTimeout(() => {
          setAlert({ show: false, message: '', type: 'success' });
        }, 5000);
      })
      .catch((errorResponse) => {
        const message = errorResponse.response.data.message;
        setAlert({
          show: true,
          type: 'text-danger',
          message,
        });
        setTimeout(() => {
          setAlert({ show: false, message: '', type: 'success' });
        }, 5000);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className='container'>
      <div className='titleButtons'>
        <div className='nav justify-content-end'>
          <Alert {...alert} />
          <div className='nav-item'>
            <Link className='nav-link' to='/cambiar-contraseña'>
              Cambiar contraseña
            </Link>
          </div>
        </div>
      </div>
      <div className='mainBox'>
        <SpinnerPlaceholder isLoading={isLoading}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={saveData}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              handleReset,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} onReset={handleReset}>
                <div className='row'>
                  <div className='col-lg-4'>
                    <div className='form-group'>
                      <label className='form-label'>Nombre</label>
                      <input
                        type='text'
                        name='nombre'
                        value={formData.nombre}
                        className='form-control'
                        onChange={(e) => {
                          setFieldValue('nombre', e.target.value);
                          setFormData((prev) => ({
                            ...prev,
                            nombre: e.target.value,
                          }));
                        }}
                        onBlur={handleBlur}
                      ></input>
                    </div>
                    <ErrorMessage
                      name='nombre'
                      component='p'
                      className='small text-danger'
                    />
                  </div>

                  <div className='col-lg-4'>
                    <div className='form-group'>
                      <label className='form-label'>Teléfono</label>
                      <input
                        type='text'
                        name='telefono'
                        value={formData.telefono}
                        className='form-control'
                        onChange={(e) => {
                          setFieldValue('telefono', e.target.value);
                          setFormData((prev) => ({
                            ...prev,
                            telefono: e.target.value,
                          }));
                        }}
                        onBlur={handleBlur}
                      ></input>
                    </div>
                    <ErrorMessage
                      name='telefono'
                      component='p'
                      className='small text-danger'
                    />
                  </div>

                  <div className='col-lg-4'>
                    <div className='form-group'>
                      <label className='form-label'>Correo electrónico</label>
                      <input
                        type='text'
                        name='email'
                        value={formData.email}
                        className='form-control'
                        readOnly
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                    </div>
                    <ErrorMessage
                      name='email'
                      component='p'
                      className='small text-danger'
                    />
                  </div>

                  <div className='col-lg-4'>
                    <div className='form-group'>
                      <label className='form-label'>Nombre de usuario</label>
                      <input
                        type='text'
                        name='username'
                        readOnly
                        value={formData.username}
                        className='form-control'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                    </div>
                    <ErrorMessage
                      name='username'
                      component='p'
                      className='small text-danger'
                    />
                  </div>

                  <div className='col-lg-4'>
                    <div className='form-group'>
                      <label className='form-label'>Organización</label>
                      <input
                        type='text'
                        name='organizacion'
                        readOnly
                        value={formData.organizacion}
                        className='form-control'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                    </div>
                    <ErrorMessage
                      name='organizacion'
                      component='p'
                      className='small text-danger'
                    />
                  </div>

                  <div className='col-lg-4'>
                    <div className='form-group'>
                      <label className='form-label'>Rol</label>
                      <input
                        type='text'
                        name='role'
                        readOnly
                        value={formData.role}
                        className='form-control'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                    </div>
                    <ErrorMessage
                      name='role'
                      component='p'
                      className='small text-danger'
                    />
                  </div>
                </div>
                <button type='submit' className='btn btn-primary buttonSubmit'>
                  {isSubmitting ? 'Guardando...' : 'Guardar'}
                </button>
              </form>
            )}
          </Formik>
        </SpinnerPlaceholder>
      </div>
    </div>
  );
};

export default withRouter(Profile);
