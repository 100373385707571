import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  colateral: Yup.string(),
  registro: Yup.string().nullable().required(
    "La fecha de registro es un campo requerido"
  ),
  organizacion: Yup.string().required("Organización es un campo requerido"),
  // vulnerabilidad: Yup.mixed().required().test('validation', 'Este campo es requerido', value => {
  //   console.log({ value })
  //   // return ['1', '2', ''].includes(value) || 'Este campo es requerido';
  //   return !['1', '2', ''].includes(value)
  // }),
  nombres: Yup.string().required("Nombres es un campo requerido"),
  apellidos: Yup.string().required("Apellidos es un campo requerido"),
  // pais: Yup.string().nullable().required('País es un campo requerido'),
  provincia: Yup.string().nullable().required("Provincia es un campo requerido"),
  municipio: Yup.string().nullable().required("Municipio es un campo requerido"),
  sector: Yup.string().nullable().required("Sector es un campo requerido"),
  direccion: Yup.string().when("colateral", {
    is: "directo",
    then: Yup.string(),
  }),
  fecha_nacimiento: Yup.string().nullable().when("tipo", {
    is: "1",
    then: Yup.string().required("Fecha de nacimiento es un campo requerido"),
  }),
  pais_nacimiento: Yup.string().nullable().when('tipo', {
    is: '1',
    then: Yup.string().required('País de nacimiento es un campo requerido'),
  }),
  genero: Yup.string().required("Sexo es un campo requerido"),
  // genero_particular: Yup.string().required("Género es un campo requerido"),
  if_mobile_phone: Yup.string().nullable().required("Es un campo requerido"),
  movil: Yup.string().when("if_mobile_phone", {
    is: "si",
    then: Yup.string().required("El Número de teléfono es un campo requerido"),
  }),
  if_facebook: Yup.string().when("tipo", {
    is: "1",
    then: Yup.string().nullable().required("Es un campo requerido"),
  }),
  facebook: Yup.string().when("tipo", {
    is: "1",
    then: Yup.string().when("if_facebook", {
      is: "si",
      then: Yup.string().required("El usuario Facebook es un campo requerido"),
    }),
  }),
  if_email: Yup.string().nullable().required("Es un campo requerido"),
});
