import { ErrorMessage, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { array, object, string, mixed } from 'yup';
import DatePickerField from '../common/DatePicker';
import SecuredComponent from '../components/SecuredComponent';
import { convertDate } from '../poll/PollQuestion';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import { saveReportData } from './ReportDataService';
import MultiSelect from '../components/MultiSelect';

export function ModalBeneficiariosDeSalida19({
  show,
  onHide,
  title,
  reportNo,
  organizaciones,
  tipoActividades
}) {
  const history = useHistory();

  const initialValues = {
    estado_participante: '',
    tipo_participantes: {
      label: 'Directo',
      value: '1'
    }, // Sólo directos
    tipo_formulario: '3', // Salida a Participantes
    organizaciones: [],
    fecha_inicio: '',
    fecha_final: '',
  };

  const validationSchema = object().shape({
    estado_participante: string().nullable(),
    tipo_participantes: mixed().nullable().required('Tipo de Participantes es un campo requerido.'),
    tipo_formulario: string().nullable(),
    fecha_inicio: string()
      .nullable()
      .required('Fecha inicial es un campo requerido.'),
    fecha_final: string()
      .nullable()
      .required('Fecha final es un campo requerido.'),
    organizaciones: array()
      .of(object())
      .min(1, 'Organizaciones es un campo requerido.'),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {};
    for (let key in values) {
      if (key === 'fecha_inicio' || key === 'fecha_final') {
        params[key] = convertDate(values[key]);
      } else {
        params[key] = values[key];
      }
    }
    params.organizaciones = values.organizaciones
      .map((elem) => `${elem.value}`)
      .join(`,`);

    params.tipos_actividad = values.tipos_actividad
      .map((elem) => `${elem.value}`)
      .join(`,`);


    params.tipo_participantes = values.tipo_participantes.value;

    params.estado_participante = 'activos';
    params.estado_asistencia = 'activos';



    // const obj = {
    //   '': 'fecha_inicio',
    //   'finalizadas': 'fecha_final',
    //   'no_finalizadas': 'fecha_final'
    // }

    params.filtro_fechas = 'fecha_final';

    req.get(`${API_URL}reportes/${reportNo}`, { params }).then((response) => {
      setSubmitting(false);
      saveReportData(response.data.data);
      history.push(`/reportes/${reportNo}`);
    });
  };

  const tipoParticipantesOptions = [
    {
      label: 'Ambos',
      value: '1,2'
    },
    {
      label: 'Directo',
      value: '1'
    },
    {
      label: 'Indirecto',
      value: '2'
    }
  ]
  return (
    <Modal size='lg' centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleChange, handleBlur, setFieldValue, values }) => (
          <Form>
            <Modal.Body>
              <SecuredComponent roles={[]}>
                <div className='form-group'>
                  <label className='form-label'>* Fecha inicio creación</label>
                  <DatePickerField name='fecha_inicio' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_inicio'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>* Fecha fin creación</label>
                  <DatePickerField name='fecha_final' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_final'
                    component='p'
                  />
                </div>

                <div className='form-group'>
                  <label className='form-label'>* Organizaciones</label>
                  <MultiSelect
                    name='organizaciones'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('organizaciones', e);
                    }}
                    options={organizaciones.map((org) => ({
                      label: org.nombre,
                      value: org.id,
                    }))}
                  />
                  <ErrorMessage
                    component='p'
                    name='organizaciones'
                    className='small text-danger'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Estado participantes</label>
                  <select
                    name='estado_participante'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='activos'>Activos</option>
                    <option value=''>Ambos</option>
                    <option value='inactivos'>Inactivos</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='estado_participante'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Tipo de Actividad</label>
                  <MultiSelect
                    name='tipos_actividad'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('tipos_actividad', e);
                    }}
                    options={tipoActividades.map((elem) => ({
                      label: elem.nombre,
                      value: elem.id,
                    }))}
                  ></MultiSelect>
                  <ErrorMessage
                    component='p'
                    name='tipos_actividad'
                    className='small text-danger'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Tipo de Participantes</label>
                  <MultiSelect
                    value={values.tipo_participantes}
                    name='tipo_participantes'
                    onChange={(e) => {
                      const foundItem = tipoParticipantesOptions.find(x => x.value === e);
                      setFieldValue('tipo_participantes', foundItem);
                    }}
                    options={tipoParticipantesOptions}
                  />
                  <ErrorMessage
                    component='p'
                    name='tipo_participantes'
                    className='small text-danger'
                  />
                </div>
              </SecuredComponent>
            </Modal.Body>

            <Modal.Footer>
              <button
                type='submit'
                className='buttonSubmit'
                disabled={isSubmitting}
              >
                Buscar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalBeneficiariosDeSalida19;
