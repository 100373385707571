import { differenceInYears, format, parse, parseISO } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import { changeDateFormat } from '../components/functions';

export default function DatosPersonales({ beneficiario }) {
  const fecha_nacimiento = parse(
    beneficiario.fecha_nacimiento,
    'yyyy-MM-dd',
    new Date()
  );
  const fecha_registro = parse(beneficiario.registro, 'yyyy-MM-dd', new Date());
  beneficiario.edad_actual = differenceInYears(new Date(), fecha_nacimiento);
  beneficiario.edad_registro = differenceInYears(
    fecha_registro,
    fecha_nacimiento
  );
  return (
    <div className='card beneficiariesDetail'>
      <div className='card-body'>
        <h5 className='card-title'>Datos personales</h5>
        <Row>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>UUID: </strong> {beneficiario.codigo}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Nombre: </strong> {beneficiario.nombres}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Apellido: </strong> {beneficiario.apellidos}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Teléfono: </strong> {beneficiario.telefono || 'N/A'}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div>
              <p style={{ textTransform: 'capitalize' }}>
                <strong>Tipo: </strong> {beneficiario.tipo?.nombre}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div>
              <p style={{ textTransform: 'capitalize' }}>
                <strong>Sexo: </strong> {beneficiario.genero}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Nacimiento: </strong>{' '}
                {changeDateFormat(beneficiario.fecha_nacimiento)}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Edad actual: </strong> {beneficiario.edad_actual}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Edad durante registro: </strong>{' '}
                {beneficiario.edad_registro}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Fecha de Registro: </strong>{' '}
                {changeDateFormat(beneficiario.registro)}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Provincia: </strong> {beneficiario.provincia?.nombre}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Municipio: </strong> {beneficiario.municipio?.nombre}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Sector: </strong> {beneficiario.sector?.nombre}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Organización: </strong>{' '}
                {beneficiario.organizacion?.nombre}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>Creado en el sistema: </strong>{' '}
                {format(
                  parseISO(beneficiario.creado),
                  'dd/MM/yyyy hh:mm:ss a',
                  new Date()
                )}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>¿Discapacidad?: </strong> {beneficiario.discapacidad?.nombre || 'Dato no recolectado'}
              </p>
            </div>
          </Col>
          <Col lg='3'>
            <div className='beneficiariesDetailContent'>
              <p>
                <strong>¿Forma parte de algún grupo excluido o vulnerable?: </strong> {beneficiario.vulnerabilidad?.nombre || 'Dato no recolectado'}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
