import React, { Component } from "react";
import { Button, Col, Row, Form, Container } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { withRouter } from "react-router-dom";
import { Formik, Field } from "formik";
import FS from "../components/FieldStructure";
import {
  Link
} from "react-router-dom";
import AuthService from "../services/auth.service";
import { ROLE_USUARIO_CONSULTA } from '../utils/roles.js';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error: false,
      message: ""
    };
  }
  componentWillMount(){
    const auth = AuthService.getCurrentUser();
    if(auth){
        if(auth.user.role.nombre === ROLE_USUARIO_CONSULTA){
            this.props.history.push("/reportes");
        }else{
            this.props.history.push("/beneficiarios");
        }
    }
  }
  componentDidMount() {

    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);
  }

  render() {
    return (
      <Container>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <Row>
            <Col lg="3">
            </Col>
            <Col lg="6">
              <div className="mainBox" style={{ padding: "4.5%" }}>
                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                  }}
                  enableReinitialize={true}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    values.is_enabled = 1;
                    setSubmitting(true);
                    AuthService.login(values.username, values.password).then(
                      (data) => {
                        if (data.data.user.role.nombre === ROLE_USUARIO_CONSULTA) {
                          this.props.history.push("/reportes");
                        } else {
                          this.props.history.push("/beneficiarios");
                        }
                      },
                      error => {
                        const resMessage =
                          (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                          error.message ||
                          error.toString();
                        this.setState({
                          error: true,
                          message: resMessage
                        });
                      }
                    );
                    setSubmitting(false);
                  }}
                >
                  {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg="12">
                          <h3 className="title">Iniciar Sesión</h3> <br />
                          <FS
                            label="Correo"
                            name="username"
                            errors={formik.errors}
                            col={[12]}
                          >
                            <Field type="text" name="username" />
                          </FS>
                          <FS
                            label="Contraseña"
                            name="password"
                            errors={formik.errors}
                            col={[12]}
                          >
                            <Field type="password" name="password" />
                          </FS>
                          {this.state.error !== "" ? (
                            <p class="errorFieldForm">{this.state.message}</p>
                          ) : null}
                          <Link style={{ textAlign: "right", display: "block" }} to="/olvido_contrasena"><span>¿Olvidaste tu contraseña?</span></Link>
                          <Button
                            type="submit"
                            className="buttonSubmit"
                            variant="primary "
                          >Ingresar</Button>

                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
            <Col lg="3">
            </Col>
          </Row>
        </CSSTransition>
      </Container>
    );
  }
}
export default withRouter(Login);