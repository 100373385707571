import { ErrorMessage, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { array, object, string } from 'yup';
import DatePickerField from '../common/DatePicker';
import MultiSelect from '../components/MultiSelect';
import SecuredComponent from '../components/SecuredComponent';
import { convertDate } from '../poll/PollQuestion';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import { saveReportData } from './ReportDataService.js';

export function ModalReporte4({
  show,
  onHide,
  organizaciones,
  formularios,
  title,
  reportNo,

}) {
  const history = useHistory();

  const initialValues = {
    fecha_inicio: '',
    fecha_final: '',
    organizaciones: [],
    formularios: [],
    tipo_participantes: 'both', // ambos directo e indirecto
    finalizado: 'finalizadas', // siempre finalizadas
    estado: 'no_canceladas', // siempre no canceladas
  };

  const validationSchema = object().shape({
    fecha_inicio: string()
      .nullable()
      .required('Fecha inicial es un campo requerido.'),
    fecha_final: string()
      .nullable()
      .required('Fecha final es un campo requerido.'),
    organizaciones: array()
      .of(object())
      .min(1, 'Organizaciones es un campo requerido.'),
    formularios: array()
      .of(object())
      .min(1, 'Formularios es un campo requerido.'),
    tipo_participantes: array()
      .of(object()).nullable(),

    finalizado: string().nullable(),
    estado: string().nullable(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {};
    for (let key in values) {
      if (values[key]) {
        if (key === 'fecha_inicio' || key === 'fecha_final') {
          params[key] = convertDate(values[key]);
        } else {
          params[key] = values[key];
        }
      }
    }
    params.organizaciones = values.organizaciones
      .map((elem) => `${elem.value}`)
      .join(`,`);
    params.formularios = values.formularios
      .map((elem) => `${elem.value}`)
      .join(`,`);

    console.log({ miami: params.tipo_participantes })
    if (params.tipo_participantes === 'both') {
      params.tipo_participantes = '1,2'
    } else if (!params.tipo_participantes) {
      delete params.tipo_participantes;
    }

    req.get(`${API_URL}reportes/${reportNo}`, { params }).then((response) => {
      setSubmitting(false);
      saveReportData(response.data.data);
      history.push(`/reportes/${reportNo}`);
    });
  };

  return (
    <Modal size='lg' centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          handleChange,
          handleBlur,
          setFieldValue,
          values
        }) => (
          <Form>
            {console.log({ dirty, isValid, values: values })}
            <Modal.Body>
              <SecuredComponent roles={[]}>
                {console.log({ indi: values.tipo_participantes })}
                <div className='form-group'>
                  <label className='form-label'>
                    * Fecha inicio llenado de encuesta
                  </label>
                  <DatePickerField name='fecha_inicio' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_inicio'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>
                    * Fecha fin llenado de encuesta
                  </label>
                  <DatePickerField name='fecha_final' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_final'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>* Organizaciones</label>
                  <MultiSelect
                    name='organizaciones'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('organizaciones', e);
                    }}
                    options={organizaciones.map((org) => ({
                      label: org.nombre,
                      value: org.id,
                    }))}
                  />
                  <ErrorMessage
                    component='p'
                    name='organizaciones'
                    className='small text-danger'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>* Formularios</label>
                  <MultiSelect
                    name='formularios'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('formularios', e);
                    }}
                    options={formularios.map((org) => ({
                      label: org.nombre,
                      value: org.id,
                    }))}
                  />
                  <ErrorMessage
                    component='p'
                    name='formularios'
                    className='small text-danger'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Tipo de participantes</label>
                  <select
                    name='tipo_participantes'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='both'>Ambos</option>
                    <option value='1'>Directo</option>
                    <option value='2'>Indirecto</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='tipo_participantes'
                    component='p'
                  />
                </div>
                {/* <div className='form-group'>
                  <label className='form-label'>Tipo de participante</label>
                  <MultiSelect
                    value={{ label: 'Ambos', value: "both" }}
                    name='tipo_participantes'
                    onChange={(...e) => {
                      console.log(...e)
                      setFieldValue('tipo_participantes', e);
                    }}
                    options={[
                      { label: 'Ambos', value: "both" },
                      { label: 'Directo', value: 1 },
                      { label: 'Indirecto', value: 2 }
                    ]}
                  />
                  <ErrorMessage
                    component='p'
                    name='tipo_participantes'
                    className='small text-danger'
                  />
                </div> */}
              </SecuredComponent>
            </Modal.Body>

            <Modal.Footer>
              <button
                type='submit'
                className='buttonSubmit'
                disabled={isSubmitting || !dirty || !isValid}
              >
                Buscar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalReporte4;
