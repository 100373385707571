import React, { Component, createRef } from "react";
import { Button, Col, Row, Form, Modal, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { req } from "../utils/request.js";
import Alerts from "../common/Alerts.js";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { checkStatus } from "../components/functions.js";
import Audits from '../components/Audits.js';

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import programService from '../services/program.js'
import { API_URL } from '../utils/config.js';

class Programs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      popupAddProgram: false,
      programstype: [],
      editingData: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      auditType: "programas",
      showAudit: null
    };
    this.tableRef = createRef();
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);

    this.show_PopUpProgram = this.show_PopUpProgram.bind(this);
    this.hide_PopUpProgram = this.hide_PopUpProgram.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }
  componentDidMount() {

    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.administracion .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.programas").addClass("active");
    });

    req.get(`${API_URL}tipos/programa`, {
      params: {
        estado: 'activos',
        paginacion: 999999
      }
    }).then(response => {
      this.setState({ tipos: response.data.data ?? [] });
    });

    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);
  }

  show_PopUpProgram(data) {
    this.setState({ popupAddProgram: true, editingData: data });
  }
  hide_PopUpProgram() {
    this.setState({ popupAddProgram: false });
  }

  editRow(data) {
    this.show_PopUpProgram(data);
  }
  showAudit(id) {
    this.setState({
      showAudit: id
    })
  }

  desactivateRow(data) {
    programService.toggle(data.id).then(() => {
      data.activo = false;
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El programa ha sido desactivado con éxito',
      });
    });
  }

  activeRow(data) {
    programService.toggle(data.id).then(() => {
      data.activo = true;
      this.setState({
        alertShow: true,
        alertType: 'success',
        alertMessage: 'El programa ha sido activado con éxito',
      });
    });
  }

  closeAudit() {
    this.setState({
      showAudit: null
    })
  }

  fetchData(query) {
    return new Promise(resolve => {
      const params = {
        page: query.page + 1,
        paginacion: this.tableRef.current.dataManager.pageSize,
      };
      if (query.search) {
        params.busqueda = query.search;
      }
      programService.query(params).then(response => {
        resolve({
          data: response.data.data ?? [],
          page: response.data.meta.current_page - 1,
          totalCount: response.data.meta.total
        });
      });
    });
  }

  render() {
    const {
      popupAddProgram,
      editingData,
      alertMessage,
      alertShow,
      alertType,
      tipos,
    } = this.state;
    const addProgramValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
      descripcion: Yup.string().nullable().notRequired(),
      cantidad: Yup.number().required('Cantidad es un campo requerido.'),
      tipo: Yup.number().required('Tipo es un campo requerido'),
    });
    return (
      <Container>
        {this.state.showAudit ? (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        ) : null}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <span className="nav-link" onClick={this.show_PopUpProgram}>
                Crear Programa
              </span>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              tableRef={this.tableRef}
              columns={[
                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "nombre" },
                { title: "Descripción", field: "descripcion" },
                { title: 'Tipo', field: 'tipo.nombre' },
                { title: 'Cantidad', field: 'cantidad' },
                {
                  title: "Estatus", field: "activo",
                  render: rowData => (
                    checkStatus(rowData.activo)
                  )
                }
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "right",
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                draggable: false
              }}
              data={this.fetchData}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, rowData) => this.editRow(rowData)
                },
                rowData => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: 'Desactivar',
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: rowData.activo === false
                }),
                rowData => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: 'Activar',
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.showAudit(rowData.id)
                })
              ]}
            />
          </div>
        </CSSTransition>
        <Modal
          centered
          show={popupAddProgram}
          onHide={this.hide_PopUpProgram}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Programa</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                nombre: editingData.nombre ?? '',
                descripcion: editingData.descripcion ?? '',
                cantidad: editingData.cantidad ?? '',
                tipo: editingData.tipo?.id ?? ''
              }}
              validationSchema={addProgramValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                this.hide_PopUpProgram();
                if (editingData.id !== undefined) {
                  programService.update({ ...values, tipo_programa: values.tipo, id: editingData.id }).then(() => {
                    this.setState({
                      alertShow: true,
                      alertType: 'success',
                      alertMessage: 'El programa ha sido actualizada con éxito'
                    });
                    this.tableRef.current.onQueryChange({});
                  });
                } else {
                  programService.save({ ...values, tipo_programa: values.tipo }).then(() => {
                    this.setState({
                      alertShow: true,
                      alertType: 'success',
                      alertMessage: 'El programa fue creado con éxito'
                    });
                    this.tableRef.current.onQueryChange({});
                  });
                }
                this.hide_PopUpProgram();
                resetForm();
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <div className="form-group col-lg-6">
                      <label>Nombre</label>
                      <Field type="text" name="nombre" />
                      <ErrorMessage
                        className="errorField"
                        name="nombre"
                        component="p"
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label>Descripción</label>
                      <Field type="text" name="descripcion" />
                      <ErrorMessage
                        className="errorField"
                        name="descripcion"
                        component="p"
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label>Cantidad</label>
                      <Field type="number" name="cantidad" />
                      <ErrorMessage
                        className="errorField"
                        name="cantidad"
                        component="p"
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label>Tipo</label>
                      <Field name="tipo" as='select'>
                        <option value=''>Selecciona</option>
                        {
                          tipos.map(type => (
                            <option key={type.id} value={type.id}>{type.nombre}</option>
                          ))
                        }
                      </Field>
                      <ErrorMessage
                        className="errorField"
                        name="tipo"
                        component="p"
                      />
                    </div>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="primary "
                        >
                          {isSubmitting ? "Guardando..." : "Guardar"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
export default withRouter(Programs);