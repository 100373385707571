import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { object, string } from 'yup';
import { initialAlert } from '../common/Alert';
import MultiSelect from '../components/MultiSelect';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';

function ProvinciasUpdate(props) {
  const [isLoading, setLoading] = useState(true);
  const [provincia, setProvincia] = useState({});
  const [paises, setPaises] = useState([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState();
  const [alert, setAlert] = useState(initialAlert);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert((prev) => ({ ...prev, show: false }));
    }, alert.timeout ?? 5000);
    return () => clearTimeout(timer);
  }, [alert.show, alert.timeout]);

  const { id } = useParams();

  useEffect(() => {
    req
      .get(`${API_URL}paises`, { params: { estado: 'activos' } })
      .then((response) => {
        setPaises(response.data.data);
      });
    if (!id) {
      setLoading(false);
      return;
    }
    req.get(`${API_URL}provincias/${id}/show`).then((response) => {
      setProvincia({ ...response.data.data, pais: response.data.data.pais_id });
      setPaisSeleccionado(response.data.data.pais_id);
      setLoading(false);
    });
  }, [id]);

  const onSubmit = ({ pais, nombre }, { setSubmitting }) => {
    const isNew = !id;
    if (isNew) {
      req
        .post(`${API_URL}provincias/store`, {
          nombre,
          pais,
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((err) => {
          const message = err.response.data.message;
          setAlert({
            show: true,
            type: 'text-danger',
            message,
          });
        })
        .finally(() => setSubmitting(false));
    } else {
      req
        .post(`${API_URL}provincias/${id}/update`, {
          nombre,
          pais,
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((err) => {
          const message = err.response.data.message;
          setAlert({
            show: true,
            type: 'text-danger',
            message,
          });
        })
        .finally(() => setSubmitting(false));
    }
  };

  const validationSchema = object().shape({
    nombre: string().required('Nombre es un campo requerido.'),
    pais: string().required('País es un campo requerido'),
  });

  const { nombre, pais } = provincia;

  const opcionesPaises = [
    { label: 'Selecciona', value: null },
    ...paises.map((p) => ({ label: p.nombre_espanol, value: p.id })),
  ];
  const opcionPaisSeleccionado = opcionesPaises.find(
    (p) => p.value === paisSeleccionado
  );

  return (
    <div className='container'>
      <div className='mainBox'>
        <SpinnerPlaceholder isLoading={isLoading}>
          <Formik
            initialValues={{
              nombre,
              pais,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleBlur, handleChange, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className='row'>
                  <div className='form-group col-4'>
                    <label className='form-label'>Nombre</label>
                    <input
                      type='text'
                      name='nombre'
                      className='form-control'
                      value={nombre}
                      onChange={(e) => {
                        handleChange(e);
                        setProvincia((prev) => ({
                          ...prev,
                          nombre: e.target.value,
                        }));
                      }}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name='nombre'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                  <div className='form-group col-4'>
                    <label className='form-label'>País</label>
                    <MultiSelect
                      name='pais'
                      defaultValue={opcionPaisSeleccionado}
                      value={opcionPaisSeleccionado}
                      required
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange({
                          target: { name: 'pais', value: e },
                        });
                        setPaisSeleccionado(e);
                      }}
                      options={opcionesPaises}
                    />
                    <ErrorMessage
                      name='pais'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button
                    type='submit'
                    className='buttonSubmit'
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    <span>{isSubmitting ? 'Guardando...' : 'Guardar'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </SpinnerPlaceholder>
      </div>
    </div>
  );
}

export default withRouter(ProvinciasUpdate);
