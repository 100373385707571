import { req } from "../utils/request";
import Utils, { API_URL } from "../utils/config";
import { ROLE_SYSTEM_ADMIN } from '../utils/roles';

const API = Utils.API;
class AuthService {
  login(username, password) {
    return req
      .post(API + "login", {
        username,
        password
      },
      )
      .then(response => {
        if (response.headers) {
          localStorage.setItem("user", JSON.stringify(response.data));
          return response;
        }
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  forgetPassword(username, password) {
    return req
      .get(API + "auth/forgot?username=" + username)
      .then(response => {
        if (response.headers) {
          return response;
        }
      });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  isAdmin() {
    const auth = this.getCurrentUser();
    return auth?.user.role.nombre === ROLE_SYSTEM_ADMIN;
  }

  fetchUserRoles() {
    return req.get(`${API_URL}roles`);
  }
}

export const service = new AuthService();

export default service;
