import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { object, string } from 'yup';
import { initialAlert } from '../common/Alert';
import MultiSelect from '../components/MultiSelect';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';

function MunicipiosUpdate(props) {
  const [isLoading, setLoading] = useState(true);
  const [municipio, setMunicipio] = useState({});
  const [provincias, setProvincias] = useState([]);
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState();
  const [alert, setAlert] = useState(initialAlert);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert((prev) => ({ ...prev, show: false }));
    }, alert.timeout ?? 5000);
    return () => clearTimeout(timer);
  }, [alert.show, alert.timeout]);

  const { id } = useParams();

  useEffect(() => {
    req
      .get(`${API_URL}provincias`, {
        params: { estado: 'activos', pais: 62 /* República Dominicana */ },
      })
      .then((response) => {
        setProvincias(response.data.data);
      });

    if (!id) {
      setLoading(false);
      return;
    }
    req.get(`${API_URL}municipios/${id}/show`).then((response) => {
      setMunicipio(response.data.data);
      setProvinciaSeleccionada(response.data.data.provincia_id);
      setLoading(false);
    });
  }, [id]);

  const onSubmit = ({ nombre, provincia }, { setSubmitting }) => {
    const isNew = !id;
    if (isNew) {
      req
        .post(`${API_URL}municipios/store`, {
          nombre,
          provincia,
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((err) => {
          const message = err.response.data.message;
          setAlert({
            show: true,
            type: 'text-danger',
            message,
          });
        })
        .finally(() => setSubmitting(false));
    } else {
      req
        .post(`${API_URL}municipios/${id}/update`, {
          nombre,
          provincia,
        })
        .then(() => props.history.goBack())
        .catch((err) => {
          const message = err.response.data.message;
          setAlert({
            show: true,
            type: 'text-danger',
            message,
          });
        })
        .finally(() => setSubmitting(false));
    }
  };

  const validationSchema = object().shape({
    nombre: string().trim().required('Nombre es un campo requerido.'),
    provincia: string().required('Provincia es un campo requerido.'),
  });

  const { nombre, provincia } = municipio;

  const opcionesProvincias = [
    { label: 'Selecciona', value: null },
    ...provincias.map((p) => ({ label: p.nombre, value: p.id })),
  ];
  const opcionProvinciaSeleccionada = opcionesProvincias.find(
    (op) => op.value === provinciaSeleccionada
  );

  return (
    <div className='container'>
      <div className='mainBox'>
        <SpinnerPlaceholder isLoading={isLoading}>
          <Formik
            initialValues={{
              nombre,
              provincia,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleBlur, handleChange, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className='row'>
                  <div className='form-group col-4'>
                    <label className='form-label'>Nombre</label>
                    <input
                      type='text'
                      name='nombre'
                      className='form-control'
                      value={nombre}
                      onChange={(e) => {
                        handleChange(e);
                        setMunicipio((prev) => ({
                          ...prev,
                          nombre: e.target.value,
                        }));
                      }}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name='nombre'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                  <div className='form-group col-4'>
                    <label className='form-label'>Provincia</label>
                    <MultiSelect
                      name='provincia'
                      defaultValue={opcionProvinciaSeleccionada}
                      value={opcionProvinciaSeleccionada}
                      required
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange({
                          target: { name: 'provincia', value: e },
                        });
                        setMunicipio((prev) => ({
                          ...prev,
                          provincia: e,
                        }));
                      }}
                      options={opcionesProvincias}
                    />
                    <ErrorMessage
                      name='provincia'
                      className='small text-danger'
                      component='p'
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button
                    type='submit'
                    className='buttonSubmit'
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    <span>{isSubmitting ? 'Guardando...' : 'Guardar'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </SpinnerPlaceholder>
      </div>
    </div>
  );
}

export default withRouter(MunicipiosUpdate);
