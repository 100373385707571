import { ErrorMessage, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { array, object, string } from 'yup';
import DatePickerField from '../common/DatePicker';
import MultiSelect from '../components/MultiSelect';
import SecuredComponent from '../components/SecuredComponent';
import { convertDate } from '../poll/PollQuestion';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import { saveReportData } from './ReportDataService';

export function ModalReporteEncuestas2({
  show,
  onHide,
  organizaciones,
  title,
  reportNo,
}) {
  const history = useHistory();

  const initialValues = {
    estado_participante: 'activos',
    tipo_formulario: '1', // Sólo Encuesta de Inscripción a Participantes
    tipo_participantes: '',
    organizaciones: [],
    fecha_inicio: '',
    fecha_final: '',
  };

  const validationSchema = object().shape({
    estado_participante: string().nullable(),
    tipo_formulario: string().nullable(),
    tipo_participantes: string().nullable(),
    fecha_inicio: string()
      .nullable()
      .required('Fecha inicial es un campo requerido.'),
    fecha_final: string()
      .nullable()
      .required('Fecha final es un campo requerido.'),
    organizaciones: array()
      .of(object())
      .min(1, 'Organizaciones es un campo requerido.'),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {};
    for (let key in values) {
      if (key === 'fecha_inicio' || key === 'fecha_final') {
        params[key] = convertDate(values[key]);
      } else {
        params[key] = values[key];
      }
    }
    params.organizaciones = values.organizaciones
      .map((elem) => `${elem.value}`)
      .join(`,`);

    req.get(`${API_URL}reportes/${reportNo}`, { params }).then((response) => {
      setSubmitting(false);
      saveReportData(response.data.data);
      history.push(`/reportes/${reportNo}`);
    });
  };
  return (
    <Modal size='lg' centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <Modal.Body>
              <SecuredComponent roles={[]}>
                <div className='form-group'>
                  <label className='form-label'>* Fecha inicio</label>
                  <DatePickerField name='fecha_inicio' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_inicio'
                    component='p'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>* Fecha fin</label>
                  <DatePickerField name='fecha_final' />
                  <ErrorMessage
                    className='small text-danger'
                    name='fecha_final'
                    component='p'
                  />
                </div>

                <div className='form-group'>
                  <label className='form-label'>* Organizaciones</label>
                  <MultiSelect
                    name='organizaciones'
                    isMulti
                    allowSelectAll
                    onChange={(e) => {
                      setFieldValue('organizaciones', e);
                    }}
                    options={organizaciones.map((org) => ({
                      label: org.nombre,
                      value: org.id,
                    }))}
                  />
                  <ErrorMessage
                    component='p'
                    name='organizaciones'
                    className='small text-danger'
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Tipo participantes</label>
                  <select
                    name='tipo_participantes'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value=''>Ambos</option>
                    <option value='1'>Directo</option>
                    <option value='2'>Indirecto</option>
                  </select>
                  <ErrorMessage
                    className='small text-danger'
                    name='tipo_participantes'
                    component='p'
                  />
                </div>
              </SecuredComponent>
            </Modal.Body>

            <Modal.Footer>
              <button
                type='submit'
                className='buttonSubmit'
                disabled={isSubmitting}
              >
                Buscar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalReporteEncuestas2;
