import { ErrorMessage, Field, Formik } from 'formik';
import MaterialTable from 'material-table';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { object, string } from 'yup';
import { checkStatus } from '../components/functions';
import { API_URL } from '../utils/config.js';
import { req } from '../utils/request.js';
import {
  CheckCircleOutline,
  DoNotDisturbOnOutlined,
  History,
  ModeEditOutlined,
} from '@mui/icons-material';
import Audits from '../components/Audits';

export function ProgramsTypesList(props) {
  const [show, setShow] = useState(false);
  const [auditModal, setAuditModal] = useState(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editingData, setEditingData] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    type: 'success',
    message: '',
    timeout: 7000,
  });
  const [showAlert, setShowAlert] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setShowAlert(true);
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, alert.timeout ?? 7000);
    return () => clearTimeout(timer);
  }, [alert]);

  const fetchData = (query) => {
    return new Promise((resolve) => {
      const params = { page: query.page + 1, paginacion: query.pageSize };
      // TODO implement filter
      if (query.search) {
        params.busqueda = query.search;
      }
      req.get(`${API_URL}tipos/programa`, { params }).then((response) => {
        resolve({
          data: response.data.data ?? [],
          page: response.data.meta.current_page - 1,
          totalCount: response.data.meta.total,
        });
      });
    });
  };

  const onSubmit = (values) => {
    setSubmitting(true);
    const isNew = editingData.id === undefined;
    if (isNew) {
      req.post(`${API_URL}tipos/programa/store`, values).then(() => {
        setSubmitting(false);
        setShowCreateDialog(false);
        tableRef.current.onQueryChange({});
        setAlert((prev) => ({
          ...prev,
          type: 'success',
          message: 'El tipo de programa ha sido creado con éxito',
        }));
      });
    } else {
      req
        .post(`${API_URL}tipos/programa/${editingData.id}/update`, values)
        .then(() => {
          setSubmitting(false);
          setShowCreateDialog(false);
          tableRef.current.onQueryChange({});
          setAlert((prev) => ({
            ...prev,
            type: 'success',
            message: 'El tipo de programa ha sido actualizado con éxito',
          }));
        });
    }
  };

  const activateRow = (rowData) => {
    const { id } = rowData;
    if (id === undefined) {
      return;
    }
    rowData.activo = true;
    req.post(`${API_URL}tipos/programa/${id}/toggle`).then((response) => {
      setAlert({
        type: 'success',
        message: 'El tipo de programa fue activado con éxito',
      });
    });
  };

  const deactivateRow = (rowData) => {
    const { id } = rowData;
    if (id === undefined) {
      return;
    }
    rowData.activo = false;
    req.post(`${API_URL}tipos/programa/${id}/toggle`).then((response) => {
      setAlert({
        type: 'success',
        message: 'El tipo de programa fue desactivado con éxito',
      });
    });
  };

  const validationSchema = object().shape({
    nombre: string().required('El nombre es un campo requerido.'),
  });

  return (
    <div className='container'>
      {auditModal && (
        <Audits
          close={() => setAuditModal(null)}
          show={!!auditModal}
          tipo={'tipos/programa'}
          id={auditModal}
        />
      )}
      <div className={'titleButtons'}>
        <div className='justify-content-end nav'>
          <div className={`alert ${showAlert ? 'show' : ''} ${alert.type}`}>
            <p>{alert.message}</p>
          </div>
          <div className='nav-item'>
            <span
              className='nav-link'
              onClick={() => setShowCreateDialog(true)}
            >
              Crear Tipo de Programa
            </span>
          </div>
        </div>
      </div>

      <CSSTransition
        unmountOnExit
        in={show}
        timeout={200}
        classNames='transitionPage'
      >
        <div className='mainBox'>
          <MaterialTable
            title=''
            tableRef={tableRef}
            columns={[
              { title: 'id', hidden: true, field: 'id', defaultSort: 'desc' },
              { title: 'Nombre', field: 'nombre' },
              {
                title: 'Estatus',
                field: 'activo',
                render: (rowData) => checkStatus(rowData.activo),
              },
            ]}
            localization={{
              header: {
                actions: "Acciones",
              },
              toolbar: {
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar',
              },
              pagination: {
                previousTooltip: 'Página anterior',
                nextTooltip: 'Página siguiente',
                firstTooltip: 'Primera página',
                lastTooltip: 'Última página',
              },
              body: {
                emptyDataSourceMessage: 'No hay registros que mostrar',
              },
            }}
            options={{
              search: true,
              selection: false,
              searchFieldAlignment: 'right',
              actionsColumnIndex: -1,
              paging: true,
              emptyRowsWhenPaging: false,
              pageSize: 20,
              filtering: true,
              draggable: false,
            }}
            data={fetchData}
            actions={[
              {
                icon: ModeEditOutlined,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditingData(rowData);
                  setShowCreateDialog(true);
                },
              },
              (rowData) => ({
                icon: DoNotDisturbOnOutlined,
                tooltip: 'Desactivar',
                onClick: (event, rowData) => deactivateRow(rowData),
                disabled: !rowData.activo,
              }),
              (rowData) => ({
                icon: CheckCircleOutline,
                tooltip: 'Activar',
                onClick: (event, rowData) => activateRow(rowData),
                disabled: rowData.activo,
              }),
              (rowData) => ({
                icon: History,
                tooltip: 'Auditoria',
                onClick: (event, rowData) => setAuditModal(rowData.id),
              }),
            ]}
          />
        </div>
      </CSSTransition>

      <Modal centered={true} show={showCreateDialog}>
        <Modal.Header
          closeButton={true}
          onHide={() => {
            setEditingData({});
            setShowCreateDialog(false);
          }}
        >
          <Modal.Title>Guardar Tipo de Programa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              nombre: editingData.nombre ?? '',
              descripcion: editingData.descripcion ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isValid, dirty }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg='12'>
                    <div className='form-group'>
                      <label>Nombre</label>
                      <Field type='text' name='nombre' />
                      <ErrorMessage
                        className='errorField'
                        name='nombre'
                        component='p'
                      />
                    </div>
                  </Col>
                  <Col lg='12'>
                    <div className='form-group'>
                      <label>Descripción</label>
                      <Field as='textarea' name='descripcion' />
                    </div>
                  </Col>
                  <Col lg='12'>
                    <div className='contButtons'>
                      <Button
                        type='submit'
                        disabled={isSubmitting || !isValid || !dirty}
                        variant='primary'
                      >
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProgramsTypesList;
