export function PollTitle({ participant, pollName }) {
  return (
    <div className='row'>
      <div className='col-lg-6'>
        <p>
          {`Participante: ${participant?.nombres} ${participant?.apellidos} ID: ${participant?.codigo}`}
        </p>
      </div>
      <div className='col-lg-6 text-end'>{pollName}</div>
    </div>
  );
}

export default PollTitle;
