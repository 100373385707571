import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RowOptions.css';
import { Dropdown } from "react-bootstrap";

class RowOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      showList: true
    };
    this.sendBack = this.sendBack.bind(this);
  }
 sendBack(option) {
   this.props.parentCallback(option, this.props);
 }
  render(){
    return (
      <div className={this.props.inline ? "contRowOptions inline" : "contRowOptions"}>
        <Dropdown>
          <Dropdown.Toggle className="btnRowOptions"/>

          <Dropdown.Menu alignright="true" id={this.props.data.id}>
            {this.props.options.map((option, i) => (
                option != null ? (
              <Dropdown.Item key={i} onClick={() => this.sendBack(option)} id={option}>{option}</Dropdown.Item>) : null
            ))}
          </Dropdown.Menu>
        </Dropdown>
    </div>
    )
  }
}

export default RowOptions;
