export function base64ToBlob(dataURI) {
  const { atob } = window;
  const byteString = atob(dataURI.substring(dataURI.indexOf(',') + 1));
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const start = dataURI.indexOf(':') + 1;
  const end = dataURI.indexOf(';');
  const type = dataURI.substring(start, end);
  return new Blob([int8Array], { type });
}

export function download(url, fileName) {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName ? fileName : url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
