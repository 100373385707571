import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import Actividades from './Actividades';
import DatosPersonales from './DatosPersonales';
import FormulariosCompletados from './FormulariosCompletados';
import Participacion from './Participacion';
import ParticipantDocuments from './ParticipantDocuments';

export function BeneficiaryDetail(props) {
  const [isLoading, setLoading] = useState(true);
  const [beneficiario, setBeneficiario] = useState({});
  const [key, setKey] = useState('perfil');

  const beneficiario_id = props.match.params.beneficiario_id;

  useEffect(() => {
    req
      .get(`${API_URL}participantes/${beneficiario_id}/show`)
      .then((response) => {
        setBeneficiario(response.data.data);
        setLoading(false);
      });
  }, [beneficiario_id]);

  return (
    <div className='container'>
      <div className='mainBox'>
        <SpinnerPlaceholder isLoading={isLoading}>
          <Tabs
            id='tabs-detalle-beneficiario'
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className='mb-3'
          >
            <Tab eventKey='perfil' title='Perfil'>
              <DatosPersonales beneficiario={beneficiario} />

              <FormulariosCompletados beneficiario={beneficiario} />
            </Tab>
            <Tab eventKey='programas' title='Participación en Programas'>
              <Participacion beneficiario={beneficiario} />
            </Tab>
            <Tab eventKey='actividades' title='Historial de Actividades'>
              <Actividades beneficiario={beneficiario} />
            </Tab>
            <Tab eventKey='archivos_participante' title='Archivos del Participante'>
              <ParticipantDocuments beneficiarioId={beneficiario_id} />
            </Tab>
          </Tabs>
        </SpinnerPlaceholder>
      </div>
    </div>
  );
}

export default withRouter(BeneficiaryDetail);
