export function SpinnerPlaceholder({ isLoading, children }) {
  return isLoading ? (
    <div className='text-center'>
      <div className='spinner-border text-primary' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  ) : (
    children
  );
}

export default SpinnerPlaceholder;
