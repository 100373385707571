import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import formService from '../services/form';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import CustomDatePicker from './CustomDatePicker.jsx';
import { convertDate } from './PollQuestion.jsx';
import PollSavedDialog from './PollSavedDialog';
import PollSection from './PollSection.jsx';
import PollTitle from './PollTitle.jsx';
import Utils from "../utils/config";
import axios from 'axios';
import { Nav } from 'react-bootstrap';
import Audits from '../components/DynamicAudits';
import { format } from 'date-fns';
import { SIN_DISCAPACIDAD } from '../configurations/Configurations';
import { Formik } from 'formik';

function PollView(props) {
  const [isLoading, setLoading] = useState(true);
  const [form, setForm] = useState({});
  const [fecha_llenado, setFechaLlenado] = useState(new Date());
  const [encuesta, setEncuesta] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [isSaving, setSaving] = useState(false);
  const [showSavedDialog, setShowSavedDialog] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [auditData, setAuditData] = useState([]);
  const [auditModal, setAuditModal] = useState(false);
  const [loadingAuditFetch, setLoadingAuditFetch] = useState(false);
  const [discapacidad, setDiscapacidad] = useState(null);
  const [sinDiscapacidadConfig, setSinDiscapacidadConfig] = useState([]);
  const [asistenciaActividadesToShow, setAsistenciaActividadesToShow] = useState([]);
  // params
  const formId = props.match.params.formId;
  const participantId = props.match.params.participantId;

  useEffect(() => {
    formService.find(formId).then((response) => {
      setForm(response.data.data);
    });
  }, [formId]);

  // fetch data
  useEffect(() => {
    req
      .get(`${API_URL}encuestas`, {
        params: {
          formulario: formId,
          participante: participantId,
          estado: 'no_canceladas',
        },
      })
      .then((response) => {
        // there should be no more than 1
        const encuestas = response.data.data ?? [];
        if (encuestas.length === 0) {
          req
            .post(`${API_URL}encuestas/store`, {
              participante: participantId,
              formulario: formId,
            })
            .then((response) => {
              const data = response.data.data;
              data.secciones
                .sort((a, b) => a.orden - b.orden)
                .forEach((section) =>
                  section.items
                    .sort((a, b) => a.orden - b.orden)
                    .forEach((item) =>
                      item.respuestas.sort((a, b) =>
                        a.respuesta.localeCompare(b)
                      )
                    )
                );
              console.log('1')
              setEncuesta(data);
              const fecha = response.data.data.fecha_llenado;
              setFechaLlenado(fecha ? new Date(fecha + ' 00:00') : null);
              setLoading(false);
            });
        } else {
          const encuestaId = encuestas[0].id;
          req.get(`${API_URL}encuestas/${encuestaId}/show`).then((response) => {
            response.data.data.secciones
              .sort((a, b) => a.orden - b.orden)
              .forEach((section) =>
                section.items
                  .sort((a, b) => a.orden - b.orden)
                  .forEach((item) => {
                    item.respuestas.sort((a, b) =>
                      a.respuesta.localeCompare(b.respuesta)
                    );
                    item.respuestas_participante.forEach((respuesta) => {
                      // console.log({ parsed: JSON.parse(respuesta.respuesta ?? '[]') })
                      const tipo_id = item.tipo_pregunta.id;
                      let value = null;
                      try {
                        value = JSON.parse(respuesta.respuesta ?? '[]');
                      } catch (error) {
                        value = respuesta.respuesta;
                      }

                      const valorRespuesta =
                        tipo_id === 2
                          ? (Array.isArray(value) ? value.map(
                            (valor) => ({ label: valor, value: valor })
                          ) : value)

                          : tipo_id === 1
                            ? {
                              label: respuesta.respuesta,
                              value: respuesta.respuesta,
                            }
                            : respuesta.respuesta;
                      setSelectedAnswers((values) => ({
                        ...values,
                        [`field_${item.id}`]: valorRespuesta,
                      }));
                    });
                  })
              );
            console.log('2')
            setEncuesta(response.data.data);
            const fecha = response.data.data.fecha_llenado;
            setFechaLlenado(fecha ? new Date(fecha + ' 00:00') : null);
            // if this poll is finished, figure out if this is the laboral form and if so, do validations
            if (response.data.data.finalizado) {
              req
                .get(`${API_URL}config`)
                .then((configResponse) => {
                  const configs = configResponse.data.data;
                  const laboralFormConfig = configs.find(
                    (config) => config.key === 'laboralForm'
                  );

                  const sinDiscapacidadConfig = configs.find(
                    (config) => config.key === SIN_DISCAPACIDAD
                  );

                  const sinDiscapacidadConfigParsed = JSON.parse(sinDiscapacidadConfig.value || null);
                  setSinDiscapacidadConfig(sinDiscapacidadConfigParsed)


                  if (!laboralFormConfig) {
                    window.alert(
                      'No existe configuración de encuesta laboral.'
                    );
                    return;
                  }
                  if (
                    laboralFormConfig.value ===
                    response.data.data.formulario_id.toString()
                  ) {
                    const validations = JSON.parse(
                      configs.find(
                        (config) => config.key === 'laboralForm_Validations'
                      )?.value ?? '[]'
                    );

                    const encuestaItems = response.data.data.secciones
                      .map((seccion) => seccion.items)
                      .flat()
                      .map((p) => {
                        const pregunta = p.pregunta;
                        // intentionally taking only the first answer
                        const respuesta =
                          p.respuestas_participante[0]?.respuesta;
                        return { pregunta, respuesta };
                      });

                    const cannotEdit = validations.some(
                      ({ questionName, answerName }) => {
                        const relevantItem = encuestaItems.find(
                          (item) => item.pregunta === questionName
                        );
                        return relevantItem?.respuesta === answerName;
                      }
                    );
                    if (cannotEdit) {
                      window.alert(
                        'Esta encuesta no puede ser editada por contar con un empleo o negocio reportado.'
                      );
                      setCanEdit(false);
                    }
                  }
                })
                .finally(() => setLoading(false));
            } else {
              setLoading(false);
            }
          });
        }
      });



    if (formId === '1') {
      req
        .get(`${API_URL}asistencia/actividades`, {
          params: {
            participante: participantId,
            finalizado: 'finalizadas',
            estado: 'activos',
            paginacion: 2000
          },
        }).then(response => {
          const asistenciaActividades = response.data.data;
          console.log({ apartment: asistenciaActividades })
          setAsistenciaActividadesToShow(
            asistenciaActividades.filter(x =>
              x.participante.encuestas.some(encuesta => encuesta.nombre === 'Encuesta de Inserción Laboral')
              &&
              (['Capacitación Técnica Vocacional', 'Emprendimiento'].includes(x.actividad.programa.nombre))
            )
          )



        });
    }

  }, [formId, participantId]);

  const handleChange = (e, itemId, pregunta) => {
    // multi select fields pass an array of selected values
    // instead of a reference to the event
    const name = e.target.name;
    const value = e.target.value;
    // const type = e.type;

    const sinDiscapacidad = sinDiscapacidadConfig.some(x => parseInt(formId) === x.formulary.id && pregunta === x.question.pregunta && x.answer === value);
    if (sinDiscapacidad) {
      setDiscapacidad(false);
    } else {
      setDiscapacidad(true);
    }

    setSelectedAnswers((values) => ({ ...values, [name]: value }));
    // if (type.id === 9 && value.length < 10) {
    //   setErrors(values => ({
    //     ...values,
    //     [itemId]: { ...values[itemId], length: true }
    //   }))
    // }
    if (e.target.required) {
      setErrors((values) => {
        const error = values[itemId] ?? {};
        if (e.target.required) {
          const errorRequired = e.target.value.length === 0;
          values[itemId] = { ...error, required: errorRequired };
        }
        return { ...values };
      });
    }
  };

  async function fetchAuditData() {
    const API = Utils.API;
    let auth = JSON.parse(localStorage.getItem("user"));
    let access_token = auth.access_token;


    const audits = await Promise.all(encuesta.secciones.map(async seccion => {
      return await Promise.all(seccion.items.map(async item => {
        const response = await axios.get(`${API}encuestas/respuestas/participantes/${item.id}/audit`, {
          headers: { Authorization: "Bearer " + access_token },
        });
        const audits = response.data.data || [];
        return audits.map(x => ({ ...x, pregunta: item.pregunta, seccion: seccion.nombre }));
      }))
    }));

    setAuditData(audits.filter(x => x.length).flat(2));

  }

  const handleSubmit = () => {
    setSaving(true);
    if (encuesta.finalizado) {

      setSaving(false);
      setShowSavedDialog(true);
    }

    if (!encuesta.finalizado) {
      req.post(`${API_URL}encuestas/${encuesta.id}/finalizar`).then(() => {
        const formData = new FormData();
        formData.append('fecha_llenado', format(fecha_llenado, 'yyyy-MM-dd'));
        req.post(`${API_URL}encuestas/${encuesta.id}/fecha_llenado`, formData).then(() => {
          setSaving(false);
          setShowSavedDialog(true);
        });
      });
    }



    // const formData = new FormData();
    // let discapacidadPayload = null;

    // if ((!!encuesta && discapacidad !== null) || (!encuesta)) {
    //   if (discapacidad === null) {
    //     discapacidadPayload = 'no-contesto';
    //   } else if (discapacidad === true) {
    //     discapacidadPayload = '1';
    //   } else if (discapacidad === false) {
    //     discapacidadPayload = '0';
    //   }

    //   formData.append('discapacidad', discapacidadPayload);
    //   req.post(`${API_URL}participantes/${participantId}/discapacidad`, formData);
    // }

  };

  const hideSavedDialog = () => {
    setShowSavedDialog(false);
    props.history.push('/beneficiarios');
  };

  const cancelPoll = () => {
    req.post(`${API_URL}encuestas/${encuesta.id}/cancelar`).then(() => {
      props.history.push('/beneficiarios');
    });
  };

  const areSectionConditionsMet = ({ dependencias }) => {
    return dependencias
      .filter((dependencia) => dependencia.activo)
      .every((dependencia) => {
        const itemId = `field_${dependencia.encuesta_item_id_dependencia}`;
        switch (dependencia.operador) {
          case '3':
            if (selectedAnswers[itemId]?.value !== undefined) {
              return (
                selectedAnswers[itemId] && selectedAnswers[itemId]?.value !== dependencia.valor
              )
            } else {
              return (
                selectedAnswers[itemId] && selectedAnswers[itemId] !== dependencia.valor
              )
            }
          case '0':
          default:
            return (
              selectedAnswers[itemId] &&
              selectedAnswers[itemId] === dependencia.valor ||
              selectedAnswers[itemId]?.value === dependencia.valor
            );
        }
      });
  };

  const openAuditModal = async () => {
    setLoadingAuditFetch(true)
    await fetchAuditData();
    setLoadingAuditFetch(false)
    setAuditModal(true)

  }

  const sectionsToShow =
    encuesta?.secciones
      ?.filter((seccion) => seccion.activo)
      ?.filter(areSectionConditionsMet) ?? [];

  return (
    <div className='container'>
      <Audits
        close={() => setAuditModal(false)}
        show={auditModal}
        data={auditData}
        getColumns={defaultColumns => [
          {
            title: 'Seccion',
            field: 'seccion'
          }, {
            title: 'Pregunta',
            field: 'pregunta'
          }
          , ...defaultColumns]}
      />
      {loadingAuditFetch ? (
        <div className='text-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) :
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <div class="nav-item">
              <p class="nav-link" onClick={() => openAuditModal()}><span>Historico</span></p>
            </div>
          </Nav>
        </div>
      }


      <div className='mainBox'>
        {isLoading ? (
          // show a spinner when loading
          <div className='text-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <PollTitle
              participant={encuesta?.participante}
              pollName={`${form.nombre}${encuesta?.finalizado ? `: ${encuesta?.codigo}` : ''
                }`}
            />
            <div style={{ marginBottom: 20 }}>
              {asistenciaActividadesToShow.map(x => (
                <span
                  style={{
                    color: 'black',
                    fontWeight: '500'
                  }}
                >
                  {`ID actividad ${x?.actividad?.id} | ${x?.actividad?.nombre}`}
                </span>
              ))}
            </div>
            <Formik
              initialValues={{}}
              onSubmit={handleSubmit}
              // validationSchema={{}}
              enableReinitialize={true}
            >{({
              values,
              errors,
              touched,
              handleChange: handleChangeFormik,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <MultiSelect
                  name='212121'
                  required
                  // onBlur={() => { }}
                  // onChange={(e) => { }}
                  options={[{
                    label: 'x', value: 'x'
                  }]}
                /> */}
                <div className='form-group col-4'>
                  <label>Fecha de llenado de la encuesta *</label>
                  <CustomDatePicker
                    selected={fecha_llenado}
                    disabled={!canEdit}
                    required={true}
                    onChange={(date) => {
                      setFechaLlenado(date);
                      req
                        .post(
                          `${API_URL}encuestas/${encuesta.id}/fecha_llenado`,
                          {
                            fecha_llenado: convertDate(date),
                          }
                        )
                        .then((response) => {
                          const data = response.data.data;
                          data.secciones
                            .sort((a, b) => a.orden - b.orden)
                            .forEach((seccion) =>
                              seccion.items.sort((a, b) => a.orden - b.orden)
                            );
                          setEncuesta(data);
                        });
                    }}
                  ></CustomDatePicker>
                </div>
                {sectionsToShow.map((seccion) => (
                  <PollSection
                    key={`section_${seccion.id}`}
                    section={seccion}
                    selectedAnswers={selectedAnswers}
                    errors={errors}
                    handleChange={(e, ...rest) => {
                      handleChangeFormik(e.target.value);
                      handleChange(e, ...rest);
                    }}
                    canEdit={canEdit}
                    form={form}
                  />
                ))}
                <div className='d-flex justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-danger rounded-pill me-2'
                    onClick={cancelPoll}
                  >
                    Descartar
                  </button>
                  <button
                    type='submit'
                    className='d-inline-block m-0 buttonSubmit'
                    disabled={isSaving}
                  >
                    Guardar
                  </button>
                </div>
              </form>
            )}</Formik>

          </>
        )}
      </div>

      <PollSavedDialog
        show={showSavedDialog}
        encuestaId={encuesta?.codigo}
        codigo={encuesta?.participante?.codigo}
        nombre={`${encuesta?.participante?.nombres} ${encuesta?.participante?.apellidos}`}
        onHide={hideSavedDialog}
      />
    </div>
  );
}

export default withRouter(PollView);
